.wrapper .event-wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 20px;
    flex-direction: column;
    width: 100%;
}

.wrapper .event-wrapper .event-name-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
}

.wrapper .event-wrapper .event-name-container p {
    font-size: 18px;
}

.wrapper .event-wrapper .event-name-container i {
    font-size: inherit;
}

.pie-explanation {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 15px;
    flex-wrap: wrap;
}

.pie-explanation .exp {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 5px;
    flex-direction: row;
    color: #818181;
}

.pie-explanation .color-box.accepted {
    background-color: #2bcead;
}

.pie-explanation .color-box {
    width: 20px;
    height: 20px;
    border-radius: 5px;
}

.pie-explanation .color-box.pending {
    background-color: #ffcb74;
}

.pie-explanation .color-box.rejected {
    background-color: #df7184;
}

.pie-explanation .color-box.sent {
    background-color: #f1f1f1;
}

.subevent-head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    width: 100%;
    border-radius: 10px;
    padding: 20px;
    background-color: #002d63;
    color: #fff;
}

.subevent-head.jc-sb {
    justify-content: space-between;
}

.subevent-head.jc-sb .sub {
    align-items: center;
    flex-grow: 1;
}

.subevent-head.jc-sb .sub p {
    text-align: center;
}

.subevent-head.sticky {
    position: sticky;
    top: 0;
    left: 0;
}

.subevent-head .prev-btn {
    font-family: 'Poppins', sans-serif;
    font-size: 18px;
    padding: 10px 30px;
    border-radius: 10px;
    background-color: #2977c9;
    border: none;
    color: #fff;
    font-weight: 300;
    text-decoration: none;
    width: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.subevent-head .prev-btn p {
    color: #d1edff;
    font-weight: 400;
}

.subevent-head .prev-btn.refresh {
    background-color: #069c56;
    min-height: 40px;
}

.subevent-wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 20px;
    width: 100%;
    flex-wrap: wrap;
}

.subevent-head .sub {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 0px;
    flex-direction: column;
}

.subevent-head h2 {
    color: #fff;
    font-size: 18px;
    font-weight: 400;
    font-family: 'Poppins', 'Open Sans', sans-serif;
}

.subevent-head h3 {
    color: #b5b5b5;
    font-size: 16px;
    font-weight: 400;
    font-family: 'Poppins', 'Open Sans', sans-serif;
}

.subevent-head p {
    color: #90bceb;
    font-size: 14px;
    font-weight: 400;
    font-family: 'Poppins', 'Open Sans', sans-serif;
    text-align: right;
    width: 100%;
}

.batch-container {
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    flex-wrap: wrap;
    gap: 30px;
    width: 100%;
    padding-bottom: 20px;
}

.batch-container .batch-tile {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;
    flex-direction: column;
    border: 1px solid #e4e4e4;
    border-radius: 10px;
    position: relative;
    width: 280px;
}

.batch-container .batch-tile .batch-delete {
    position: absolute;
    top: 10px;
    right: 10px;
    color: #d3212c;
    background-color: transparent;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    z-index: 3;
    transition: 0.3s;
}

.batch-container .batch-tile .batch-delete:hover {
    background-color: #d3212c;
    color: #fff;
    transition: 0.3s;
}

.batch-container .batch-tile .data {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;
    flex-direction: column;
    padding: 20px;
    padding-bottom: 10px;
    position: relative;
    width: 100%;
}

.batch-container .batch-tile h2 {
    font-size: 16px;
    color: #2977c9;
}

.batch-container .batch-tile .cert-count {
    background-color: #d0e5fe;
    color: #2977c9;
    padding: 5px 20px;
    border-radius: 5px;
}

.batch-container .batch-tile .status {
    background-color: #f3f3f3;
    color: #818181;
    padding: 5px 20px;
    border-radius: 5px;
}

.batch-container .batch-tile .status.sent {
    background-color: #2bcead;
    color: #fff;
}

.batch-container .batch-tile .status.pending {
    background-color: #ffcb74;
    color: #fff;
}

.batch-container .batch-tile .status.rejected {
    background-color: #df7184;
    color: #fff;
}

.batch-container .batch-tile .btn-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: stretch;
    gap: 0px;
}

.batch-container .batch-tile .btn {
    border-radius: 0px;
    text-align: center;
    padding: 5px;
    flex-grow: 1;
    background-color: #157efb29;
    color: #2977c9;
    border: none;
    font-family: 'Poppins', sans-serif;
    font-size: 18px;
    font-weight: 300;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-decoration: none;
}

.batch-container .batch-tile .btn.modified-on {
    background-color: #f1f1f1;
    color: #818181;
    text-align: left;
    padding: 5px 10px;
    border: none;
    align-items: flex-start;
}

.batch-container .batch-tile .btn.modified-on label {
    font-size: 10px;
    font-weight: 300;
}

.batch-container .batch-tile .btn.modified-on p {
    font-size: 10px;
    color: #002d63;
    font-weight: 600;
    font-family: 'Poppins', 'Open Sans', sans-serif;
}

.batch-container .batch-tile .btn:first-child {
    border-radius: 0px 0px 0px 10px;
}

.batch-container .batch-tile .btn:last-child {
    border-radius: 0px 0px 10px 0px;
}

.batch-container .batch-tile-new {
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 10px;
    flex-direction: row;
    flex-wrap: nowrap;
    padding: 10px;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 0px 8px;
    width: 280px;
    transition: 0.3s;
}

.batch-container .batch-tile-new:hover {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 0px 2px;
    transition: 0.3s;
    cursor: pointer;
    animation: none !important;
}

.batch-container .batch-tile-new.ready {
    animation: ready-breathe 4s infinite;
    transition: 0.3s;
}

@keyframes ready-breathe {
    0% {
        box-shadow: #2bcead 0px 0px 12px;
        transition: 0.3s;
    }

    10% {
        box-shadow: rgba(0, 0, 0, 0.24) 0px 0px 8px;
        transition: 0.3s;
    }

    80% {
        box-shadow: rgba(0, 0, 0, 0.24) 0px 0px 8px;
        transition: 0.3s;
    }

    100% {
        box-shadow: #2bcead 0px 0px 12px;
        transition: 0.3s;
    }
}

.batch-container .batch-tile-new .batch-tile-container {
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 10px;
    flex-direction: column;
    height: 100%;
}

.batch-container .batch-tile-new .batch-tile-container .img {
    height: 90px;
    max-width: 127px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    position: relative;
    border-radius: 6px;
    overflow: hidden;
}

.batch-container .batch-tile-new .batch-tile-container .img img {
    height: 100%;
    width: auto;
}

.batch-container .batch-tile-new .batch-tile-container .img .preview {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 5px;
    background-color: #00000069;
    color: #fff;
    font-size: 12px;
    opacity: 0;
    transition: 0.3s;
}

.batch-container .batch-tile-new .batch-tile-container .img:hover .preview {
    opacity: 1;
    transition: 0.3s;
}

.batch-container .batch-tile-new .batch-tile-container .img .preview i {
    font-size: 2rem;
}

.batch-container .batch-tile-new .batch-tile-container .img .preview p {
    font-size: 12px;
    color: inherit;
}

.batch-container .batch-tile-new .batch-tile-container:last-child {
    align-items: flex-end;
}

.batch-container .batch-tile-new h2 {
    font-size: 18px;
    color: #818181;
    font-weight: 400;
}

.batch-container .batch-tile-new .data {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 0px;
    flex-direction: column;
    color: #818181;
    font-weight: 400;
    font-size: 12px;
}

.batch-container .batch-tile-new .data p {
    font-size: inherit;
    color: inherit;
    font-weight: inherit;
}

.batch-container .batch-tile-new .data .batch-delete {
    color: #d3212c;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    flex-direction: row;
}

.batch-container .batch-tile-new .data .batch-delete:hover p {
    opacity: 1;
    transition: 0.6s;
}

.batch-container .batch-tile-new .data .batch-delete p {
    font-size: 12px;
    line-height: 12px;
    opacity: 0;
    transition: 0.6s;
}

.batch-container .batch-tile-new .status {
    background-color: #157efb;
    color: #fff;
    padding: 1px 15px;
    font-size: 12px;
    border-radius: 5px;
}

.batch-container .batch-tile-new .status.sent {
    background-color: #f1f1f1;
    color: #818181;
}

.batch-container .batch-tile-new .status.pending {
    background-color: #ffcb74;
    color: #fff;
}

.batch-container .batch-tile-new .status.rejected {
    background-color: #df7184;
    color: #fff;
}

.batch-container .batch-tile-new .status.ready {
    background-color: #2bcead;
    color: #fff;
}

.batch-data-email-preview {
    width: fit-content;
    max-width: 100%;
    position: relative;
    margin-bottom: 5px;
}

.batch-data-email-preview img {
    width: fit-content;
    max-width: 100%;
    border-radius: 10px;
    border: 2px dashed #157efb;
    min-height: 200px;
    height: 200px;
    min-width: 140px;
    display: block;
    margin: auto;
}

.batch-data-email-preview button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #2977c939;
    color: #002d63;
    font-size: 14px;
    font-weight: 400;
    padding: 5px 10px;
    border-radius: 0px;
    border: 1px solid #002d63;
    width: 100%;
    transition: 0.3s;
}

.batch-data-email-preview:hover button {
    background-color: #002d63;
    color: #fff;
    transition: 0.3s;
    border: 1px solid #002d63;
}

.tiles .sub-nav .more-info {
    padding: 2px 20px;
    font-size: 14px;
    background-color: transparent;
    color: #002d63;
    border: 1px solid #002d63;
    border-radius: 5px;
    margin-top: 5px;
}

.tiles .sub-nav h3 {
    font-size: 16px;
    color: #002d63;
    font-weight: 600;
}

.tiles .sub-nav .stat-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 5px;
    font-size: 12px;
    color: #818181;
    font-weight: 300;
}

.tiles .sub-nav .stat-container span {
    font-weight: 600;
    font-size: 14px;
    color: #2977c9;
}

.tiles .sub-nav .stat-container i {
    font-size: 18px;
    color: #002d63;
    padding: 6px;
}

.tiles .sub-nav .stat-container.accepted i {
    color: #2bcead;
}

.tiles .sub-nav .stat-container.rejected i {
    color: #df7184;
}

.check-accept-status {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
}

.check-accept-status p {
    width: 100%;
}

.check-accept-status input {
    flex-basis: 80%;
}

.check-accept-status button {
    flex-basis: 20%;
    padding: 10px;
    height: 52px;
    width: 52px;
}

.check-accept-status a {
    padding: 10px 20px;
    background-color: #d1edff;
    color: #157efb;
    border-radius: 5px;
    display: block;
    text-decoration: none;
    width: 100%;
    text-align: center;
}

.check-accept-status a.withdraw-batch-btn {
    background-color: #df7184;
    color: #fff;
    margin-top: 10px;
}

.check-accept-status a.reissue-batch-btn {
    background-color: #75c8a7;
    color: #fff;
    margin-top: 10px;
}

.check-accept-status .label {
    font-size: 12px;
    color: #002d63 !important;
}

.check-accept-status .status {
    margin-top: 10px;
}

.check-accept-status .status b {
    background-color: #002d63;
    color: #fff;
    padding: 3px 10px;
    font-weight: 400;
    border-radius: 5px;
    font-size: 12px;
    text-transform: capitalize;
}

.check-accept-status .status.deleted b {
    background-color: #d3212c;
}

.check-accept-status .status.pending b {
    background-color: #ff980e;
}

.check-accept-status .status.accepted b {
    background-color: #069c56;
}

.issue-failed {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;
    flex-direction: column;
    width: 100%;
    flex-grow: 1;
    overflow-y: auto;
    padding: 5px;
}

.issue-failed .load-more {
    font-size: 14px;
    border-radius: 0px;
    width: 100%;
    background-color: #d1edff;
    color: #157efb;
    font-weight: 400;
}

.issue-failed .wrap {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
    background-color: #fff;
    padding: 10px;
    border-radius: 5px;
    flex-wrap: wrap;
}

.issue-failed .wrap h1 {
    color: #002d63;
    font-size: 14px;
    font-weight: 400;
}

.issue-failed .wrap h2 {
    color: #818181;
    font-size: 14px;
    font-weight: 300;
}

.issue-failed .wrap h3 {
    color: #818181;
    font-size: 12px;
    font-weight: 400;
}

.issue-failed .actions {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.issue-failed .actions button {
    font-size: 14px;
    border-radius: 5px;
    background-color: #069c56;
    font-weight: 400;
}

.issue-failed .actions button.withdraw {
    font-size: 14px;
    border-radius: 5px;
    background-color: #f3f3f3;
    color: #d3212c;
    font-weight: 400;
}

.issue-failed .actions button.withdraw:hover {
    background-color: #df7184;
    color: #fff;
}

.issue-failed .actions button.deactive {
    background-color: #f0f0f0;
    color: #818181;
}

.issue-failed .status-icons {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    padding-top: 10px;
    color: #d0e5fe;
    font-size: 16px;
}

.issue-failed .status-icons i {
    font-size: inherit;
    color: #ececec;
}

.issue-failed .status-icons i.fa-envelope-circle-check.active {
    color: #069c56;
}

.issue-failed .status-icons i.fa-triangle-exclamation.active {
    color: #ff980e;
}

.issue-failed .status-icons i.fa-envelope-open-text.active {
    color: #157efb;
}

.issue-failed .status-icons i.fa-circle-check.active {
    color: #069c56;
}

.issue-failed .status-icons i.fa-circle-xmark.active {
    color: #d3212c;
}

.issue-failed .status-icons i.fa-trash-can.active {
    color: #818181;
}

.issue-failed .status-icons .deleted {
    background-color: #d3212c;
    color: #fff;
    font-size: 12px;
    padding: 2px 6px;
    border-radius: 3px;
}

.issue-failed .status-icons .deleted i {
    color: #fff !important;
}

.issued-failed-btn {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    gap: 10px;
    width: 100%;
    min-height: fit-content;
}

.issued-failed-btn button {
    background-color: transparent;
    color: #818181;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    border-radius: 5px;
    /* padding: 5px 15px; */
}

.issued-failed-btn button b {
    font-weight: 300;
}

.issued-failed-btn button:hover {
    background-color: #d1edff;
}

.issued-failed-btn button.failed span {
    padding: 1px 8px;
    align-self: center;
    width: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #d3212c;
    color: #fff;
    border-radius: 10px;
    display: inline-block;
    font-size: 11px;
    font-weight: 600;
}

.issued-failed-btn button.bounced span {
    padding: 1px 8px;
    align-self: center;
    width: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ff980e;
    color: #fff;
    border-radius: 10px;
    display: inline-block;
    font-size: 11px;
    font-weight: 600;
}

.issued-failed-btn button.issued {
    color: #069c56;
}

.issued-failed-btn button.failed {
    color: #d3212c;
}

.issued-failed-btn button.bounced {
    color: #ff980e;
}

.issued-failed-btn button.pending {
    color: #002d63;
}

.issued-failed-btn button.active {
    background-color: #2977c9;
    color: #fff;
}

.issued-failed-btn .search-input {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 40px;
    min-width: 200px;
    background-color: transparent;
    border: 1px solid #157efb;
    color: #2977c9;
    flex-grow: 1;
    cursor: text;
    padding: 0px 10px;
    border-radius: 5px;
    max-width: 300px;
}

.issued-failed-btn .search-input input {
    padding: 8px;
    border: none;
    background-color: transparent;
}

.issued-failed-btn .search-input input:focus {
    border: none;
}

.issued-failed-btn .warning-highlight {
    background-color: #fae1e1;
    color: #d3212c;
    border-color: #d3212c;
}

.issued-failed-btn .warning-highlight input {
    color: inherit;
}

@keyframes shakeInvalid {
    0% {
        margin-left: 0px;
    }
    25% {
        margin-left: 3px;
    }
    75% {
        margin-left: -3px;
    }
    100% {
        margin-left: 0px;
    }
}

.issued-failed-btn .warning-highlight i {
    color: #d3212c;
    animation: shakeInvalid 0.2s ease-in-out 0s 2;
}

.csv-table {
    width: 100%;
    flex-grow: 1;
    overflow: auto;
}

.csv-table table {
    width: 100%;
}

.csv-table th {
    background-color: #75c8a7;
    color: #fff;
    padding: 10px 5px;
    font-size: 14px;
    font-weight: 400;
}

.csv-table input {
    padding: 10px 5px;
    border-radius: 0px;
    border: none;
    background-color: transparent;
    font-size: 14px;
    text-align: center;
    width: 100%;
}

.csv-table td {
    text-align: center;
    color: #002d63;
    padding: 5px 1px;
}

.csv-table tr {
    background-color: #f1f1f1;
}

.csv-table tr:nth-child(even) {
    background-color: #d1edff;
}

.csv-table button {
    padding: 5px 20px;
    height: fit-content;
    border-radius: 5px;
    border: none;
    margin-top: 20px;
    background-color: #d1edff;
    color: #157efb;
    font-size: 14px;
    border: 1px solid #157efb;
    transition: 0.3s;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.csv-table button:hover {
    background-color: #157efb;
    color: #fff;
    transition: 0.3s;
}

/* -------------toggle switch------------ */

.event-modal .switch,
.container-right .switch,
.esign-modal .switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
}

.event-modal .switch input,
.container-right .switch input,
.esign-modal .switch input {
    display: none;
}

.event-modal .slider,
.container-right .slider,
.esign-modal .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: 0.4s;
    border-radius: 34px;
    width: 100%;
}

.container-right .slider {
    background-color: #75c8a7;
}

.event-modal .slider:before,
.container-right .slider:before,
.esign-modal .slider:before {
    position: absolute;
    content: '';
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: 0.4s;
    border-radius: 50%;
}

.event-modal input:checked + .slider,
.container-right input:checked + .slider,
.esign-modal input:checked + .slider {
    background-color: #2196f3;
}

.event-modal input:focus + .slider,
.container-right input:focus + .slider,
.esign-modal input:focus + .slider {
    box-shadow: 0 0 1px #2196f3;
}

.event-modal input:checked + .slider:before,
.container-right input:checked + .slider:before,
.esign-modal input:checked + .slider:before {
    transform: translateX(26px);
}

.event-modal datalist {
    display: none;
    opacity: 0;
}

.event-modal .flex {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
}

.event-modal .flex button:first-child {
    background-color: #b5b5b5;
    color: #000;
}

.email-template-btn-container {
    position: sticky;
    bottom: 0px;
    left: 0px;
    width: 100%;
    background-color: #fff;
    padding: 10px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 20px;
}

.email-template-btn-container .back-btn {
    padding: 10px 30px;
    font-size: 18px;
    border: none;
    background-color: #d1edff;
    color: #157efb;
}

.btn-sub-nav {
    padding: 10px 30px;
    font-size: 14px;
    text-decoration: none;
    background-color: #157efb;
    color: #fff;
    border-radius: 5px;
    width: 100% !important;
}

.btn-sub-nav.download-btn {
    background-color: #d1edff;
    color: #157efb;
    font-weight: 400;
    flex-grow: 0;
}

.email-preview-modal .btn-sub-nav {
    width: fit-content;
    position: sticky;
    top: 10px;
    right: 0px;
    transition: 0.3s;
    display: block;
    margin: auto;
    margin-right: 0px;
    pointer-events: all !important;
    background-color: #75c8a7;
    border-radius: 5px;
    z-index: 999;
}

.email-preview-modal .btn-sub-nav:hover {
    background-color: #069c56;
    transition: 0.3s;
}

.email-preview-modal .infographics-modal {
    max-width: 400px;
    width: 90%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;
    flex-direction: column;
    background-color: #fff;
    border-radius: 10px;
    padding: 20px;
    position: relative;
}

.email-preview-modal .infographics-modal canvas {
    display: block;
    margin: auto;
}

.email-preview-modal .infographics-modal .close-btn {
    border: 3px solid #fff;
    top: -7px;
    right: -7px;
    font-size: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.feedback-new-att {
    width: 100%;
    padding: 0px !important;
    background-color: transparent !important;
}

.batch-group-buttons {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    flex-direction: row;
    flex-wrap: wrap;
    position: sticky;
    bottom: 0px;
    z-index: 3;
    padding: 10px;
    padding-bottom: 0px;
    background-color: #f9f9f9;
    width: 100%;
    margin-bottom: 5px;
    box-shadow: #f9f9f9a9 0px 0px 20px 17px;
}

.batch-group-buttons .btn-sub-nav {
    width: fit-content !important;
    padding: 10px 20px;
}

.batch-group-buttons .btn-sub-nav:nth-child(1) {
    background-color: #f6b58a;
    color: #000;
    font-weight: 600;
}

.batch-group-buttons .btn-sub-nav:nth-child(2) {
    background-color: #c9e0fc;
    color: #000;
    font-weight: 600;
}

.batch-group-buttons .btn-sub-nav:nth-child(3) {
    background-color: #dedede;
    color: #000;
    font-weight: 600;
}

.email-content-preview {
    max-height: calc(100% - 360px);
    overflow-y: auto;
    padding-top: 10px;
}

.email-content-preview label {
    color: #157efb;
    font-size: 12px;
    background-color: #fff;
    border: 1px solid #157efb;
    padding: 1px 10px;
    border-radius: 5px;
    display: block;
    margin-top: 6px;
    margin-bottom: 3px;
    width: fit-content;
}

.more-info-block {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;
    flex-direction: column;
    margin-bottom: 10px;
}

.more-info-block p {
    padding: 5px 10px;
    border-radius: 5px;
    background-color: #fff;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    width: 100%;
    font-size: 12px;
    font-weight: 600;
    color: #157efb !important;
    line-height: 18px !important;
}

.more-info-block p b {
    font-weight: 400;
    color: #002d63;
    font-size: 14px;
}

.more-info-block p label {
    font-weight: 400;
    color: #002d63;
    font-size: 12px;
    line-height: 14px;
    background-color: #fff;
    color: #2977c9;
    padding: 3px 10px;
    border-radius: 0px 5px 5px 0px;
    margin: 5px 0px;
    display: block;
    transform: translateX(-10px);
}

.more-info-block p.signed {
    background-color: #069c5619;
}

.more-info-block p.pending {
    background-color: #ff980e19;
}

.more-info-block p.rejected {
    background-color: #df718419;
}

.more-info-block p.signed label {
    background-color: #069c56;
    color: #fff;
}

.more-info-block p.pending label {
    background-color: #ff980e;
    color: #fff;
}

.more-info-block p.rejected label {
    background-color: #df7184;
    color: #fff;
}

.more-info-block p label i {
    font-size: inherit;
    color: inherit;
}

.private-doc-message {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.private-doc-message p {
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell,
        'Open Sans', 'Helvetica Neue', sans-serif;
    color: #818181;
}

.private-doc-message i {
    font-size: 3rem;
    color: #df7184;
    margin-top: 30px;
}

.filter {
    position: fixed;
    top: 50%;
    right: 0px;
    background-color: #157efb;
    padding: 20px 10px;
    z-index: 10;
    border-radius: 0px 15px 15px 0px;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    gap: 10px;
    transition: right 0.3s ease-in-out;
    writing-mode: vertical-rl;
    transform: translateY(-50%) rotate(180deg);
    animation: pulltab 5s infinite;
}

.filter.open {
    right: 300px;
    animation: none;
    z-index: 12;
}

@keyframes pulltab {
    0% {
        padding-left: 10px;
        padding-right: 10px;
        transition: 0.3 ease-in-out;
    }

    5% {
        padding-left: 15px;
        padding-right: 13px;
        transition: 0.3 ease-in-out;
    }

    10% {
        padding-left: 10px;
        padding-right: 10px;
        transition: 0.3 ease-in-out;
    }

    15% {
        padding-left: 15px;
        padding-right: 13px;
        transition: 0.3 ease-in-out;
    }

    20% {
        padding-left: 10px;
        padding-right: 10px;
        transition: 0.3 ease-in-out;
    }

    100% {
        padding-left: 10px;
    }
}

.filter:hover {
    background-color: #002d63;
    transition: 0.3s;
}

.filter-modal {
    position: fixed;
    top: 0;
    right: -300px;
    width: 300px;
    height: 100vh;
    background-color: #fff;
    z-index: 11;
    transition: right 0.3s ease-in-out;
    padding: 20px;
    box-shadow: 0px 0px 20px #00000069;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;
}

.filter-modal h2 {
    font-weight: 400;
    color: #002d63;
    font-size: 14px;
}

.filter-modal.open {
    right: 0px;
    transition: right 0.3s ease-in-out;
}

.filter-modal .hide-btn {
    color: #df7184;
    padding: 0px;
    height: auto;
    min-height: auto;
    background-color: transparent;
    border: none;
    width: fit-content;
    line-height: 16px;
    font-size: 16px;
}

.filter-modal .hide-btn i {
    font-size: 14px;
    transition: 0.3s;
    border: none;
}

.filter-modal .hide-btn:hover i {
    padding-left: 10px;
    transition: 0.3s;
}

.filter-modal .hide-btn:hover {
    border: none;
}

.filter-modal .container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    gap: 20px;
    min-height: 100px;
    width: 100%;
    overflow-y: auto;
}

.filter-modal .draft-tile {
    width: 100%;
    background-color: #d1edff;
    padding: 5px;
    border-radius: 5px;
    position: relative;
    transition: 0.3s;
}

.filter-modal .saved-tile {
    width: 90%;
    background-color: #f1f1f1;
    padding: 5px;
    border-radius: 5px;
    position: relative;
    transition: 0.3s;
}

.filter-modal .draft-tile:hover button {
    border: 1px solid #157efb;
    transition: 0.3s;
}

.filter-modal .draft-tile:hover {
    background-color: #d1edff39;
}

.filter-modal .saved-tile:hover button {
    border: 1px solid #157efb;
    transition: 0.3s;
}

.filter-modal .saved-tile:hover {
    background-color: #f1f1f139;
}

.filter-modal .draft-tile img,
.filter-modal .saved-tile img {
    width: 100%;
}

.filter-modal button {
    padding: 5px 20px;
    width: fit-content;
    font-size: 16px;
    border: 1px solid #fff;
    background-color: #fff;
    color: #002d63;
    border-radius: 5px;
    transition: 0.3s;
}

.filter-modal button:hover {
    border: 1px solid #157efb;
    transition: 0.3s;
}

.shimmer-bar {
    width: 100%;
    height: 10px;
    background: linear-gradient(to right, #f0f0f0 0%, #d8d8d8 50%, #f0f0f0 100%);
    background-size: 200% 100%;
    animation: shimmer 2s infinite;
    border-radius: 5px;
}

.shimmer-bar.b1 {
    position: absolute;
    top: 30%;
    left: 20px;
    width: 70%;
}

.shimmer-bar.b2 {
    position: absolute;
    top: calc(30% + 20px);
    left: 20px;
    width: 30%;
}

.shimmer-bar.b3 {
    position: absolute;
    top: calc(30% + 40px);
    left: 20px;
    width: 50%;
}

.email-preview-modal,
.batchcert-preview-modal {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 999;
    background-color: #00000069;
    display: flex;
    justify-content: center;
    align-items: center;
}

.email-preview-modal .close-btn,
.batchcert-preview-modal .close-btn {
    border: 1px solid #d3212c;
    background-color: #df7184;
    color: #fff;
    font-size: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.email-preview-modal .close-btn i,
.batchcert-preview-modal .close-btn i {
    color: inherit;
    font-size: inherit;
    pointer-events: none;
}

.email-preview-modal img,
.batchcert-preview-modal img {
    max-width: 900px;
}

.email-preview-modal img {
    width: 90%;
}

.email-preview-modal td img {
    width: fit-content;
}

.email-preview-modal .email-template {
    max-width: 800px;
    max-height: 90%;
    overflow-y: scroll;
    border-radius: 10px;
}

.email-preview-modal .email-template img {
    width: auto;
}

.email-preview-modal .email-template::-webkit-scrollbar {
    width: 0px;
    display: none;
}

.email-preview-modal .email-template a {
    pointer-events: none;
}

.email-preview-modal .email-template .email-view-action-btns {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    flex-direction: row;
    flex-wrap: wrap;
    position: sticky;
    top: 10px;
    right: 0px;
}

.email-preview-modal .email-template .email-view-action-btns a {
    width: calc(100% - 51px) !important;
}

.email-preview-modal .email-template .email-view-action-btns button {
    width: 41px;
    height: 41px;
    border-radius: 5px;
    padding: 0px;
    display: flex;
    border: none;
    background-color: #d5415a;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.email-preview-modal .email-template .email-view-action-btns button i {
    pointer-events: none;
}

.batchcert-preview-modal .preview-rel {
    position: relative;
}

.batchcert-preview-modal h3 {
    padding: 10px;
    background-color: #fff;
    color: #2977c9;
    border-radius: 5px;
    position: absolute;
    top: 40px;
    left: 50%;
    transform: translateX(-50%);
    min-width: 50%;
    text-align: center;
    max-width: 90%;
    overflow-x: hidden;
}

.batchcert-preview-modal h3 label {
    display: block;
    font-size: 12px;
    font-weight: 400;
    color: #818181;
}

.batchcert-preview-modal .sign-loader {
    position: absolute;
    z-index: 99;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #00000069;
    backdrop-filter: blur(5px);
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 21px;
    font-weight: 500;
}

@keyframes shimmer {
    0% {
        background-position: -200% 0;
    }

    100% {
        background-position: 200% 0;
    }
}

.create-organisation-btn {
    background-color: #069c56 !important;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    font-size: 16px !important;
}

.create-organisation-btn i {
    font-size: inherit;
}

.create-organisation-img {
    max-width: 90%;
    max-height: 200px;
    object-fit: contain !important;
    position: relative;
}

.create-organisation-img img {
    object-fit: contain;
    opacity: 0.3;
}

.create-organisation-img button {
    position: absolute;
    top: 50%;
    left: 0%;
    transform: translateY(-50%);
    background-color: #c9e0fc;
    color: #002d63;
    font-size: 14px;
    padding: 5px 20px;
    white-space: nowrap;
    border-radius: 5px;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-direction: row;
}

@media screen and (max-width: 1080px) {
    .subevent-head .prev-btn {
        padding: 15px;
    }

    .subevent-head .prev-btn p {
        display: none;
    }

    .subevent-head.jc-sb {
        justify-content: space-between;
    }

    .subevent-head.jc-sb .sub {
        align-items: flex-end;
        flex-grow: 0;
    }

    .subevent-head.jc-sb .sub p {
        text-align: center;
    }

    .subevent-head .prev-btn.refresh {
        display: none;
    }
}

@media screen and (max-width: 820px) {
    .filter {
        display: none;
    }

    .filter-modal {
        display: none;
    }

    .subevent-head {
        flex-wrap: wrap;
    }

    .subevent-head .prev-btn {
        padding: 10px;
    }

    .subevent-head .prev-btn p {
        display: none;
    }

    .subevent-head .sub {
        align-items: flex-end;
        font-size: 14px;
    }

    .subevent-head h2 {
        font-size: 16px;
    }

    .subevent-head h3 {
        font-size: 14px;
    }

    .issue-failed .wrap {
        flex-direction: column;
        align-items: flex-start;
    }

    .subevent-head .prev-btn.refresh {
        display: none;
    }

    .issued-failed-btn button {
        padding: 10px;
    }

    .issued-failed-btn button b {
        display: none;
    }

    .issued-failed-btn button.active b {
        display: block;
    }
}
