.element {
    width: fit-content;
    flex-grow: 1;
    max-height: 100%;
}

.min-element {
    max-width: calc(100% - 420px);
}

.max-element {
    max-width: calc(100% - 120px);
}

.full-element {
    max-width: 100%;
}

.full-element .marketplace {
    height: 100% !important;
    /* max-height: 640px !important; */
}

.no-scroll {
    overflow-y: hidden !important;
    height: 100%;
}

.element .container {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    flex-direction: column;
    height: 100%;
    position: relative;
    overflow: hidden;
}

.element.public .container {
    flex-wrap: nowrap;
}

.element.public {
    height: calc(100% - 70px);
}

.element .container .header {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    align-content: center;
    gap: 20px;
    position: relative;
}

.element .container .header .back-btn {
    padding: 5px 15px;
    border-radius: 5px;
    font-size: 14px;
    background-color: #fff;
    color: #002d63;
    position: absolute;
    top: 0px;
    left: 0px;
    width: fit-content;
    border: 1px solid #002d63;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.btn-issue {
    /* background-color: #2BCEAD; */
    background-image: linear-gradient(60deg, #3d3393 0%, #2b76b9 37%, #2cacd1 65%, #35eb93 100%);
    background-size: 200% auto;
    animation: gradientAnimation 5s linear infinite;
    color: #fff;
    width: 100%;
    /* box-shadow: 4px 1px 13px 0px #00000021; */
    border: none;
    border-radius: 10px;
    padding: 5px;
    font-size: 16px;
    font-weight: 400;
    transition: 0s;
    cursor: pointer;
}

@keyframes gradientAnimation {
    0% {
        background-position: 0%;
    }

    50% {
        background-position: 100%;
    }

    100% {
        background-position: 0%;
    }
}

.btn-issue:hover {
    box-shadow: none;
    transition: 0s;
}

.btn-issue i {
    width: 16px;
    overflow: hidden;
    transition: 0.3s ease-in-out;
}

.btn-issue:hover i {
    width: 0px;
    transition: 0.3s ease-in-out;
}

.element .container .header h1 {
    font-family: 'Poppins', sans-serif;
    font-size: 20px;
    color: #2977c9;
    font-weight: 600;
    line-height: 20px;
    height: 45px;
    text-align: center;
}

.element .container .header h1 .search-tag {
    text-transform: lowercase;
    background-color: #1fd5b0;
    color: #fff;
    width: fit-content;
    padding: 1px 10px;
    margin-top: 2px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.element .container .header h1 .search-tag p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 120px;
}

.element .container .header h1 label {
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    color: #2977c9;
    font-weight: 300;
    display: block;
    color: #8e8e8e;
    text-transform: capitalize;
    text-align: center;
}

.element .container .header .badge-container {
    display: flex;
    gap: 10px;
    position: relative;
}

.element .container .header .badge {
    position: relative;
    width: 35px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2em;
    color: #333;
    background-color: transparent;
    transition: 0.3s;
}

.element .container .header .badge-container img {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 30px;
    z-index: 3;
    mix-blend-mode: multiply;
}

.element .container .header .badge::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: #f1f1f1;
    clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
    z-index: 1;
}

.element .container .header .badge::after {
    content: '';
    position: absolute;
    width: 70%;
    height: 70%;
    background-color: #ffffff69;
    clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
    z-index: 2;
}

.element .container .header .iron.badge:before {
    background: #d7d2cc;
    background: linear-gradient(310deg, #d7d2cc, #304352);
}

.element .container .header .bronze.badge:before {
    background: #554023;
    background: linear-gradient(to right, #554023, #c99846);
}

.element .container .header .silver.badge:before {
    background: #bdc2e8;
    background: linear-gradient(50deg, #bdc2e8, #bdc2e8, #e6dee9);
}

.element .container .header .gold.badge:before {
    background: #554023;
    background: linear-gradient(310deg, #ffc76a, #d49a39);
}

.element .container .header .platinum.badge:before {
    background: #00c6fb;
    background: linear-gradient(310deg, #00c6fb, #005bea);
}

.element .container .header .emerald.badge:before {
    background: #003d4d;
    background: linear-gradient(310deg, #003d4d, #00c996);
}

.element .container .header .badge:hover {
    filter: drop-shadow(-4px 4px 4px rgba(0, 0, 0, 0.23));
    transition: 0.3s;
}

.element .container .header .badge span {
    position: relative;
    z-index: 3;
    padding: 0.2em 0.4em;
    border-radius: 5px;
    font-weight: 900;
    text-shadow: -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff, 1px 1px 0 #fff;
}

.element .container .header .toggle {
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-self: center;
    justify-content: center;
    background-color: transparent;
    font-family: 'Poppins', 'Open Sans', sans-serif;
}

.element .container .header .toggle button {
    padding: 5px 15px;
    border-radius: 5px;
    border: none;
    background-color: #fff;
    color: #2977c9;
    font-family: inherit;
    font-size: 14px;
    font-weight: 600;
    transition: 0.3s;
}

.element .container .header .toggle button:hover {
    background-color: #157efb21;
    transition: 0.3s;
}

.element .container .header .toggle button.active {
    background-color: #2977c9;
    color: #fff;
}

.element .tiles {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 20px;
    max-height: calc(100vh - 210px);
    overflow-y: scroll;
    position: relative;
    /* color: rgba(0, 0, 0, 0); */
    /* Always active scrollbar */
    color: #2977c939;
    transition: color 0.3s ease;
    padding-bottom: 160px;
    /* for UI in middle issue resolving */
    height: 100vh;
    align-content: flex-start;
}

*::-webkit-scrollbar,
*::-webkit-scrollbar-thumb {
    width: 26px;
    border-radius: 13px;
    background-clip: padding-box;
    border: 10px solid transparent;
}

*::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 0 10px #669df669;
    min-height: 40px;
}

*::-webkit-scrollbar,
*::-webkit-scrollbar-thumb {
    height: 26px;
    border-radius: 13px;
    background-clip: padding-box;
    border: 10px solid transparent;
    /* Adjust the border width as needed */
}

*::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 0 10px #669df669;
    min-width: 40px;
}

.element .tiles:hover {
    color: #2977c969;
}

.element .fadeout {
    position: sticky;
    bottom: -30px;
    right: 0;
    width: 100%;
    z-index: 3;
    background-image: linear-gradient(to bottom, transparent, #f1f1f1);
    height: 120px;
}

.element .tiles .tile {
    padding: 15px;
    border-radius: 10px;
    background-color: #fff;
    width: 400px;
    height: 300px;
    display: flex;
    flex-direction: column;
    gap: 15px;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: flex-start;
    transition: 0.3s;
    z-index: 3;
}

.tile-wrapper:hover .tile {
    height: 240px;
    transition: 0.3s;
}

.tile-wrapper.no-hover:hover .tile {
    height: 300px;
    transition: 0.3s;
}

.tile-wrapper:hover .action {
    height: 50px;
    transition: 0.3s;
}

.tile-wrapper {
    display: flex;
    flex-direction: column;
    gap: 10px;
    height: 300px;
    position: relative;
}

.tile-wrapper.pronetin .tile,
.tile-wrapper.pronetin .action {
    background-image: linear-gradient(120deg, #a1c4fd 0%, #c2e9fb 100%);
}

.tile-wrapper.pronetin .tile h1 {
    color: #157efb;
}

.tile-wrapper.pronetin .tile .trial {
    color: #3d3393;
}

.tile-wrapper.pronetin .tile h1 label {
    color: #fff;
    font-weight: 600;
}

.tile-wrapper.pronetin .tile p {
    color: #fff;
    font-weight: 600;
}

.tile-wrapper.pronetin .tile .view-option .view {
    background-color: #2bcead;
    color: #fff;
}

.tile-wrapper.pronetin .tile .organisation {
    background-color: #157efb;
    color: #fff;
}

.tile-wrapper .action {
    background-color: #fff;
    color: #000;
    height: 70px;
    border-radius: 10px;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 2;
    transition: 0.3s;
    padding: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.tile-wrapper .uploading-status {
    padding-right: 20px;
    color: #002d63;
    width: fit-content;
}

.tile-wrapper .action .icon-btn {
    display: flex;
    justify-content: flex-start;
    gap: 10px;
}

.tile-wrapper .action .icon-btn button {
    background-color: #f1f1f1;
    color: #2977c9;
}

.tile-wrapper .action .icon-btn button.disabled {
    background-color: #f1f1f1;
    color: #ccc;
}

.tile-wrapper .action .icon-btn button.disabled:hover {
    background-color: #f1f1f1;
    color: #ccc;
}

.tile-wrapper .action .icon-btn button i {
    font-size: 16px;
}

.tile-wrapper .action .icon-btn button:hover {
    color: #fff;
}

.tile-wrapper .action button,
.tile-wrapper .action a {
    font-family: 'Poppins', sans-serif;
    font-size: 18px;
    border-radius: 10px;
    border: none;
    font-weight: 300;
    height: 40px;
    padding: 0px 20px;
    width: fit-content;
    background-color: #2977c9;
    color: #fff;
    transition: 0.3s;
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
}

/* -----------tileV2------------ */

.tileV2 {
    background-color: #fff;
    border-radius: 10px;
    padding: 10px;
    width: 270px;
    height: 170px;
    color: #818181;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;
    flex-direction: column;
    transition: 0.3s;
    box-shadow: none;
    position: relative;
    overflow: hidden;
}

.tileV2:hover {
    box-shadow: #157efb39 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
    /* scale: 0.98; */
    transition: 0.3s;
}

.tileV2 .bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    width: 100%;
}

.tileV2 .name {
    /* border: 1px solid #157EFB; */
    background-color: #f9f9f9;
    border-radius: 5px;
    width: 100%;
    padding: 10px;
}

.tileV2.grayed .name {
    background-color: #f1f1f1;
}

.tileV2 .view-option {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 5px;
    font-size: 12px;
    padding: 3px 0px;
    border-radius: 5px;
}

.tileV2 .view-option .view {
    font-size: inherit;
    background-color: #21937b;
    color: #fff;
    border-radius: 5px;
    padding: 2px 10px;
}

.tileV2 .view-option.public {
    color: #21937b;
}

.tileV2 .view-option.private {
    color: #d5415a;
}

.tileV2 h1 {
    font-size: 16px;
    font-weight: 600;
    color: #000;
    line-height: 20px;
    overflow: hidden;
    width: 100%;
    height: 20px;
    position: relative;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
}

.tileV2 h1 span {
    font-size: inherit;
    font-family: inherit;
    color: inherit;
    font-weight: inherit;
    white-space: nowrap;
    overflow: auto;
    position: absolute;
    top: 0px;
    left: 0px;
    overflow: hidden;
}

.tileV2 .organisation {
    border: none;
    background-color: #eff7ff;
    color: #2977c9;
    padding: 3px 10px;
    border-radius: 5px;
    text-align: center;
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
    width: fit-content;
}

.tileV2 label {
    font-size: 12px;
    line-height: 14px;
    font-weight: 400;
    color: #8e8e8e;
    display: block;
}

.tileV2 p {
    font-size: 12px;
    font-weight: 400;
    color: #157efb;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
}

.tileV2 .trial {
    color: #ccc;
    font-size: 12px;
}

.tileV2.pronetin .name {
    background-image: linear-gradient(120deg, #a1c4fd 0%, #16b6e8 100%);
}

.tileV2.pronetin .name p {
    color: #fff;
}

.tileV2.upload-doc {
    min-width: 270px;
}

.tileV2 input {
    font-size: 12px;
    padding: 5px 10px;
    border-radius: 5px;
    border: 1px solid #d1edff;
}

.tileV2.grayed {
    background-color: #f9f9f9;
}

.tileV2 input:focus {
    border: 1px solid #157efb;
}

.tileV2 .flex {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-direction: row;
}

.tileV2 .view-cert-v2-btn {
    position: absolute;
    bottom: 5px;
    left: 50%;
    transform: translate(-50%, 150%);
    padding: 5px 30px;
    border-radius: 20px;
    font-size: 14px;
    background-color: #157efb;
    color: #fff;
    font-weight: 500;
    width: max-content;
}

.tileV2:hover .view-cert-v2-btn {
    transform: translate(-50%, 0%);
}

.upload-document-modal .flex {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-direction: column;
}

.upload-document-modal .content {
    max-width: 320px !important;
}

.upload-document-modal .chosen-doc {
    max-width: none !important;
    width: 100% !important;
    justify-content: space-between !important;
    padding: 2px !important;
    background-color: #81818119;
    border: none !important;
}

.upload-document-modal .flex {
    width: 100%;
}

.upload-document-modal input {
    border: 1px solid #157efb;
}

.upload-document-modal .chosen-doc p {
    height: 20px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.upload-document-modal .custom-file-input {
    border-radius: 5px;
}

.tileV2 .custom-file-input,
.upload-document-modal .custom-file-input {
    color: transparent;
    padding: 0px;
    height: 30px;
    overflow: hidden;
}

.tileV2 .custom-file-input::-webkit-file-upload-button,
.upload-document-modal .custom-file-input::-webkit-file-upload-button {
    visibility: hidden;
}

.tileV2 .custom-file-input::before,
.upload-document-modal .custom-file-input::before {
    content: 'Choose PDF';
    color: #fff;
    display: inline-block;
    background: #157efb;
    border: none;
    border-radius: 5px;
    padding: 5px 10px;
    outline: none;
    white-space: nowrap;
    -webkit-user-select: none;
    user-select: none;
    cursor: pointer;
    font-size: 12px;
    height: 49.31px;
    width: 100%;
}

.tileV2 .custom-file-input:hover::before .upload-document-modal .custom-file-input:hover::before {
    border-color: black;
}

.tileV2 .custom-file-input:active,
.upload-document-modal .custom-file-input:active {
    outline: 0;
}

.tileV2 .chosen-doc,
.upload-document-modal .chosen-doc {
    height: 30px;
    padding: 0px;
    border: 1px solid #21937b;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    font-size: 12px;
    flex-direction: row;
    border-radius: 5px;
    color: #818181;
    overflow: hidden;
    min-width: 50%;
    max-width: 140px;
}

.tileV2 .chosen-doc p,
.upload-document-modal .chosen-doc p {
    max-width: calc(100% - 40px);
    overflow: hidden;
}

.tileV2 .chosen-doc button,
.upload-document-modal .chosen-doc button {
    padding: 4px 10px;
    width: fit-content;
    font-size: 10px;
    border-radius: 5px;
    background-color: #d5415a;
}

.tileV2 .chosen-doc button i,
.upload-document-modal .chosen-doc button i {
    font-size: inherit;
}

.upload-document-modal .bottom {
    width: 100%;
    display: block;
}

.tileV2 .upload-doc-btn,
.upload-document-modal .upload-doc-btn {
    text-decoration: none;
    background-color: #157efb21;
    border: 1px solid #157efb;
    color: #157efb;
    width: 100%;
    padding: 5px;
    border-radius: 5px;
    align-self: flex-end;
    text-align: center;
}

.upload-document-modal .upload-doc-btn {
    display: block;
}

.upload-zone {
    width: 100%;
    height: 100%;
}

.upload-zone p {
    color: #002d63;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    flex-direction: column;
    max-width: 300px;
    white-space: wrap;
}

.upload-zone p i {
    font-size: 3rem;
}

.tileV2:hover span.scrolling {
    animation-play-state: paused;
}

.tileV2 h1 span.scrolling {
    animation: text-scroll 10s linear infinite;
}

.tileV2.video {
    padding: 3px;
}

.tileV2 video {
    width: 100%;
    height: 100%;
    border-radius: 5px;
    object-fit: cover;
}

.element .tiles.marketplace {
    flex-wrap: nowrap;
    flex-direction: row;
}

.batches-new {
    flex-direction: column !important;
    background-color: #f9f9f9 !important;
}

.batches-new .wrapper {
    width: 100% !important;
    background-color: transparent !important;
    padding-top: 10px !important;
}

.batches-new .wrapper .event-wrapper {
    gap: 5px;
}

.batches-new .wrapper .event-wrapper .event-name-container {
    display: flex;
    flex-direction: column;
    gap: 0px;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 10px;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    background-color: #fff;
}

.batches-new .wrapper .event-wrapper .event-name-container.active {
    box-shadow: rgb(204, 219, 232) 3px 3px 6px 0px inset, rgba(255, 255, 255, 0.5) -3px -3px 6px 1px inset;
}

.batches-new .wrapper .event-wrapper .event-name-container p {
    color: #494949;
    font-weight: 400;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
}

.batches-new .wrapper .event-wrapper .event-name-container p i {
    color: #157efb;
    font-size: 14px;
}

.batches-new .wrapper .event-wrapper .event-name-container label {
    color: #818181;
    font-size: 12px;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    flex-direction: row;
    flex-wrap: wrap;
}

.batches-new .wrapper .event-wrapper .event-name-container label .ready {
    padding: 1px 15px;
    background-color: #2bcead;
    color: #fff;
    border-radius: 6px;
    font-size: 12px;
}

.batches-new .subevent-template-wrapper {
    width: 100%;
    overflow-x: auto;
    position: relative;
}

.batches-new .caret-container .left-caret {
    position: absolute;
    top: 0;
    left: -3px;
    width: 60px;
    height: 100%;
    background-image: linear-gradient(to right, #f9f9f9, transparent);
    display: flex;
    justify-content: flex-start;
    padding: 10px;
    align-items: center;
    z-index: 3;
}

.batches-new .caret-container .right-caret {
    position: absolute;
    top: 0;
    right: -3px;
    float: right;
    width: 60px;
    height: 100%;
    background-image: linear-gradient(to left, #f9f9f9, transparent);
    display: flex;
    justify-content: flex-end;
    padding: 10px;
    align-items: center;
    z-index: 3;
}

.batches-new .caret-container .right-caret i,
.batches-new .caret-container .left-caret i {
    color: #157efb;
    font-size: 21px;
}

.batches-new .template-wrapper {
    width: fit-content !important;
    justify-content: center !important;
    align-items: center !important;
    padding: 10px 60px !important;
    flex-direction: row !important;
    flex-wrap: nowrap !important;
    margin: auto;
}

.batches-new .subevent-template-wrapper::-webkit-scrollbar {
    display: none;
    width: 0px;
}

.batches-new .caret-container {
    position: relative;
    width: 100%;
}

.anchor-btn {
    color: #818181;
    text-decoration: underline;
    padding: 3px 10px;
}

.anchor-btn:hover {
    color: #157efb;
}

.template-subevent-new {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 0px 8px;
    border-radius: 10px;
    padding: 10px;
    width: 220px;
    min-width: 220px;
    position: relative;
    background-color: #fff;
    transition: 0.3s;
}

.template-subevent-new:hover {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 0px 2px;
    transition: 0.3s;
    cursor: pointer;
}

.template-subevent-new .subevent {
    color: #494949;
    font-size: 18px;
    padding-bottom: 3px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.template-subevent-new .content p {
    color: #818181;
    font-size: 12px;
}

.template-subevent-new .notification {
    position: absolute;
    bottom: 5px;
    right: 5px;
    background-color: #2bcead;
    color: #fff;
    font-size: 10px;
    padding: 4px 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    font-weight: 600;
    line-height: 10px;
    gap: 5px;
}

.template-subevent-new .notification i {
    font-size: 12px;
}

.headboard {
    background-color: #157efb21;
    width: 100%;
    border-radius: 10px;
    padding: 20px;
    min-height: 20vh;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
}

.batches-v2 .headboard {
    min-height: auto;
}

.batches-v2 .folder-wrap {
    max-height: 100px;
    min-height: 100px;
    padding-left: 20px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 5px;
    flex-direction: column;
    flex-wrap: nowrap;
    overflow-y: auto;
    width: 100%;
    border-bottom: 1px solid #ccc;
    margin-bottom: 10px;
}

.batches-v2 .folder-wrap .folder {
    color: #002d63;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    flex-direction: row;
    flex-wrap: nowrap;
    cursor: pointer;
    transition: 0.3s;
}

.batches-v2 .folder-wrap .folder:hover {
    gap: 13px;
    transition: 0.3s;
}

.batches-v2 .folder-wrap .folder i {
    color: #ffd738;
}

.batches-v2 .folder-wrap .folder .subevent {
    white-space: nowrap;
    overflow: hidden;
    width: 180px;
    text-overflow: ellipsis;
}

.batches-v2 .folder-wrap .sub-folder {
    color: #002d63;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    flex-direction: row;
    flex-wrap: nowrap;
    padding-left: 10px;
    cursor: pointer;
    transition: 0.3s;
}

.batches-v2 .folder-wrap .sub-folder:hover {
    gap: 13px;
    transition: 0.3s;
}

.batches-v2 .height10px {
    height: 0px;
}

.batches-v2 .folder-wrap .sub-folder i {
    color: #14304d;
}

.batches-v2 .folder-wrap .sub-folder .subevent {
    white-space: nowrap;
    overflow: hidden;
    width: 170px;
    text-overflow: ellipsis;
    font-size: 12px;
}

.batches-v2 .wrapper {
    padding: 0px !important;
    max-width: 1080px;
    border-radius: 5px;
}

.batches-v2 .template-wrapper {
    display: flex !important;
    justify-content: flex-start !important;
    align-items: flex-start !important;
    gap: 20px !important;
    flex-direction: row !important;
    flex-wrap: wrap !important;
    margin: 0 !important;
    padding: 10px !important;
}

.batches-v2 .outer {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 20px;
    flex-direction: row;
    width: 100%;
    height: calc(100% - 120px);
}

.batches-v2 .nav-v2 {
    width: 300px;
    min-width: 300px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;
    flex-direction: column;
    overflow-y: auto;
    height: 100%;
    padding: 10px;
    border-right: 1px solid #ccc;
}

.batches-v2 .nav-v2 .container {
    background-color: #fff;
    padding: 10px;
    width: 100%;
    border-radius: 10px;
    color: #002d63;
    min-height: 40px;
    box-shadow: rgba(0, 0, 0, 0.14) 0px 3px 8px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 0px;
    flex-direction: column;
    flex-wrap: nowrap;
    position: relative;
    transition: 0.3s;
    cursor: pointer;
    max-height: 40px;
}

.batches-v2 .nav-v2 .container:hover {
    box-shadow: rgba(58, 120, 214, 0.34) 0px 1px 9px;
    transition: 0.3s;
    padding-left: 13px;
}

.batches-v2 .nav-v2 .container.active {
    min-height: 80px;
    background-image: linear-gradient(120deg, #14304d, #1c4c7f);
    height: fit-content;
    cursor: default;
}

.batches-v2 .nav-v2 .container.active:hover {
    padding-left: 10px;
}

.batches-v2 .nav-v2 .container .ready {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #1fd5b0;
    position: absolute;
    bottom: 5px;
    right: 5px;
}

.batches-v2 .nav-v2 .container.active p {
    color: #fff;
    font-size: 18px;
    padding-bottom: 3px;
}

.batches-v2 .nav-v2 .container .event-name {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    width: 240px;
    text-overflow: ellipsis;
}

.batches-v2 .nav-v2 .container label {
    font-size: 12px;
    color: #fff;
}

.batches-v2 .batch-container {
    padding: 10px;
}

.batches-v2 .subevent-wrapper {
    gap: 5px;
}

.batches-v2 .event-name-display {
    border-bottom: 1px solid #ccc;
    width: 100%;
    padding-bottom: 10px;
    position: sticky;
    top: 0px;
    z-index: 3;
    background-color: #f9f9f9;
    box-shadow: #f9f9f9a9 0px 0px 24px 20px;
}

.batches-v2 .event-name-display p {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    flex-direction: row;
    flex-wrap: wrap;
}

.batches-v2 .event-name-display p span,
.batches-v2 .event-name-display p a {
    max-width: 120px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.batches-v2 .event-name-display p a {
    text-decoration: none;
    color: inherit;
}

.batches-v2 .event-name-display p a:hover {
    text-decoration: underline;
}

.headboard .head-container a:hover {
    text-decoration: underline;
}

.headboard .content-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    flex-direction: column;
    gap: 10px;
    height: 100%;
}

.headboard .content-container .content {
    text-align: right;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    gap: 5px;
    flex-direction: column;
}

.headboard .content-container .content .refresh {
    color: #21937b;
    background-color: #fff;
    padding: 3px 10px;
    font-size: 12px;
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    border-radius: 6px;
    width: fit-content;
}

.headboard h2 {
    font-size: 21px !important;
    flex-direction: column;
    align-items: flex-start !important;
    gap: 0px !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #000;
}

.headboard h2 a,
.headboard h2 span a {
    font-size: inherit;
    color: inherit;
    font-weight: inherit;
    text-decoration: none;
}

.headboard h2 span {
    font-size: 18px !important;
    display: block;
    color: #157efb;
    font-weight: 400;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0px;
}

.headboard h2 span.small {
    font-size: 14px !important;
    padding-left: 10px;
    color: #2b76b9;
}

.headboard h2 span i {
    transform: rotate(90deg);
    padding-left: 10px;
    padding-right: 5px;
}

.headboard .sort-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-direction: row;
    background-color: #fff;
    padding: 5px 10px;
    border-radius: 10px;
    align-self: flex-end;
}

.headboard .sort-container p {
    color: #000;
}

.headboard .sort-container hr {
    height: 20px;
    width: 3px;
    background-color: #ccc;
    border: none;
}

.headboard .sort-container select {
    padding: 0px !important;
    font-size: inherit;
    border: none;
}

.doc-alt-marketplace {
    height: fit-content !important;
    position: fixed;
    bottom: 0px;
    left: 0px;
    width: 100%;
    padding: 0px !important;
}

.doc-alt-marketplace .mob-actions {
    background-color: #d1edff;
}

.marketplace .nav-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 15px;
    padding-top: 10px;
    overflow-y: auto;
}

.marketplace .nav-container::-webkit-scrollbar {
    width: 0px;
    display: none;
}

.marketplace .document-info {
    padding: 10px;
    background-color: #fff;
    border-radius: 10px;
    margin-bottom: 10px;
}

.marketplace .nav-container .request {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;
    background-color: #fff;
    border-radius: 10px;
    padding: 10px;
}

.marketplace .nav-container hr {
    width: 100%;
    border: none;
    background-color: #157efb6b;
    height: 1px;
}

.marketplace .nav-container .request .btn {
    background-color: #ffcb74;
    padding: 10px 20px;
    color: #000;
    font-size: 14px;
    border-radius: 5px;
    transition: 0.3s;
    text-decoration: none;
    display: block;
}

.marketplace .nav-container .request .btn:hover {
    background-color: #ff9800;
    transition: 0.3s;
}

.marketplace .nav-container .info-container {
    width: 100%;
}

.marketplace .nav {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    text-decoration: none;
    color: #000;
    font-size: 16px;
    flex-wrap: wrap;
    max-width: 100%;
}

.nav2-container {
    gap: 1px !important;
}

.marketplace .nav2 {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    text-decoration: none;
    color: #818181;
    font-size: 14px;
    flex-wrap: wrap;
    width: 100%;
    max-width: 100%;
    padding: 6px 10px;
}

.marketplace .nav p.ellipsis {
    max-width: calc(100% - 55px);
    color: #8e8e8e;
    font-family: 'Poppins', 'Open Sans', sans-serif;
    font-size: 14px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.marketplace .nav.active {
    background-image: linear-gradient(to right, #d1edff 10%, transparent 90%);
    border-radius: 10px;
}

.marketplace .nav2.active {
    background-color: #157efb21;
    color: #000;
    border-radius: 30px;
}

.marketplace .nav.active i {
    background-color: #2977c9;
    color: #fff;
}

.marketplace .nav.active:hover i {
    background-color: #2977c9;
    color: #fff;
}

.marketplace .nav.green i {
    color: #2bcead;
    border: 1px solid #2bcead;
}

.marketplace .nav.yellow i {
    color: #ff9800;
    border: 1px solid #ff9800;
}

.marketplace .nav:hover i {
    background-color: #fff;
    transition: 0.3s;
}

.marketplace .nav.warn i {
    color: #d5415a;
    border: 1px solid #d5415a;
    transition: 0.3s;
}

.marketplace .nav i {
    color: #2977c9;
    background-color: #f1f1f1;
    border-radius: 10px;
    border: 1px solid #2977c9;
    width: 45px;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 19px;
    transition: 0.3s;
}

.marketplace .image-container {
    max-width: 100%;
    height: 100%;
    max-height: 100%;
    overflow: hidden;
}

.marketplace .image-container img {
    max-width: 100%;
    /* height: auto; */
    height: 100%;
    max-height: 100%;
}

.marketplace .view-toggle {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    font-size: 12px;
}

.marketplace .view-toggle p {
    font-size: inherit;
    line-height: 14px !important;
}

.marketplace .view-toggle .toggle {
    display: flex;
    height: 30px;
    width: 45px;
    min-width: 45px;
    background-color: #157efb21;
    border: 1px solid #157efb;
    border-radius: 30px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 2px;
}

.marketplace .view-toggle .toggle .slider {
    width: 24px;
    font-size: 12px;
    height: 24px;
    border-radius: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.marketplace .view-toggle .toggle .slider i {
    font-size: inherit;
}

.marketplace .view-toggle .toggle.public .slider {
    background-color: #21937b;
    color: #fff;
}

.marketplace .view-toggle .toggle.private .slider {
    background-color: #d5415a;
    color: #fff;
}

.marketplace .view-toggle .toggle.private {
    justify-content: flex-start;
}

.marketplace .pending-name-change {
    background-color: #ff98001a;
    color: #000 !important;
    padding: 10px;
    border-radius: 5px;
    margin-top: 15px;
}

.tile-wrapper .action button:hover {
    background-color: #157efb;
    transition: 0.3s;
}

.tile .view-option {
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
    flex-direction: row;
    align-items: center;
    width: 100%;
}

.tile .view-option.public {
    color: #2bcead;
    font-weight: 600;
}

.tile .view-option.public .view {
    border: 1px solid #2bcead;
    padding: 5px 10px;
    border-radius: 10px;
}

.tile .view-option.private {
    color: #df7184;
    font-weight: 600;
}

.tile .view-option.private .view {
    border: 1px solid #df7184;
    padding: 5px 10px;
    border-radius: 10px;
}

.tile .view-option.comingsoon {
    color: #157efb;
    font-weight: 600;
}

.tile .view-option.comingsoon .view {
    border: 1px solid #157efb;
    padding: 5px 10px;
    border-radius: 10px;
}

.tile h1 {
    font-size: 18px;
    font-weight: 600;
    color: #000;
    line-height: 22px;
    overflow: hidden;
    width: 100%;
    height: 42px;
    position: relative;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
}

.tile h1 span {
    font-size: inherit;
    font-family: inherit;
    color: inherit;
    font-weight: inherit;
    white-space: nowrap;
    overflow: auto;
    position: absolute;
    top: 0px;
    left: 0px;
    overflow: hidden;
    width: fit-content;
}

.tile:hover span.scrolling {
    animation-play-state: paused;
}

.tile h1 span.scrolling {
    animation: text-scroll 10s linear infinite;
}

@keyframes text-scroll {
    0% {
        transform: translateX(0);
    }

    20% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(-100%);
    }
}

.tile h1 label {
    font-size: 12px;
    line-height: 14px;
    font-weight: 400;
    color: #8e8e8e;
    display: block;
    position: absolute;
    bottom: 0px;
    left: 0px;
}

.tile p {
    font-size: 14px;
    font-weight: 400;
    color: #8e8e8e;
    display: block;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
}

.tile .organisation {
    border: 1px solid #2977c9;
    background-color: #eff7ff;
    color: #2977c9;
    padding: 5px 10px;
    border-radius: 10px;
    text-align: center;
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
}

.tile .bottom {
    width: 100%;
    margin-top: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 15px;
}

.tile .bottom .trial {
    color: #df7184;
    font-size: 14px;
}

.tile .bottom .views {
    color: #8e8e8e;
    font-size: 14px;
    font-weight: 600;
}

.template-env {
    width: 100%;
    /* width: 940px; */
    min-height: 100px;
    background-color: #fff;
    aspect-ratio: 4/3;
    border-radius: 10px;
    position: relative;
}

.issue-page {
    justify-content: space-between;
}

.certificate-template {
    position: relative;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 1;
}

.hide {
    display: block;
    height: 0px;
    overflow: hidden;
    width: fit-content;
}

.box {
    z-index: 2;
    position: absolute;
    top: 0;
    left: 0;
    cursor: move;
    color: black;
    background-color: transparent;
    border-radius: 5px;
    border: none;
    margin: auto;
    user-select: none;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;
    flex-direction: column;
    width: auto;
}

.box.pinned {
    box-shadow: none;
}

.box.pinned input::-webkit-scrollbar,
.box.pinned textarea::-webkit-scrollbar {
    width: 0px;
    height: 0px;
}

.box textarea,
.box input {
    border: 2px solid transparent;
    color: #000;
    background-color: transparent;
    border-radius: 5px;
    text-align: left;
    /* padding: 5px; */
    padding: 0px;
    width: 300px;
    max-width: 100%;
    overflow: hidden;
    min-height: 30px;
    resize: none;
    cursor: move;
    caret-color: transparent;
}

.box input[type='date'] {
    width: fit-content;
}

.box input:hover,
.box textarea:hover {
    transition: none;
    border: 2px solid #818181;
}

.box.selectFocus input,
.box.selectFocus textarea {
    transition: 0.3s;
    animation-name: selectFocus;
    animation-duration: 1s;
    animation-direction: normal;
}

@keyframes selectFocus {
    0% {
        background-color: transparent;
    }

    50% {
        background-color: #157efb21;
    }

    100% {
        background-color: transparent;
    }
}

.box.pinned textarea,
.box.pinned input {
    border: 2px dashed #157efb;
    resize: both;
    cursor: text;
    caret-color: #000;
    transition: none;
}

.box.selected textarea,
.box.selected input {
    transition: none;
    border: 2px solid #818181;
}

.box.pinned input::-webkit-calendar-picker-indicator {
    margin-right: 15px;
}

.box.pinned .content-controls input {
    border: 2px solid #157efb;
    border-radius: 10px;
}

.box .wrap {
    position: relative;
    display: inline-block;
}

.box .wrap.no-pull-tab:after {
    display: none !important;
}

.box .wrap .rel-guide-y-top {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 200vw;
    height: 0px;
    border: none;
    border-top: 2px dashed #ffcb74;
    display: none;
    z-index: 998;
}

.box .wrap .rel-guide-y-bottom {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 200vw;
    height: 0px;
    border: none;
    border-top: 2px dashed #ffcb74;
    display: none;
    z-index: 998;
}

.box .wrap .rel-guide-y-middle {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 200vw;
    height: 0px;
    border: none;
    border-top: 2px dashed #ffcb74;
    display: none;
    z-index: 998;
}

.box .wrap .rel-guide-x-top {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    height: 200vh;
    width: 0px;
    border: none;
    border-left: 2px dashed #ffcb74;
    display: none;
    z-index: 998;
}

.box .wrap .rel-guide-x-bottom {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    height: 200vh;
    width: 0px;
    border: none;
    border-left: 2px dashed #ffcb74;
    display: none;
    z-index: 998;
}

.box .wrap .rel-guide-x-middle {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    height: 200vh;
    width: 0px;
    border: none;
    border-left: 2px dashed #ffcb74;
    display: none;
    z-index: 998;
}

.box .wrap .rotate-handle-container {
    position: absolute;
    bottom: -60px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    flex-direction: row;
}

.box .wrap .rotate-handle {
    height: fit-content;
    min-height: auto;
    padding: 0px;
    height: 25px;
    width: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: #002d63;
    color: #fff;
    cursor: grab;
}

.box .rotate-wrap {
    position: relative;
}

.box .wrap .rotate-handle-container .angle-view {
    min-height: auto;
    width: 35px;
    border: 1px solid #002d63;
    background-color: #002d63;
    color: #fff;
    border-radius: 3px;
    padding: 1px 5px;
    font-size: 12px;
    font-weight: 600;
    -moz-appearance: textfield;
    -webkit-appearance: none;
    margin: 0;
    caret-color: #fff;
}

.box .wrap .rotate-handle-container .angle-view::-webkit-outer-spin-button,
.box .wrap .rotate-handle-container .angle-view::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.box.pinned .wrap:after {
    content: '';
    border: 10px solid #157efb;
    z-index: 1;
    position: absolute;
    right: -7px;
    bottom: -2px;
    pointer-events: none;
    display: block;
}

.box .pull-tab {
    display: none;
}

.box.pinned .pull-tab {
    height: 0px;
    width: 0px;
    border: 8px solid #fff;
    position: absolute;
    bottom: -0px;
    right: -5px;
    pointer-events: none;
    z-index: 2;
    display: block;
}

.box .vLine .shape,
.box .hLine .shape {
    background-color: #8e8e8e;
}

.box .square .shape {
    background-color: transparent;
    border: 2px solid #8e8e8e;
}

.box .circle .shape {
    background-color: transparent;
    border: 2px solid #8e8e8e;
    border-radius: 50%;
}

.box .shape {
    width: 100%;
    height: 100%;
    min-width: 7px;
    min-height: 7px;
}

.box .imagearea {
    box-shadow: 0px 0px 10px transparent;
    transition: none;
    overflow: hidden;
    transition: 0.3s;
}

.box .imagearea-no-hover {
    box-shadow: none;
    transition: none;
    overflow: hidden;
}

.box .imagearea img {
    width: 100%;
    height: 100%;
    object-fit: fill !important;
}

.box .imagearea:hover {
    box-shadow: 0px 0px 10px #2977c939;
    transition: 0.3s;
}

.box .imagearea.selected {
    box-shadow: 0px 0px 10px #2977c939;
    transition: none;
}

.box.pinned .imagearea {
    box-shadow: 0px 0px 0px 2px #157efb;
    transition: none;
    cursor: not-allowed;
}

.box .sign-container {
    height: fit-content;
    display: block;
    font-family: 'Poppins', 'Open Sans', sans-serif;
    font-size: 14px;
    font-weight: 300;
    color: #818181;
    text-align: center;
    background-color: transparent;
    max-width: 140px;
    white-space: nowrap;
    position: relative;
    text-align: center;
}

.box .sign-container img {
    mix-blend-mode: multiply;
    object-fit: contain !important;
    max-width: 140px;
}

.box .sign-container h1 {
    font-weight: 600;
    display: inline-block;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    white-space: nowrap;
    margin: 0;
    min-width: 100%;
}

.box .sign-container h2 {
    font-weight: 400 !important;
    font-size: 12px !important;
    justify-content: center;
    color: #818181;
    white-space: break-spaces;
    width: 200px;
    transform: translateX(-30px);
    line-height: 14px;
}

.box table {
    border: 2px solid #dddddd;
    border-collapse: collapse;
    width: 100%;
    height: 100%;
    table-layout: fixed;
}

.box table:hover {
    border: 2px solid #818181;
}

.box td,
.box th {
    border: 1px solid #dddddd;
    padding: 1px;
    padding: 3px;
    font-size: inherit !important;
    height: 30px;
}

.box table tbody,
.box table thead,
.box tr,
.box td {
    font-size: inherit !important;
    font-family: inherit !important;
    font-weight: inherit !important;
    color: inherit !important;
    font-style: inherit !important;
    text-decoration: inherit !important;
    text-align: inherit !important;
    line-height: inherit !important;
    word-break: break-word;
}

.table-wrapper {
    width: 300px;
    min-width: 125px;
    overflow: auto;
}

.table-wrapper::-webkit-scrollbar {
    width: 0;
    display: none;
}

.box table i {
    padding: 5px;
}

.box.pinned table {
    cursor: default;
}

.box table td:last-child {
    cursor: default;
}

.box table i.fa-circle-xmark {
    color: #df7184;
    background-color: transparent;
    border-radius: 5px;
    transition: 0.3s;
}

.box table i.fa-circle-xmark:hover {
    color: #f1f1f1;
    background-color: #df7184;
    transition: 0.3s;
}

.box tr:nth-child(even) {
    background-color: #00000011;
}

.addRow-btn {
    background-color: #2bcead;
    color: #fff;
    font-size: 14px;
    font-weight: 400;
    padding: 3px 15px !important;
    height: auto;
    border-radius: 5px;
    display: block;
    transition: 0.3s;
    gap: 10px;
    height: auto !important;
}

.addRow-btn:hover {
    background-color: #21937b;
    transition: 0.3s;
}

.field-label {
    display: block;
    color: #8e8e8e;
    font-size: 12px;
    font-weight: 500;
    padding-left: 2px;
}

.td-delete {
    min-width: none !important;
}

.td-delete i {
    display: block;
    margin: auto;
    width: fit-content;
}

.inherit {
    width: auto;
    min-width: 60px;
    outline: none;
    border: 2px solid #dddddd !important;
}

.inherit:hover {
    border: 2px solid #dddddd !important;
}

.pinned .inherit {
    border: 2px dashed #157efb !important;
}

.pinned .inherit:hover {
    border: 2px dashed #157efb !important;
}

.variable-controls {
    position: absolute;
    width: fit-content;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 10px;
    background-color: transparent;
    padding: 0px;
    border-radius: 10px;
    top: -30px;
    right: 0px;
    vertical-align: middle;
}

.variable-controls.lock-controls {
    left: 0;
    right: auto;
}

.variable-controls button {
    padding: 0px 10px !important;
    height: 25px !important;
    width: fit-content;
    font-size: 14px;
    font-weight: 300;
    border-radius: 5px;
}

.variable-controls .delete {
    background-color: #f1f1f1;
    color: #d5415a;
}

.variable-controls .variable {
    background-color: #2bcead;
    color: #fff;
}

.variable-controls .readonly {
    background-color: #d5415a;
    color: #fff;
}

.variable-controls .help {
    background-color: #d1edff;
    color: #157efb;
}

.content-controls {
    position: absolute;
    width: fit-content;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 10px;
    background-color: #fff;
    padding: 0px;
    border-radius: 10px;
    bottom: -25px;
    left: 0px;
}

.content-controls .container {
    position: relative;
}

.content-controls .container button {
    padding: 5px 10px !important;
    height: 35px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    width: fit-content;
    background-color: #2977c9;
    color: #fff;
    position: absolute;
    top: 0;
    right: 0;
}

.content-controls .container input {
    padding-right: 35px;
    width: 150px;
}

.content-controls button {
    padding: 5px 10px !important;
    height: 25px !important;
    border-radius: 5px;
    width: fit-content;
    font-size: 14px;
    font-weight: 300;
    background-color: #d1edff;
    color: #157efb;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    border: 1px solid #d1edff;
}

.content-controls button:hover {
    border: 1px solid #157efb;
}

.content-controls input {
    background-color: #f6f6f6;
    width: fit-content;
    resize: both;
    padding: 5px 10px;
    font-size: 14px;
    height: 35px;
    border: none;
}

.controls {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 10px;
    background-color: #f1f1f1;
    padding: 5px;
    border-radius: 10px;
}

.controls select,
.controls input {
    background-color: #fff;
    width: fit-content;
    resize: both;
    padding: 5px 10px;
    font-size: 14px;
    height: 35px;
    border: none;
}

.controls select:hover,
.controls input:hover {
    border: none;
}

.controls button {
    background-color: #fff;
    color: #000;
    padding: 4px 10px;
    font-size: 17px;
}

.controls button.bold {
    font-weight: 500;
    width: 35px;
    height: 35px;
}

.controls button.italic {
    width: 35px;
    height: 35px;
    font-weight: 400;
    font-style: italic;
    font-family: 'Times New Roman', Times, serif;
}

.controls button.active {
    background-color: #2977c9;
    color: #fff;
}

.controls .container {
    display: flex;
    flex-direction: column;
    gap: 5px;
    justify-content: flex-start;
    align-items: flex-start;
    position: relative;
}

.controls .container h1 {
    text-align: center;
    font-size: 12px;
    font-weight: 600;
    width: 100%;
}

::placeholder {
    font-weight: 600;
    color: #000;
}

.close-btn {
    position: absolute;
    top: -10px;
    right: -15px;
    border-radius: 50%;
    background-color: #fff;
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px;
    z-index: 100;
}

.close-btn i {
    color: #df7184;
    font-size: 20px;
}

.pin-btn {
    position: absolute;
    top: -10px;
    left: -15px;
    border-radius: 50%;
    background-color: #8e8e8e;
    color: #fff;
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px;
}

.pin-btn.active {
    background-color: #2bcead;
}

.grid-btn {
    padding: 3px 20px;
    background-color: #8e8e8e;
    color: #fff;
    width: fit-content;
    font-size: 14px;
}

.pin-btn i {
    color: inherit;
    font-size: 12px;
}

.palette {
    height: 35px;
    width: 35px;
    border: 2px solid #2977c9;
    transition: 0.3s;
    color: #000;
    padding: 0px !important;
    font-weight: 500;
}

.color-picker-modal {
    position: absolute;
    z-index: 99;
    top: -50px;
    right: 81px;
    transform: translate(12%, 36%);
    border-radius: 10px;
    cursor: pointer;
}

.color-picker-modal .color-picker-component {
    position: relative;
}

.color-picker-modal .color-picker-component .dropper {
    position: absolute;
    bottom: -33px;
    right: 0px;
    background-color: #fff;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 6px;
    border: 2px solid #002d63;
}

.cursor-crosshair {
    cursor: crosshair;
}

.sketch-picker {
    box-shadow: -6px 6px 20px 0px #00000021 !important;
    border-radius: 10px !important;
}

.sketch-picker input {
    height: auto !important;
    border-radius: 2px;
    width: 100% !important;
}

.sketch-picker label {
    transition: none !important;
    letter-spacing: normal !important;
}

/* ---------- Profile Editor ----------- */

.element .settings {
    height: 100vh;
    border-radius: 10px;
    background-color: #fff;
    padding: 20px;
}

.element .settings::-webkit-scrollbar {
    width: 0px;
}

.sub-nav {
    width: 310px;
    height: 100%;
    background-color: #f9f9f9;
    border-radius: 10px;
    position: relative;
}

.marketplace .sub-nav {
    min-width: 310px;
}

.break-20px {
    min-height: 20px;
}

.settings .wrapper,
.marketplace .wrapper {
    padding: 0px;
    display: flex;
    justify-content: flex-start;
    gap: 20px;
    align-items: flex-start;
    flex-direction: column;
    height: 100%;
    flex-grow: 1;
    overflow-y: scroll;
    padding: 0px 10px;
}

.marketplace .wrapper::-webkit-scrollbar {
    display: none;
    width: 0px;
}

.marketplace .wrapper.scroll-bar::-webkit-scrollbar {
    display: block;
    width: 26px;
    border-radius: 13px;
    background-clip: padding-box;
    border: 10px solid transparent;
}

.row-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    gap: 20px;
    width: 100%;
}

.row-container .profile-message {
    padding: 10px;
    border-radius: 5px;
    background-color: #d1edff;
    color: #002d63;
    max-width: 400px;
    line-height: 21px;
}

.row-container .profile-icon-edit {
    width: 120px;
    height: 120px;
    border: 2px solid #2977c9;
    border-radius: 10px;
    position: relative;
    overflow: hidden;
}

.settings input.gray {
    background-color: #f1f1f1;
    border: 1px solid #f1f1f1;
    width: 400px;
}

.settings button,
.settings .button {
    width: fit-content;
    padding: 10px 30px;
    font-family: 'Poppins', sans-serif;
    font-size: 18px;
    border-radius: 10px;
    background-color: #2977c9;
    border: none;
    color: #fff;
    font-weight: 300;
    text-decoration: none;
}

.settings input,
.settings select {
    border: 1px solid #2977c9;
    color: #000;
    width: 300px;
    max-width: 100%;
}

.new-change {
    position: absolute;
    top: 10px;
    left: 10px;
    font-size: 12px !important;
    background-color: #002d63;
    color: #fff !important;
    padding: 2px 5px;
    border-radius: 3px;
    font-weight: 300 !important;
}

.settings img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    object-position: center;
    border-radius: 0px;
}

.settings-container .apply-btn {
    position: absolute;
    bottom: 10px;
    right: 10px;
    padding: 0px;
    width: fit-content;
    background-color: transparent;
    border-radius: 0px;
    display: flex;
    justify-content: flex-end;
}

.settings-container .apply-btn button {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    transition: 0.3s;
}

.settings-container .apply-btn button p {
    display: none;
    color: #fff;
}

.settings-container .apply-btn button:hover {
    width: 100px;
    border-radius: 10px;
    transition: 0.3s;
}

.settings-container .apply-btn button:hover p {
    display: block;
}

.settings-container .apply-btn button i {
    font-size: 21px;
}

.row-container .profile-icon-edit .edit-btn {
    position: absolute;
    bottom: 5px;
    right: 5px;
    height: 30px;
    width: 30px;
    padding: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.3s;
}

.row-container .profile-icon-edit .edit-btn:hover {
    background-color: #d1edff;
    color: #157efb;
    transition: 0.3s;
}

.check-validity-container {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;
    flex-direction: column;
}

.check-validity-container p {
    font-size: 14px !important;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell,
        'Open Sans', 'Helvetica Neue', sans-serif !important;
    color: #002d63 !important;
}

.check-validity-container .green {
    color: #21937b;
}

.check-validity-container .red {
    color: #d5415a;
}

.column-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    gap: 5px;
    color: #000;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
}

.column-container label {
    font-size: 14px;
    color: #818181;
}

.column-container label span {
    color: #157efb;
    font-weight: 600;
}

.row-container .column-container {
    width: fit-content;
}

.settings p {
    font-family: 'Poppins', sans-serif;
    color: #2977c9;
    font-size: 14px;
}

.settings p.info {
    color: #818181;
    font-family: 'Poppins', 'Open Sans', sans-serif;
}

.settings p.warn {
    color: #d5415a;
    font-family: 'Poppins', 'Open Sans', sans-serif;
}

.settings h1 {
    font-family: 'Poppins', sans-serif;
    color: #000;
    font-size: 18px;
    font-weight: 500;
    text-transform: capitalize;
    line-height: 18px;
}

.settings .danger-zone {
    color: #df7184;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    width: 100%;
    gap: 20px;
}

.settings .danger-zone p {
    color: #df7184;
}

.danger-zone hr {
    width: 50%;
    border: none;
    background-color: #df7184;
    height: 2px;
}

.settings .partition-zone p {
    color: #2bcead;
}

.partition-zone hr {
    width: 25%;
    border: none;
    background-color: #2bcead;
    height: 2px;
}

.red-btn {
    background-color: #df7184 !important;
}

.red-btn:hover {
    background-color: #d5415a !important;
}

.settings .text {
    font-weight: 300;
    color: #8e8e8e;
    width: 50%;
}

.complete-profile {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 30px;
    background-color: #fff;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    color: #157efb;
    width: calc(100% - 55px);
    padding: 20px;
    border-radius: 10px;
    margin: 10px;
    position: relative;
    flex-wrap: wrap;
}

.complete-profile h2 {
    font-size: 16px;
    font-weight: 600;
    color: #002d63;
}

.complete-profile p {
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell,
        'Open Sans', 'Helvetica Neue', sans-serif;
    line-height: 21px;
    font-size: 14px;
}

.complete-profile p.pointer {
    padding-left: 10px;
}

.complete-profile .pointer i.red {
    color: #d5415a;
}

.complete-profile .pointer i.green {
    color: #21937b;
}

.complete-profile .circle-wrap {
    width: 150px;
    height: 150px;
    background: #e6e2e7;
    border-radius: 50%;
}

.complete-profile .circle-wrap .circle .mask,
.complete-profile .circle-wrap .circle .fill {
    width: 150px;
    height: 150px;
    position: absolute;
    border-radius: 50%;
}

.complete-profile .circle-wrap .circle .mask {
    clip: rect(0px, 150px, 150px, 75px);
}

.complete-profile .circle-wrap .circle .mask .fill {
    clip: rect(0px, 75px, 150px, 0px);
    background-color: #2bcead;
}

.complete-profile .circle-wrap .circle .mask.full,
.complete-profile .circle-wrap .circle .fill {
    animation: fill ease-in-out 1.5s;
    transform: rotate(var(--circle-percentage));
}

@keyframes fill {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(var(--circle-percentage));
    }
}

.complete-profile .circle-wrap .inside-circle {
    width: 130px;
    height: 130px;
    border-radius: 50%;
    background: #fff;
    line-height: 130px;
    text-align: center;
    margin-top: 10px;
    margin-left: 10px;
    position: absolute;
    z-index: 100;
    font-weight: 600;
    font-size: 2em;
    color: #157efb;
}

/* -----------Profile Storage----------- */

.profile-progress {
    position: absolute;
    top: 0;
    left: 0;
    transform: scale(1);
    width: 100%;
    height: 100%;
    border-radius: 10px;
    overflow: hidden;
}

.wave {
    position: relative;
    width: 100%;
    height: 100%;
    background: #2977c9;
    border-radius: 10px;
}

.wave.red {
    background: #df7184;
}

.wave:before,
.wave:after {
    content: '';
    position: absolute;
    height: 110%;
    aspect-ratio: 1;
    top: var(--storage-var);
    left: 50%;
    transform: translate(-50%, -75%);
    background: #000;
}

.wave:before {
    border-radius: 45%;
    background: transparent;
    animation: animate 20s linear infinite;
}

.wave:after {
    border-radius: 40%;
    background: #f1f1f1;
    animation: animate 10s linear infinite;
}

.sub-nav .profile-storage {
    position: absolute;
    top: 0;
    left: 0;
    padding: 20px;
    font-size: 18px;
    font-weight: 400;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 5px;
    color: #000;
    width: 100%;
}

.sub-nav button {
    border: 1px solid #fff;
}

.sub-nav button:hover {
    border: 1px solid #fff;
    transition: 0.3s;
}

.sub-nav .profile-storage label {
    font-size: 40px;
    font-weight: 600;
    text-transform: capitalize;
}

.sub-nav .profile-storage label b {
    font-size: 14px;
    color: #8e8e8e;
    padding-left: 10px;
    font-weight: 400;
}

@keyframes animate {
    0% {
        transform: translate(-50%, -70%) rotate(0deg);
    }

    100% {
        transform: translate(-50%, -70%) rotate(360deg);
    }
}

/* -----------Plan Container----------- */

.flex-grow {
    flex-grow: 1;
}

.plan-container {
    background-color: #f1f1f1;
    border: 3px solid #fff;
    border-radius: 10px;
    padding: 10px;
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    align-items: flex-start;
    flex-direction: column;
}

.plan-container.issue-box {
    border: none;
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 0px 8px;
}

.plan-container h1 {
    font-family: 'Poppins', sans-serif;
    color: #2977c9 !important;
    font-weight: 400 !important;
}

.plan-container p {
    color: #8e8e8e !important;
    font-family: 'Poppins', 'Open Sans', sans-serif !important;
}

.plan-container p.font-red {
    color: #d5415a !important;
}

.plan-container button {
    width: fit-content;
    padding: 10px 30px;
}

.plan-container button.deactive {
    background-color: #dddddd;
}

/* -----------Marketplace----------- */

.element .marketplace {
    height: 100vh;
    border-radius: 10px;
    background-color: #fff;
    padding: 20px;
    max-width: 100%;
}

.marketplace p {
    font-family: 'Poppins', sans-serif;
    color: #2977c9;
    font-size: 14px;
}

.explanation-wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;
    flex-direction: column;
    padding: 20px;
    background-color: #21937b29;
    border-radius: 5px;
    width: 100%;
}

.marketplace .create-certificate-message {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 20px;
}

.marketplace .create-certificate-message a {
    text-decoration: none;
    color: #157efb;
    border: 1px solid transparent;
    padding: 5px 20px;
    border-radius: 10px;
}

.marketplace .create-certificate-message a:hover {
    border-color: #157efb;
}

.marketplace .explanation-content {
    color: #818181;
    font-family: 'Poppins', 'Open Sans', sans-serif !important;
}

.marketplace .explanation-content a {
    color: #157efb;
}

.marketplace .explanation-btn {
    padding: 10px 20px;
    background-color: #21937b;
    color: #fff;
    text-decoration: none;
    display: block;
    border-radius: 5px;
    width: fit-content;
}

.marketplace .wrapper {
    width: calc(100% - 330px);
    position: relative;
}

.marketplace .template-wrapper {
    padding: 0px;
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
}

.marketplace .template-tile {
    min-height: 200px;
    height: 200px;
    /* width: 267px; */
    width: auto;
    min-width: 138px;
    max-width: none;
    background-color: #eff7ff;
    border: 2px solid #eff7ff;
    border-radius: 10px;
    display: block;
    position: relative;
    margin-bottom: 10px;
    overflow: hidden;
}

.marketplace .template-tile.button {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #157efb;
    border: 1px solid #eff7ff;
    font-size: 16px;
    font-weight: 600;
    padding: 20px;
    text-align: center;
    flex-direction: column;
    gap: 10px;
    transition: 0.3s;
    cursor: pointer;
    max-width: 200px;
}

.marketplace .template-tile.button:hover {
    border: 1px solid #157efb;
    transition: 0.3s;
}

.marketplace .template-tile.button i {
    font-size: 21px;
}

.marketplace .template-tile.button p {
    font-family: 'Poppins', 'Open Sans', sans-serif;
    font-size: 12px;
    font-weight: 600;
    color: #818181;
}

.marketplace .template-tile .preview-btn {
    position: absolute;
    top: 10px;
    left: 10px;
    z-index: 3;
    padding: 0px 10px;
    height: 30px;
    width: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    min-height: auto;
    font-family: 'Poppins', 'Open Sans', sans-serif;
    font-size: 12px;
    font-weight: 600;
    gap: 5px;
}

.marketplace .template-tile .delete-btn {
    position: absolute;
    bottom: 10px;
    right: 10px;
    z-index: 3;
    padding: 0px 10px;
    height: 30px;
    width: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    min-height: auto;
    font-family: 'Poppins', 'Open Sans', sans-serif;
    font-size: 12px;
    font-weight: 600;
    gap: 5px;
    background-color: #d5415a;
}

.marketplace .template-tile .content {
    position: absolute;
    background-color: transparent;
    top: 0;
    left: 0;
    padding: 20px;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    gap: 10px;
    flex-direction: column;
}

.signature-preview {
    position: relative;
    min-height: 190px;
    width: auto;
    height: 200px;
    animation: pan-zoom 12s infinite;
}

.signature-preview img {
    object-fit: cover;
}

@keyframes pan-zoom {
    0% {
        transform: scale(1) translateY(-0%);
    }

    20% {
        transform: scale(3) translateY(-30%);
    }

    40% {
        transform: scale(3) translateY(-30%);
    }

    60% {
        transform: scale(1) translateY(-0%);
    }
}

.signature-preview .user-signature {
    position: absolute;
    bottom: 16%;
    left: 50%;
    transform: translateX(-50%);
    width: 16%;
    height: auto;
    border-radius: 0px;
    padding: 0px;
}

.signature-privacy-btn {
    background-color: #818181 !important;
    color: #fff !important;
    font-family: 'Poppins', 'Open Sans', sans-serif;
    font-size: 12px;
    font-weight: 600;
}

.marketplace .template-tile .content.center {
    justify-content: center;
    align-items: center;
}

.marketplace .template-tile .content.center p {
    color: #818181;
    font-family: 'Poppins', 'Open Sans', sans-serif;
}

.marketplace .template-tile .content .container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    flex-direction: row;
    gap: 10px;
    height: fit-content;
}

.marketplace .template-tile .content button {
    padding: 5px 10px;
    border-radius: 5px;
    background-color: #21937b;
    height: fit-content;
    min-height: auto;
    font-family: 'Poppins', 'Open Sans', sans-serif;
    font-size: 14px;
    font-weight: 600;
}

.marketplace .template-tile .content .del-btn {
    background-color: #d5415a;
}

.marketplace .template-subevent {
    height: 200px;
    width: 267px;
    max-width: none;
    background-color: #fff;
    border: 1px solid #f1f1f1;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    gap: 10px;
    flex-direction: column;
    position: relative;
}

.marketplace .template-subevent .subevent {
    background-color: #002d63;
    color: #fff;
    width: 100%;
    padding: 15px 10px;
    border-radius: 10px 10px 0px 0px;
}

.marketplace .template-subevent .data {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    flex-direction: row;
    width: 100%;
    padding: 10px;
}

.marketplace .template-subevent .btn-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: stretch;
    gap: 0px;
}

.marketplace .template-subevent .btn {
    border-radius: 0px;
    text-align: center;
    padding: 5px;
    flex-grow: 1;
    background-color: #157efb29;
    color: #2977c9;
    border: none;
    font-family: 'Poppins', sans-serif;
    font-size: 18px;
    font-weight: 300;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-decoration: none;
}

.marketplace .template-subevent .btn.modified-on {
    background-color: #f1f1f1;
    color: #818181;
    text-align: left;
    padding: 5px 10px;
    border: none;
    align-items: flex-start;
}

.marketplace .template-subevent .btn.modified-on label {
    font-size: 10px;
}

.marketplace .template-subevent .btn.modified-on p {
    font-size: 12px;
    color: #002d63;
}

.marketplace .template-subevent .btn:first-child {
    border-radius: 0px 0px 0px 10px;
}

.marketplace .template-subevent .btn:last-child {
    border-radius: 0px 0px 10px 0px;
}

.marketplace .template-subevent .notification {
    color: #818181;
    font-size: 12px;
}

.marketplace .template-subevent .notification p {
    background-color: #fff;
    border: 1px solid #d5415a;
    color: #d5415a;
    font-weight: 400;
    font-size: 21px;
    border-radius: 10px;
    padding: 5px 10px;
    text-align: center;
}

.marketplace .template-subevent .fine-text {
    color: #818181;
    font-size: 12px;
    font-family: 'Poppins', 'Open Sans', sans-serif;
    max-height: 50px;
    overflow: hidden;
}

.marketplace .email-editor {
    width: 100%;
}

.marketplace .back-btn {
    background-color: transparent;
    border: 1px solid #002d63;
    padding: 5px 20px;
    font-size: 14px;
    color: #002d63;
    transition: 0.3s;
}

.marketplace .back-btn:hover {
    background-color: #002d63;
    color: #fff;
    transition: 0.3s;
}

.marketplace .email-editor input {
    width: 100%;
}

.marketplace .email-editor textarea {
    min-height: 200px;
    line-height: 26px;
    resize: none;
    overflow: hidden;
}

.marketplace .logo-wrapper {
    padding: 20px;
    width: 100%;
    background-color: #d1edff;
    display: flex;
    justify-content: center;
    align-items: center;
}

.marketplace .logo-wrapper img {
    height: 40px;
    width: auto;
}

.template-tile img {
    max-width: 100%;
    max-height: 100%;
    height: 100%;
    object-fit: fill;
    object-position: center;
    border-radius: 10px;
}

.marketplace .sub-nav {
    padding: 20px;
    display: flex;
    gap: 5px;
    flex-direction: column;
    position: sticky;
    top: 0;
    left: 0;
    overflow-y: auto;
}

.marketplace .sub-nav::-webkit-scrollbar {
    width: 0px;
    display: none;
}

.marketplace .sub-nav p {
    color: #8e8e8e;
    font-family: 'Poppins', 'Open Sans', sans-serif;
    line-height: 26px;
    word-break: break-word;
}

.marketplace .sub-nav p.note {
    color: #d5415a;
}

.marketplace .sub-nav .batch-id-sec {
    padding: 10px;
    border: 1px dashed #2977c9;
    margin-top: 10px;
    border-radius: 5px;
    width: 100%;
}

.marketplace .sub-nav .batch-id-sec button {
    width: fit-content;
    font-size: 12px;
    font-weight: 400;
    padding: 5px 10px;
    height: auto;
    border-radius: 5px;
    display: block;
    margin: auto;
    margin-right: 0px;
}

.marketplace .sub-nav p.verified {
    background-color: #21937b;
    color: #fff;
    font-weight: 600;
    padding: 3px 10px;
    border-radius: 5px;
    width: fit-content;
    margin-top: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    width: 100%;
}

.marketplace .sub-nav p.not-verified {
    background-color: transparent;
    color: #d5415a;
    border: 1px solid #d5415a;
    font-weight: 600;
    padding: 3px 10px;
    border-radius: 5px;
    width: fit-content;
    margin-top: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    width: 100%;
}

.marketplace .sub-nav p a {
    color: #2977c9;
    font-weight: 600;
    transition: 0.3s;
}

.marketplace h1 {
    color: #000;
    font-size: 15px;
    font-weight: 400;
}

.marketplace h2 {
    color: #000;
    font-size: 40px;
    font-weight: 600;
    vertical-align: middle;
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: flex-start;
}

.marketplace button {
    width: fit-content;
    padding: 10px 30px;
}

.sub-nav .template-tile {
    background-color: #fff;
}

.marketplace input[type='file'] {
    color: #000;
}

.marketplace .upload-signature {
    display: flex;
    gap: 0px;
    flex-direction: column;
}

.marketplace .btn-wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
}

.marketplace .btn-wrapper .back-btn {
    padding: 10px 20px;
    border: none;
}

.marketplace .btn-wrapper .back-btn:hover {
    background-color: #f1f1f1;
    color: #002d63;
}

.marketplace .btn-wrapper .use-btn {
    height: auto;
    min-height: auto;
    padding: 10px 20px;
    font-size: 14px;
    border: none;
    background-color: #002d63;
    transition: 0.3s;
}

.marketplace .btn-wrapper .use-btn:hover {
    background-color: #157efb;
    transition: 0.3s;
}

.marketplace .marketplace-view {
    width: 100%;
    height: 90%;
    object-fit: contain;
    border-radius: 10px;
    background-color: #d1edff;
}

/* ------------cropper--------------- */

.slider {
    width: 200px;
    padding: 5px;
}

.cropper-rel {
    width: 100%;
    height: calc(100% - 52px);
    overflow-y: auto;
    position: relative;
}

.cropper-rel .container img {
    border: 3px dashed #157efb;
}

.cropper-rel button {
    position: absolute;
    bottom: 15px;
    right: 15px;
    z-index: 3;
    background-color: #fff;
    color: #2977c9;
}

.cropper-zoom {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 20px;
    width: 100%;
    position: sticky;
    background-color: #fff;
    bottom: 0;
}

.cropper-zoom .container {
    display: flex;
    flex-direction: column;
    gap: 3px;
    height: fit-content;
}

.cropper-zoom p {
    font-family: 'Poppins', 'Open Sans', sans-serif;
    color: #8e8e8e;
    text-align: center;
}

.cropper-zoom p.toggle-span {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;
    background-color: #00000011;
    border-radius: 20px;
    box-shadow: rgb(204, 219, 232) 3px 3px 6px 0px inset, rgba(255, 255, 255, 0.5) -3px -3px 6px 1px inset;
}

.cropper-zoom p.toggle-span span {
    display: block;
    border-radius: 20px;
    flex-grow: 1;
    padding: 3px;
}

.cropper-zoom p.toggle-span span.active {
    background-color: #003d4d;
    color: #fff;
}

.threshold-btns {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-direction: row;
}

.threshold-btns button {
    padding: 8px 20px;
    height: fit-content;
    min-height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.threshold-btns button:hover {
    background-color: #157efb;
}

.threshold-btns p {
    color: #002d63;
    font-weight: 600;
}

.upload-signature input[type='file'] {
    height: 0;
    width: 0;
    overflow: hidden;
    padding: 0;
    border: none;
}

.upload-signature input[type='file'] + label {
    background: #2977c9;
    border: none;
    border-radius: 10px;
    color: #fff;
    cursor: pointer;
    font-size: 16px;
    display: flex;
    justify-content: space-between;
    gap: 10px;
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;
    position: relative;
    padding: 10px 30px;
    text-align: center;
    letter-spacing: normal;
    width: 100%;
}

.discard-btn {
    width: 100% !important;
    background-color: transparent;
    color: #818181;
    padding: 5px 10px !important;
    border: 1px solid #818181 !important;
    border-radius: 5px;
    height: auto !important;
    margin-top: 20px;
    font-size: 14px;
}

.discard-btn:hover {
    background-color: transparent !important;
    border-color: #d5415a !important;
    color: #d5415a !important;
    font-weight: 400;
}

.cropper-rel .wrap-1 {
    display: flex;
    justify-content: center;
    align-items: stretch;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 30px;
    width: 100%;
    margin: 0px;
}

.cropper-rel .wrap-1 h2 {
    font-size: 18px;
    color: #818181;
    font-weight: 400;
    width: 100%;
    justify-content: center;
}

.cropper-rel .wrap-1 .privacy {
    width: 100%;
}

.cropper-rel .wrap-1 .privacy p {
    font-size: 14px;
    color: #818181;
    font-weight: 400;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell,
        'Open Sans', 'Helvetica Neue', sans-serif;
    line-height: 21px;
}

.cropper-rel .wrap-1 .privacy b {
    color: #000;
    font-size: 14px;
    font-weight: 600;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell,
        'Open Sans', 'Helvetica Neue', sans-serif;
    line-height: 21px;
}

.cropper-rel .wrap-1 .icon {
    width: 100%;
    max-width: 140px;
    border: 2px dashed #157efb;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
}

.cropper-rel .wrap-1 .icon.sample-signature {
    max-width: 280px;
}

.cropper-rel .wrap-1 h3 {
    color: #d5415a;
    font-size: 16px;
    font-weight: 400;
    padding-bottom: 10px;
}

.cropper-rel .wrap-1 .icon p {
    text-align: center;
    padding-top: 10px;
}

.cropper-rel .wrap-1 .icon img {
    width: 100%;
}

/* ------------search--------------- */

.search {
    width: 100%;
    max-width: 400px;
    position: relative;
}

.suggestive-search {
    height: 69px;
    width: 100%;
}

.suggestive-search .search-bg-blur {
    display: none;
    width: 100%;
    height: 100%;
    background-color: #00000069;
    position: fixed;
    z-index: 998;
    top: 0px;
    left: 0px;
}

.suggestive-search.focused .search-bg-blur {
    display: block;
}

.suggestive-search input {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    height: 46px;
}

.suggestive-search button {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 46px;
}

.suggestive-search.focused input,
.suggestive-search.focused button {
    z-index: 999;
}

.suggestive-search .suggestions {
    position: absolute;
    top: 56px;
    left: 0;
    width: 100%;
    height: fit-content;
    display: none;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;
    flex-direction: row;
    flex-wrap: wrap;
    backdrop-filter: blur(5px);
    padding: 10px;
    border: 1px dashed #fff;
    border-radius: 5px;
}

.suggestive-search.focused .suggestions {
    display: flex;
    z-index: 999;
}

.suggestive-search .suggestions .sug-tile {
    background-color: #e6ebed;
    color: #157efb;
    font-size: 12px;
    font-weight: 400;
    border-radius: 5px;
    padding: 5px 15px;
    cursor: pointer;
    transition: 0.3s;
}

.suggestive-search .suggestions .sug-tile:hover {
    background-color: #157efb;
    color: #fff;
    transition: 0.3s;
}

.search input {
    background-color: #fff;
    padding: 12px;
    border: none;
}

.search button {
    position: absolute;
    top: 50%;
    right: 0px;
    transform: translateY(-50%);
    padding: 8.5px 20px;
    width: fit-content;
    background-color: transparent;
    color: #2977c9;
    transition: 0.3s;
    cursor: pointer;
    border-radius: 0px 10px 10px 0px;
}

.search button:hover {
    background-color: #2977c9;
    color: #fff;
    transition: 0.3s;
}

.approval-wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 20px;
    flex-grow: 1;
    max-width: calc(100% - 330px);
    flex-direction: column;
}

.approval-wrapper.full-width {
    max-width: 100%;
}

.approval-wrapper .container {
    background-color: #ffff;
    border-radius: 10px;
    width: 100%;
    padding: 20px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    gap: 20px;
    transition: 0.3s;
    /* border: 1px solid #F3F3F3; */
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 0px 7px -3px;
}

/* .approval-wrapper .container:hover {
  box-shadow: 0px 0px 15px 9px #E6EBED;
  transition: 0.3s;
  transform: scale(1.006);
} */

.approval-wrapper .next-prev-btns {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    width: 100%;
}

.approval-wrapper .next-prev-btns button {
    font-family: 'Poppins', 'Open Sans', sans-serif;
    padding: 8px 20px;
    border-radius: 5px;
    font-size: 16px;
    font-weight: 600;
}

.approval-wrapper .certificate-preview {
    border: 1px solid #157efb;
    border-radius: 5px;
    height: 73px;
    width: auto;
    max-width: 120px;
    color: #000;
}

.approval-wrapper .certificate-preview img {
    height: 100%;
    width: auto;
    border-radius: 4px;
    object-fit: cover;
}

.approval-wrapper .content-wrapper {
    width: calc(100% - 360px);
    min-width: 200px;
}

.approval-wrapper h2 {
    font-size: 18px;
    font-weight: 600;
    color: #002d63;
    word-break: break-word;
}

.approval-wrapper .content-wrapper h2,
.approval-wrapper .content-wrapper h3 {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.approval-wrapper p {
    font-size: 12px;
    font-weight: 600;
    font-family: 'Poppins', 'Open Sans', sans-serif;
    color: #818181;
}

.approval-wrapper h3 {
    font-size: 14px;
    font-weight: 400;
    color: #000;
}

.approval-wrapper h3 .green {
    background-color: #21937b;
    font-weight: 600;
    color: #fff;
    padding: 3px 10px;
    border-radius: 5px;
    display: inline-block;
}

.approval-wrapper h3 .red {
    background-color: #d5415a;
    font-weight: 600;
    color: #fff;
    padding: 3px 10px;
    border-radius: 5px;
    display: inline-block;
}

.approval-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    background-color: #fff;
    height: 73px;
    width: 73px;
    padding: 10px;
    border: 1px dashed #818181;
    border-radius: 10px;
}

.approval-icon i {
    color: #157efb;
    font-size: 3rem;
}

.approval-wrapper .actions {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    flex-direction: column;
}

.approval-wrapper .checkbox {
    position: absolute;
    top: 5px;
    left: 5px;
    width: fit-content;
}

.approval-wrapper .actions .status-btn {
    background-color: #fff;
    color: #fff;
    padding: 20px 15px;
    border-radius: 10px;
    border: 4px dashed #fff;
    font-size: 16px;
    font-weight: 600;
}

.approval-wrapper .actions .status-btn.signed {
    border: 4px dashed #2bcead;
    color: #2bcead;
}

.approval-wrapper .actions .status-btn.rejected {
    border: 4px dashed #df7184;
    color: #d5415a;
}

.approval-wrapper .flex {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    width: 100%;
}

.approval-wrapper .flex .cert-count {
    font-size: 14px;
    font-weight: 600;
    color: #157efb;
    border-radius: 5px;
    background-color: #157efb21;
    padding: 8px 10px;
    transition: 0.3s;
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.approval-wrapper .flex .emails {
    background-color: #eff7ff;
    color: #157efb;
    border: 1px solid #157efb;
    border-radius: 5px;
    padding: 5px 10px;
    transition: 0.3s;
}

.approval-wrapper .flex .emails:hover,
.approval-wrapper .flex .cert-count:hover {
    background-color: #157efb;
    color: #fff;
    transition: 0.3s;
}

.approval-wrapper .flex .reject {
    font-size: 14px;
    font-weight: 400;
    /* background-color: #DF718439; */
    color: #d5415a;
    border-radius: 5px;
    background-color: transparent;
    padding: 8px 10px;
    transition: 0.3s;
}

.approval-wrapper .flex .reject:hover {
    background-color: #d5415a;
    color: #fff;
    transition: 0.3s;
}

.approval-wrapper .flex .sign {
    font-size: 14px;
    font-weight: 400;
    color: #fff;
    border-radius: 5px;
    background-color: #1fd5b0;
    padding: 8px 10px;
    flex-grow: 1;
    flex-direction: row;
    min-width: 80px;
    transition: 0.3s;
}

.approval-wrapper .flex .sign:hover {
    background-color: #2bcead;
    transition: 0.3s;
}

.approval-wrapper .group-action {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.approval-wrapper .group-action button:first-child {
    background-color: #f9f9f9;
    color: #d5415a;
}

.approval-wrapper .approvals-signature,
.sub-nav .approvals-signature {
    width: 100%;
    justify-content: center;
    align-items: center;
    /* border: 1px solid #157EFB; */
    border-radius: 5px;
    flex-direction: column;
    gap: 10px;
    padding: 10px;
}

.approval-wrapper .approvals-signature img,
.sub-nav .approvals-signature img {
    max-height: 200px;
    max-width: 100%;
    min-width: 180px;
    border-radius: 5px;
    mix-blend-mode: multiply;
}

.approval-wrapper .approvals-signature a,
.sub-nav .approvals-signature a {
    text-decoration: none;
    color: #002d63;
    background-color: #157efb21;
    border-radius: 5px;
    padding: 8px 15px;
    min-width: 180px;
    text-align: center;
}

.approval-wrapper .approvals-signature {
    display: none;
    background-color: #f9f9f9;
}

.sub-nav .approvals-signature {
    display: flex;
    background-color: #fff;
    margin-bottom: 10px;
}

#centerX,
#centerY {
    position: absolute;
    top: 50%;
    right: 50%;
    transform: translate(50%, -50%);
    display: none;
    border: none;
}

#centerX {
    height: 100%;
    width: 1px;
    border-left: 2px #d5415a dashed;
    background-color: transparent;
    z-index: 998;
}

#centerY {
    height: 1px;
    width: 100%;
    border-top: 2px #d5415a dashed;
    background-color: transparent;
    z-index: 998;
}

.tags-modal {
    width: 300px;
    height: 500px;
    background-color: #f6f6f6;
    border-radius: 5px;
    z-index: 1000;
    position: absolute;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;
    flex-direction: column;
    padding: 10px;
    cursor: move;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

.tags-modal .search-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    width: 100%;
    flex-direction: row;
}

.tags-modal .search-container input {
    padding: 5px 10px;
    border-radius: 5px;
    border: 1px solid #2977c9;
}

.tags-modal .search-container button {
    padding: 3.5px 10px;
    border-radius: 5px;
    height: auto;
    font-size: 18px;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    color: #d5415a;
    border: 1px solid #d5415a;
    transition: 0.3s;
}

.tags-modal .search-container button:hover {
    background-color: #d5415a;
    color: #fff;
    transition: 0.3s;
}

.tags-modal .type-container {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: row;
    width: 100%;
}

.tags-modal .type-container button {
    padding: 3px 15px;
    font-size: 14px;
    height: auto;
    border-radius: 0px;
    background-color: #fff;
    color: #2bcead;
}

.tags-modal .type-container button.active {
    background-color: #2bcead;
    color: #fff;
}

.tags-modal .content {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;
    width: 100%;
    flex-direction: column;
    overflow-y: auto;
    flex-grow: 1;
}

.tags-modal .content::-webkit-scrollbar {
    display: none;
    width: 0px;
}

.tags-modal .content .content-wrapper {
    padding: 10px;
    background-color: #fff;
    border-radius: 5px;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 10px;
    cursor: default;
    position: relative;
}

.tags-modal .content .content-wrapper .copied-message {
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    border-radius: 5px;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: #157efbcc;
    color: #fff;
    animation: fade-in-out 0.3s linear;
    animation-iteration-count: 1;
}

@keyframes fade-in-out {
    0% {
        opacity: 0.1;
    }

    100% {
        opacity: 1;
    }
}

.tags-modal .content .tag-wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    flex-wrap: wrap;
}

.tags-modal .content .tag-wrapper .tag {
    background-color: #d1edff;
    color: #2977c9;
    padding: 2px 10px;
    border-radius: 12px 5px 5px 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    flex-direction: row;
    pointer-events: none;
}

.tags-modal .content .tag-wrapper .tag .dot {
    height: 5px;
    width: 5px;
    border-radius: 50%;
    background-color: #fff;
}

.tags-modal .content .content-description {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: flex-start;
    gap: 10px;
    flex-direction: row;
    flex-wrap: nowrap;
}

.tags-modal .content .content-description button {
    background-color: transparent;
    padding: 0px;
    height: auto;
    cursor: pointer;
}

.tags-modal .content .content-description button.copy {
    color: #818181;
}

.tags-modal .content .content-description button.copy:hover {
    color: #157efb;
}

.tags-modal .content .content-description button.deletable {
    color: #df7184;
}

.tags-modal .content .content-description button.deletable:hover {
    color: #d5415a;
}

.tags-modal .content .content-description button.favorite {
    color: #818181;
}

.tags-modal .content .content-description button.favorite.active {
    color: #ffcb74;
}

.tags-modal .content .content-description button.add {
    color: #157efb;
    right: 10px;
    bottom: 10px;
}

.tags-modal .content .content-description button.add.active {
    color: #2bcead;
}

.tags-modal .content .content-description p {
    font-size: 12px;
    color: #8e8e8e;
    flex-grow: 1;
    word-break: break-word;
    pointer-events: none;
}

.tags-modal .content .save-wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;
    flex-direction: column;
    width: 100%;
    flex-grow: 1;
}

.tags-modal .content .save-wrapper p {
    color: #8e8e8e;
    font-size: 12px;
}

.tags-modal .content .save-wrapper p b {
    color: #157efb;
    font-size: 12px;
    font-weight: 400;
}

.tags-modal .content .save-wrapper textarea,
.tags-modal .content .save-wrapper input {
    resize: none;
    overflow-y: auto;
    font-size: 14px;
}

.tags-modal .content .save-wrapper textarea:nth-child(5) {
    flex-grow: 1;
}

.tags-modal .content .save-wrapper button {
    padding: 5px 15px;
    border-radius: 5px;
    height: auto;
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin: 0px auto;
    margin-right: 0px;
}

.tags-modal .content .save-wrapper .tag {
    padding-right: 5px;
}

.tags-modal .content .save-wrapper .delete-btn {
    color: #d5415a;
    cursor: pointer;
}

.tags-modal .content .tag-input-wrapper .popup-message {
    font-size: 12px;
    color: #d5415a;
    position: absolute;
    bottom: 5px;
    right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    flex-direction: row;
    background-color: #fff;
    padding: 0px 5px;
}

.tags-modal .content .tag-input-wrapper {
    position: relative;
    width: 100%;
}

.saved-content-btn i {
    font-size: 18px;
}

/* ---------- Event Modal ----------- */

.event-modal {
    flex-grow: 1;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;
    flex-direction: column;
    width: fit-content;
}

.event-modal h2 {
    font-size: 21px;
    color: #157efb;
    font-weight: 400;
    padding-bottom: 20px;
}

.event-modal .input-wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    gap: 10px;
    width: 100%;
    max-width: 600px;
    padding-bottom: 10px;
    position: relative;
}

.event-modal .input-wrapper.sub {
    margin-left: 20px;
}

.event-modal .input-wrapper .new {
    padding: 10px;
    width: fit-content;
    min-width: 100px;
    height: 52px;
    font-size: 18px;
    border: 1px solid transparent;
    color: #d5415a;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.event-modal .input-wrapper .all {
    padding: 10px;
    width: fit-content;
    min-width: 200px;
    height: 52px;
    font-size: 18px;
    border: 1px solid #157efb;
    color: #157efb;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.event-modal button {
    width: fit-content;
}

.event-modal .input-wrapper p {
    color: #157efb;
    font-size: 16px;
}

.event-modal li {
    background-color: #002d63;
    color: #fff;
    padding: 10px;
    list-style-type: none;
    border-radius: 10px;
}

.event-modal li:hover {
    color: #d1edff;
}

.event-modal ul {
    position: absolute;
    top: 62px;
    left: 0;
    width: 100%;
    padding: 0px;
    z-index: 1;
    border-radius: 10px;
    background-color: #002d63;
    max-height: 200px;
    overflow-y: auto;
}

.event-details {
    padding: 3px 10px;
    border: 1px solid #002d63;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    gap: 0px;
    flex-direction: column;
    height: 50px;
    position: relative;
    min-width: 150px;
    max-width: calc(100% - 360px);
}

.event-details p {
    text-align: right;
    font-size: 0.8rem;
    color: #002d63;
    font-weight: 600;
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.event-details p:last-child {
    color: #818181;
    font-size: 0.6rem;
}

.event-details label {
    position: absolute;
    top: -10px;
    left: 10px;
    background-color: #fff;
    color: #002d63 !important;
    font-size: 12px !important;
    padding: 0px 3px !important;
}

.tile-wrapper .tile input {
    border: 1px solid #d1edff;
}

.tile-wrapper .tile.grayed {
    background-color: #f9f9f9;
}

.tile-wrapper .tile input:focus {
    border: 1px solid #157efb;
}

.tile-wrapper .tile .flex {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-direction: row;
}

.tile-wrapper .tile .custom-file-input {
    color: transparent;
    padding: 0px;
    height: 49.31px;
    overflow: hidden;
}

.tile-wrapper .tile .custom-file-input::-webkit-file-upload-button {
    visibility: hidden;
}

.tile-wrapper .tile .custom-file-input::before {
    content: 'Choose PDF';
    color: #fff;
    display: inline-block;
    background: #157efb;
    border: none;
    border-radius: 5px;
    padding: 13px;
    outline: none;
    white-space: nowrap;
    -webkit-user-select: none;
    user-select: none;
    cursor: pointer;
    font-size: 14px;
    height: 49.31px;
    width: 100%;
}

.tile-wrapper .tile .custom-file-input:hover::before {
    border-color: black;
}

.tile-wrapper .tile .custom-file-input:active {
    outline: 0;
}

.tile-wrapper .tile .chosen-doc {
    height: 49.31px;
    padding: 10px;
    border: 1px solid #21937b;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 10px;
    font-size: 12px;
    flex-direction: row;
    border-radius: 10px;
    color: #818181;
    overflow: hidden;
    min-width: 50%;
    max-width: 140px;
}

.tile-wrapper .tile .chosen-doc p {
    max-width: calc(100% - 40px);
    overflow: hidden;
}

.tile-wrapper .tile .chosen-doc button {
    padding: 4px 10px;
    width: fit-content;
    font-size: 14px;
    background-color: #d5415a;
}

.pdf-viewer {
    width: 100%;
    height: 100%;
}

.pdf-viewer embed {
    width: 100%;
    height: 100%;
}

.continue-edit {
    background-color: #157efb;
    color: #fff;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    text-decoration: none;
    padding: 10px 15px;
    font-weight: 600;
    position: absolute;
    bottom: 10px;
    right: 10px;
    opacity: 0.3;
    transition: 0.3s;
}

.template-tile:hover .continue-edit {
    opacity: 1;
    transition: 0.3s;
}

.sub-nav .plan-info {
    font-family: 'Poppins', 'Open Sans', sans-serif;
}

.settings .view-btn {
    color: #157efb;
    font-weight: 600;
    text-decoration: none;
}

.settings .view-btn:hover {
    text-decoration: underline;
}

.settings .join-req-tile {
    padding: 10px;
    border-radius: 5px;
    background-color: #fff;
    width: 100%;
    box-shadow: 0px 0px 9px 4px #e6ebed;
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
}

.settings .join-req-tile .action-btns {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.settings .join-req-tile p {
    max-width: calc(100% - 63px);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.settings .join-req-tile button {
    padding: 0px;
    border-radius: 50%;
    min-width: none;
    width: 25px;
    height: 25px;
    min-height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    color: #157efb;
    font-size: 21px;
}

.settings .join-req-tile button i {
    font-size: inherit;
}

.settings .join-req-tile button.accept {
    color: #2bcead;
}

.settings .join-req-tile button.reject {
    color: #d5415a;
}

.verification-container {
    padding: 10px;
    border: 1px solid #d5415a;
    border-radius: 10px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 20px;
    flex-direction: column;
    gap: 10px;
}

.verification-container input {
    width: 100%;
    min-width: 200px;
    max-width: 300px;
}

.verification-container li {
    color: #818181;
}

.organiser-table {
    width: 100%;
}

.table-scroller {
    overflow: auto;
    width: 100%;
    height: 100%;
}

.organiser-table th {
    background-color: #2977c9;
    color: #fff;
    padding: 10px;
    font-weight: 400;
}

.organiser-table tr {
    background-color: #f6f6f6;
    color: #818181;
}

.organiser-table tr:nth-child(odd) {
    background-color: #fff;
}

.organiser-table td {
    padding: 10px;
    text-align: center;
}

.organiser-table tr td:last-child,
.organiser-table tr th:last-child {
    max-width: 80px;
}

.organiser-table .del-btn {
    background-color: #d5415a;
    color: #fff;
    padding: 0px;
    width: 100%;
    border-radius: 5px;
}

.issue-actions {
    display: flex;
    justify-content: center;
    align-items: stretch;
    gap: 10px;
    flex-wrap: wrap;
}

.issue-preview {
    display: flex;
    justify-content: center;
    align-items: stretch;
    gap: 10px;
    flex-wrap: wrap;
    flex-direction: row;
    width: 100%;
    background-color: #21937b29;
    border-radius: 5px;
    padding: 20px;
}

.issue-preview .batch-data-email-preview {
    min-width: 280px;
}

.issue-preview .agree {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    color: #000;
}

.issue-preview input {
    width: fit-content;
    display: inline-block;
}

.issue-actions .cnt {
    padding: 10px;
    min-width: 200px;
    background-color: #f3f3f3;
    border-radius: 5px;
    max-width: 250px;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: space-between;
}

.issue-actions .cnt p {
    color: #818181 !important;
    padding-bottom: 10px;
}

.issue-actions .cnt button {
    font-family: 'Poppins', 'Open Sans', sans-serif;
    border-radius: 5px;
    font-size: 14px;
    font-weight: 400;
}

.element-modal.event-modal .all,
.element-modal.event-modal .new {
    width: 120px;
    min-width: 120px;
    gap: 10px;
}

.element-modal.event-modal input {
    border-color: #157efb;
}

.element-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #00000069;
    z-index: 998;
    backdrop-filter: blur(5px);
    display: flex;
    justify-content: center;
    align-items: center;
}

.element-modal .side-by-side {
    display: flex;
    justify-content: center;
    align-items: stretch;
    gap: 10px;
    flex-direction: row;
    flex-wrap: nowrap;
    width: 100%;
}

.element-modal .side-by-side button {
    font-size: 14px;
    padding: 10px;
    display: flex;
    gap: 10px;
    justify-content: center;
    align-items: center;
}

.element-modal .blue-button {
    display: block;
    background-color: #002d63;
    color: #fff;
    position: relative;
    bottom: auto;
    right: auto;
}

.element-modal.transparent {
    backdrop-filter: none;
}

.element-modal .cert-action-btns {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    width: 100%;
    flex-wrap: wrap;
}

.element-modal .cert-action-btns button {
    font-size: 14px;
    padding: 10px 20px;
    width: fit-content;
    height: fit-content;
    font-weight: 400;
}

.element-modal .cert-action-btns button.name-change {
    background-color: #ffcb74;
    color: #000;
    flex-grow: 1;
}

.element-modal .cert-action-btns button.skip-btn {
    background-color: #f1f1f1;
    color: #002d63;
}

.element-modal .preview-img {
    width: 120px;
    height: 120px;
    border: 2px solid #2977c9;
    border-radius: 10px;
    position: relative;
    object-fit: cover;
}

.element-modal .content {
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;
    flex-direction: column;
    min-width: 400px;
    position: relative;
    max-height: 90vh;
    max-width: 90vw;
}

.element-modal .content .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    width: 100%;
    flex-direction: row;
    flex-wrap: nowrap;
}

.element-modal .content .container input {
    border: 1px solid #157efb;
}

.element-modal .content .container .del-btn {
    background-color: #d5415a;
    padding: 0px;
    width: fit-content;
    height: 30px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Poppins', 'Open Sans', sans-serif;
    font-size: 12px;
    gap: 5px;
    padding: 0px 10px;
}

.element-modal .email-list-container {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    max-height: 80vh;
    overflow: auto;
}

.element-modal .email-list {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 10px;
    background-color: #f1f1f1;
    padding: 5px;
    border-radius: 5px;
}

.element-modal .organisers-list {
    display: block;
    flex-direction: column;
    max-height: 60vh;
    width: 100%;
    overflow-y: auto;
}

.element-modal .organisers-list p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 300px;
}

.element-modal .organisers-list::-webkit-scrollbar {
    width: 0px;
    display: none;
}

.element-modal .organisers-list div {
    margin-bottom: 10px;
}

.element-modal .email-wrap {
    flex-grow: 1;
}

.element-modal .email-list.email-list-header {
    background-color: transparent;
    padding-bottom: 0px;
}

.element-modal .email-list.email-list-header div {
    color: #002d63;
    font-size: 12px;
    font-weight: 600;
}

.element-modal .email-list .email {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
}

.element-modal .email-list .email-original {
    color: #818181;
    text-decoration: line-through;
}

.element-modal .email-list .invalid .email-original {
    color: #d5415a;
    text-decoration: none;
}

.element-modal .email-list .row {
    color: #818181;
    font-weight: 600;
    font-size: 18px;
    min-width: 31px;
}

.email-list-reject-all {
    width: fit-content;
    height: 30px;
    padding: 10px 20px;
    font-family: 'Poppins', 'Open Sans', sans-serif;
    border-radius: 5px;
    font-size: 14px;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #d5415a;
}

.element-modal .email-list .btn-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-direction: row;
}

.element-modal .email-list .btn-container button {
    width: 30px;
    padding: 0px;
    height: 30px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.element-modal .email-list .btn-container button:last-child {
    background-color: #21937b;
}

.element-modal .email-list .btn-container button:first-child {
    background-color: #d5415a;
}

.transfer-modal .content .container {
    flex-wrap: wrap;
    flex-direction: column;
    align-items: flex-start;
}

.transfer-modal .content .container.transfer-summary p {
    color: #818181;
}

.transfer-modal .content hr {
    background-color: #157efb;
    width: 100%;
    height: 2px;
    border: none;
}

.transfer-modal .content h1,
.recover-modal .content h1 {
    font-family: 'Poppins', 'Open Sans', sans-serif;
    font-weight: 600;
    font-size: 16px;
    color: #2977c9;
}

.transfer-modal .content p {
    color: #d5415a;
    font-family: 'Poppins', 'Open Sans', sans-serif;
    font-weight: 400;
    font-size: 14px;
    max-width: 400px;
}

.transfer-modal .content p.reset {
    color: #494949;
    font-size: 14px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 5px;
    width: 100%;
}

.transfer-modal .content label {
    max-width: 300px;
    font-size: 12px;
    color: #818181;
}

.transfer-modal .content label b {
    font-size: 12px;
}

.transfer-modal .content p.reset button {
    padding: 0px;
    background-color: transparent;
    color: #14304d;
    font-size: 14px;
    width: fit-content;
    font-weight: 600;
    text-decoration: underline;
}

.transfer-modal .radio-wrapper {
    background-color: #f3f3f3;
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 5px;
    flex-direction: column;
}

.transfer-modal .radio-wrapper h2 {
    font-weight: 400;
    color: #21937b;
    font-size: 12px;
    text-align: left;
}

.transfer-modal .radio {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    flex-direction: row;
}

.transfer-modal .radio input {
    width: fit-content;
}

.transfer-modal .radio p {
    color: #818181;
}

.transfer-modal .content .email-validated {
    color: #21937b;
}

.transfer-modal .content .email-validated i {
    color: inherit;
}

.transfer-modal .img-choose {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    gap: 10px;
    width: 100%;
    flex-wrap: wrap;
}

.transfer-modal .img-choose .container {
    width: 200px;
    height: 200px;
    border: 1px solid #157efb;
    overflow: hidden;
    border-radius: 5px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    background-color: #fff;
    transition: 0.3s;
}

.transfer-modal .img-choose .container:hover {
    background-color: #f9f9f9;
    transition: 0.3s;
}

.transfer-modal .img-choose .container button {
    padding: 1px 10px;
    border-radius: 5px;
    background-color: #157efb21;
    color: #157efb;
    border: 1px solid #157efb;
}

.transfer-modal .img-choose .container .name {
    width: 100%;
    text-align: center;
    color: #21937b;
    font-size: 18px;
    font-weight: 600;
    overflow-y: auto;
}

.recover-modal .content p {
    color: #818181;
    font-family: 'Poppins', 'Open Sans', sans-serif;
    font-weight: 400;
    font-size: 14px;
    max-width: 400px;
}

.recover-modal .content p b {
    color: #157efb;
    font-weight: 600;
}

.recover-modal .big-icon {
    color: #002d63;
    font-size: 3rem;
}

.element-modal .content .close-btn {
    background-color: #d5415a;
    color: #fff;
    padding: 0px;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 4px solid #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 10px;
}

.element-modal .content .close-btn i {
    font-size: inherit;
    color: inherit;
}

.element-modal .share-modal-copy-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    background-color: #157efb21;
    border-radius: 5px;
    padding: 15px;
    width: 100%;
}

.share-modal-copy-container p {
    color: #818181;
    font-family: 'Poppins', 'Open Sans', sans-serif;
    font-size: 12px;
    max-width: 150px;
}

.share-modal-copy-container button {
    background-color: #fff;
    font-family: 'Poppins', 'Open Sans', sans-serif;
    font-size: 14px;
    font-weight: 600;
    color: #157efb;
    padding: 6px 10px;
    border-radius: 5px;
    transition: 0.3s;
}

.share-modal-copy-container button:hover {
    transition: 0.3s;
    background-color: #157efb29;
}

.template-tile .content.blur {
    background-color: #00000069;
    justify-content: flex-start;
    align-items: flex-start;
    backdrop-filter: blur(2px);
    transition: 0.6s;
    border-radius: 10px;
    opacity: 1;
}

.template-tile .content.blur h2 {
    font-size: 16px;
    color: #fff;
}

.template-tile .content.blur p {
    color: #d1edff;
    font-family: 'Poppins', 'Open Sans', sans-serif;
    font-size: 12px;
}

.template-tile .edit-btn {
    background-color: #fff;
    color: #157efb;
    border-radius: 5px;
    padding: 3px 15px;
    position: absolute;
    bottom: 10px;
    right: 10px;
    font-family: 'Poppins', 'Open Sans', sans-serif;
    font-size: 16px;
    font-weight: 600;
    z-index: 3;
}

.template-tile .content.blur:hover {
    opacity: 0;
    transition: 0.3s;
}

.verified-doc-badge {
    width: fit-content;
    padding: 10px 20px;
    border-radius: 10px;
    background-color: #21937b;
    color: #fff;
    margin-top: 20px;
}

.loading-screen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #00000069;
    backdrop-filter: blur(10px);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 998;
}

.loading-screen.opaque {
    background-color: #157efb;
    color: #fff;
}

.loading-screen .content {
    background-color: #fff;
    border-radius: 10px;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    flex-direction: column;
}

.loading-screen .content h2 {
    color: #157efb;
    font-size: 18px;
    font-weight: 600;
}

.loading-screen .content p {
    color: #d5415a;
}

.loading-screen .buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    width: 100%;
}

.loading-screen .buttons button:first-child {
    color: #818181;
    background-color: #f1f1f1;
}

.loading-screen .buttons button {
    padding: 10px 20px;
    border-radius: 5px;
    font-weight: 600;
    color: #fff;
    background-color: #157efb;
    font-size: 14px;
    font-family: 'Poppins', 'Open Sans', sans-serif;
}

.loader {
    border: 16px solid #f3f3f3;
    border-radius: 50%;
    border-top: 16px solid #157efb;
    width: 120px;
    height: 120px;
    -webkit-animation: spin 2s linear infinite;
    /* Safari */
    animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.qr-verify-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    width: 100%;
    flex-direction: column;
    height: calc(100% - 58px);
    overflow-y: scroll;
}

.qr-verify-container::-webkit-scrollbar {
    width: 0px;
    display: none;
}

.qr-verify-box {
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;
    flex-direction: column;
    height: fit-content;
    width: 100%;
    max-width: 400px;
}

.qr-verify-box h2 {
    font-size: 21px;
    color: #157efb;
    font-weight: 400;
    padding-bottom: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.qr-verify-box p {
    font-size: 14px;
    color: #818181;
}

.qr-verify-box label {
    font-size: 14px;
    color: #002d63;
    font-weight: 600;
    display: block;
    padding-bottom: 3px;
    padding-top: 10px;
    text-transform: capitalize;
}

.qr-verify-box .wrapper {
    padding: 10px;
    border-radius: 10px;
    border: 1px solid #157efb;
    width: 100%;
}

.qr-verify-box .wrapper a {
    color: #157efb;
    text-decoration: underline;
}

.qr-verify-box .wrapper a i,
.qr-verify-box .wrapper p i {
    color: #21937b;
    padding-right: 5px;
}

.qr-verify-box .wrapper button {
    padding: 5px 20px;
    width: fit-content;
    border-radius: 5px;
    font-size: 14px;
    margin-top: 10px;
}

.qr-verify-box .wrapper.status {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 20px;
    flex-direction: column;
}

.qr-verify-box .expired,
.qr-verify-box .valid {
    display: inline-block;
    padding: 3px 10px;
    border-radius: 5px;
    color: #fff;
    background-color: #002d63;
    margin-left: 10px;
    font-size: 12px;
    font-weight: 600;
}

.qr-verify-box .expired {
    background-color: #d5415a;
}

.qr-verify-box .valid {
    background-color: #21937b;
}

.qr-verify-box .verified {
    display: flex;
    gap: 10px;
    align-items: center;
}

.qr-verify-box .verified p {
    font-size: 18px;
    color: #21937b;
    font-weight: 600;
}

.qr-verify-box .verified i {
    font-size: 30px;
    color: #21937b;
}

.qr-verify-box .verified i.fa-spinner {
    -webkit-animation: spin 2s linear infinite;
    /* Safari */
    animation: spin 2s linear infinite;
}

.verification-status-container {
    background-color: #df718439;
    padding: 20px;
    width: 100%;
    border-radius: 10px;
}

.verification-status-container h2 {
    font-size: 18px;
    color: #002d63;
    font-weight: 600;
    padding-bottom: 5px;
    margin-bottom: 10px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;
    flex-direction: row;
    flex-wrap: wrap;
}

.verification-status-container h2 span {
    color: #fff;
    background-color: #21937b;
    padding: 5px 20px;
    border-radius: 5px;
}

.verification-status-container p {
    color: #818181;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell,
        'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 14px;
    line-height: 21px;
}

.verification-status-container p b {
    font-weight: 600;
    color: #002d63;
    font-size: 14px;
}

.verification-status-container p.message {
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #002d63;
    line-height: 14px;
    width: fit-content;
    margin-top: 10px;
}

.onboarding-tutorial {
    position: fixed;
    bottom: 10px;
    right: 10px;
    border-radius: 5px;
    background-color: #21937b;
    color: #fff;
    z-index: 997;
    width: fit-content;
    height: fit-content;
    max-width: 300px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
}

.onboarding-tutorial.violet {
    background-color: #7115fb;
}

.onboarding-tutorial .pos-rel {
    padding: 20px;
    position: relative;
}

.onboarding-tutorial-btn {
    position: fixed;
    bottom: 20px;
    right: 20px;
    border-radius: 14px;
    background-color: #21937b;
    color: #fff;
    z-index: 99;
    width: 50px;
    height: 50px;
    padding: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 21px;
}

.onboarding-tutorial-btn i {
    font-size: inherit;
}

.onboarding-tutorial .buttons {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    gap: 10px;
    padding-top: 10px;
}

.onboarding-tutorial .close-btn {
    top: 3px;
    right: 3px;
    background-color: transparent;
    color: #ccc;
}

.onboarding-tutorial .close-btn i {
    color: inherit;
    font-size: 16px;
}

.onboarding-tutorial .steps {
    font-size: 12px;
    color: #d1edff;
    font-weight: 600;
}

.onboarding-tutorial h2 {
    font-size: 16px;
    font-weight: 600;
}

.onboarding-tutorial p {
    font-size: 14px;
    font-weight: 300;
}

.onboarding-tutorial button {
    padding: 8px 20px;
    height: auto;
    border-radius: 5px;
    background-color: #002d63;
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: fit-content;
}

.onboarding-tutorial .caret {
    position: absolute;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid #21937b;
    transform: translate(50%, -100%);
}

.onboarding-tutorial.violet .caret {
    border-bottom: 10px solid #7115fb;
}

.onboarding-tutorial .do-not-show {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 5px;
    flex-direction: row;
    flex-wrap: nowrap;
    border-top: 1px solid #fff;
    padding-top: 10px;
    margin-top: 10px;
}

.onboarding-tutorial .do-not-show input {
    width: 14px;
}

.intro-user-data {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: #00000069;
    backdrop-filter: blur(3px);
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
}

.intro-user-data .wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    flex-wrap: wrap;
    padding: 20px;
    max-width: 500px;
    width: 95%;
}

.intro-user-data .wrapper .container {
    padding: 20px;
    width: 120px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 10px;
    border: 1px solid #fff;
    box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px;
    transition: 0.3s;
}

.intro-user-data .wrapper .container.active {
    border: 1px solid #157efb;
}

.intro-user-data .wrapper .container:hover {
    transition: 0.3s;
    box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
}

.intro-user-data .wrapper .container i {
    font-size: 3rem;
    color: #2b76b9;
}

.intro-user-data .wrapper h2 {
    width: 100%;
    font-weight: 400;
    color: #157efb;
    font-size: 18px;
}

.intro-user-data .wrapper p {
    width: 100%;
    font-weight: 400;
    color: #818181;
    font-size: 14px;
    font-family: 'Poppins', 'Open Sans', sans-serif;
}

.intro-user-data .skip {
    width: 100%;
    font-size: 14px;
    padding: 5px 20px;
    border-radius: 5px;
    background-color: #f6f6f6;
    color: #8e8e8e;
}

.intro-user-data .more-user-info {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    flex-direction: column;
}

.intro-user-data .more-user-info hr {
    border: none;
    background-color: transparent;
    width: 50%;
    height: 1px;
}

.intro-user-data .more-user-info input,
.intro-user-data .more-user-info select {
    width: 100%;
    max-width: 300px;
    border: 1px solid #157efb;
    font-size: 14px;
    margin-bottom: 10px;
}

.intro-user-data .more-user-info select {
    padding: 12px 13px;
}

.intro-user-data .more-user-info p {
    font-size: 12px;
    font-weight: 600;
    color: #002d63;
    text-align: center;
}

.intro-user-data .more-user-info .error {
    color: #d5415a;
    font-weight: 300;
}

.intro-user-data .more-user-info button {
    font-size: 14px;
    border-radius: 5px;
    transition: 0.3s;
}

.intro-user-data .more-user-info button:hover {
    background-color: #157efb;
    transition: 0.3s;
}

.tutorial-video-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #00000069;
    z-index: 998;
}

.tutorial-video-modal video,
.tutorial-video-modal iframe {
    max-width: 90%;
    max-height: 90%;
    border-radius: 10px;
}

.tutorial-video-modal .skip-btn {
    position: absolute;
    bottom: 10px;
    right: 10px;
    z-index: 999;
    border-radius: 10px;
    padding: 5px;
    display: none;
    background-color: #00000099;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
}

.tutorial-video-modal .play-button {
    width: 100px;
    height: 60px;
    background-color: #157efb;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 3rem;
    position: absolute;
    top: 50%;
    left: 50%;
    border-radius: 10px;
}

.tutorial-video-modal .skip-btn p {
    min-width: 60px;
    max-width: 120px;
    color: #fff;
}

.tutorial-video-modal .skip-btn input {
    width: fit-content;
    min-width: none;
    margin-left: 10px;
}

.tutorial-video-modal .skip-btn button {
    width: fit-content;
    background-color: #fff;
    border: 2px solid #157efb;
    color: #157efb;
    font-weight: 600;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-direction: row;
}

.feedback-modal {
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 999;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #00000021;
}

.feedback-modal .container {
    background-color: #fff;
    height: fit-content;
    padding: 20px;
    border-radius: 10px;
    gap: 5px;
    width: 95vw;
    max-width: 400px;
}

.feedback-modal .container h2 {
    font-size: 18px;
    font-weight: 400;
    color: #157efb;
}

.feedback-modal .container p {
    font-size: 14px;
    font-weight: 400;
    color: #818181;
}

.feedback-modal .message-anonymous {
    font-size: 14px !important;
    background-color: #818181;
    color: #fff !important;
    padding: 5px 10px;
    border-radius: 5px;
    width: fit-content;
}

.feedback-modal .container p span {
    color: #157efb;
    font-weight: 600;
}

.feedback-modal .button-sec {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
    margin-top: 20px;
}

.feedback-modal .button-sec button {
    font-family: 'Poppins', 'Open Sans', sans-serif;
    font-size: 14px;
    font-weight: 600;
    border-radius: 5px;
    width: fit-content;
    padding: 10px 20px;
}

.feedback-modal .button-sec button.skip {
    background-color: transparent;
    color: #818181;
    font-weight: 400;
}

.feedback-modal textarea {
    border: 2px solid #f1f1f1;
    font-size: 14px;
    height: 120px;
    resize: none;
}

.feedback-modal .warning {
    color: #d5415a !important;
}

.star-rating {
    font-size: 30px;
}

.star-rating .star {
    font-size: inherit;
    cursor: pointer;
}

.star-rating .star:hover {
    color: gold;
}

.star-rating .filled {
    color: gold;
}

.star-rating .active {
    color: gold !important;
}

.star-rating .empty {
    color: lightgray;
}

.feedback-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 10px;
    flex-wrap: nowrap;
    width: 100%;
    background-color: #f6f6f6;
    padding: 10px;
    border-radius: 10px;
    transition: 0.3s;
    min-width: 250px;
    max-width: 460px;
}

.feedback-wrapper:hover {
    transition: 0.3s;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

.feedback-wrapper .container:last-child {
    flex-grow: 1;
}

.feedback-wrapper .container {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;
    width: fit-content;
    word-break: break-word;
}

.feedback-wrapper .date {
    background-color: #8e8e8e;
    color: #fff;
    border-radius: 5px;
    padding: 3px 10px;
    display: block;
    margin: auto;
    margin-right: 0px;
    margin-top: 0px;
}

.feedback-wrapper .name {
    color: #157efb;
    font-size: 16px;
    font-weight: 600;
}

.feedback-wrapper .email {
    color: #2977c9;
    font-size: 14px;
    font-weight: 400;
}

.feedback-wrapper hr {
    width: 2px;
    border: none;
    background-color: #e0e0e0;
    height: 100%;
}

.feedback-wrapper .content {
    color: #818181;
    font-size: 14px;
    font-weight: 400;
}

.feedback-wrapper p {
    flex-basis: 100%;
    width: 100%;
}

.star-rating-view {
    color: #ffcb74;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 3px;
    width: fit-content;
    height: 25px;
}

.rating-bar-graph {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 5px;
    flex-direction: column;
    width: 100%;
}

.rating-bar-graph .wrap {
    width: 100%;
    color: #818181;
}

.rating-bar-graph .wrap label {
    font-size: 12px;
}

.rating-bar-graph .bar {
    width: 100%;
    height: 25px;
    background-color: #fff;
    border-radius: 15px;
    overflow: hidden;
}

.rating-bar-graph .bar .filled {
    background-color: #ffcb74;
    height: 100%;
    border-radius: 10px;
}

.tiles.buysection {
    flex-wrap: wrap !important;
    justify-content: center !important;
}

.buysection .buy-container {
    height: fit-content;
    min-width: 250px;
    width: 100%;
    max-width: 330px;
    border-radius: 10px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
    border: 1px solid #f1f1f1;
    transition: 0.3s;
    min-height: 398px;
}

.buysection .buy-container:hover,
.buysection .buy-container.active {
    box-shadow: none;
    transition: 0.3s;
}

.buysection .buy-container:hover .inset,
.buysection .buy-container.active .inset {
    scale: 1.03;
    transition: 0.3s;
}

.buysection .switch-organisation-buy {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    background-color: #157efb21;
    padding: 20px;
    border-radius: 10px;
    flex-basis: 100%;
    width: 100%;
}

.buysection .switch-organisation-buy a {
    background-color: #157efb;
    color: #fff;
    padding: 10px 20px;
    border-radius: 10px;
    display: block;
    text-decoration: none;
}

.buy-container .inset {
    padding: 20px;
    transition: 0.3s;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;
    flex-direction: column;
    height: 100%;
}

.buy-container button {
    width: 100%;
    font-family: 'Poppins', 'Open Sans', sans-serif;
    font-size: 16px;
    font-weight: 600;
    border-radius: 5px;
}

.buy-container h3 {
    color: #157efb;
    font-family: inherit;
    font-size: 18px;
    font-weight: 600;
}

.buy-container .features {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 5px;
    flex-direction: column;
}

.buy-container hr {
    width: 100%;
    height: 2px;
    border: none;
    background-color: #00000069;
}

.buy-container .features p {
    font-family: 'Poppins', 'Open Sans', sans-serif;
    font-size: 14px;
    color: #8e8e8e;
}

.buy-container .current-plan {
    padding: 20px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #21937b;
    background-color: #2bcead39;
    border-radius: 5px;
    font-size: 16px;
    font-weight: 400;
}

.no-view-copy {
    width: 0px;
    height: 0px;
    overflow: hidden;
    opacity: 0;
}

.copy-container p,
.copy-container h2 {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;
}

.copy-container p b {
    font-weight: 400;
}

.copy-container h2 b {
    font-weight: inherit;
    font-size: inherit;
}

.copy-container p span,
.copy-container h2 span {
    background-color: #c2e9fb;
    color: #157efb;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.copy-container h2 span {
    background-color: #e3e3e3;
    color: #818181;
}

.cert-search {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    flex-direction: row;
    flex-wrap: wrap;
    height: 42px;
    align-self: center;
    background-color: #fff;
    border-radius: 10px;
}

.cert-search input,
.cert-search select {
    width: fit-content;
    max-width: 400px;
    height: 40px;
    padding: 5px 10px;
    font-size: 14px;
}

.cert-search select {
    background-color: #157efb21;
}

.cert-search input {
    min-width: 280px;
}

.cert-search .search-btn {
    display: none;
}

.cert-search span {
    color: #157efb;
    font-size: 14px;
    font-weight: 600;
}

.activity-logs {
    width: 100%;
    color: #818181;
    white-space: pre-line;
    height: 100%;
}

.activity-logs table {
    width: 100%;
}

.activity-logs table th {
    text-align: left;
    color: #157efb;
    font-size: 14px;
    font-weight: 600;
}

.activity-logs table tr td {
    padding: 10px;
}

.activity-logs table tr:nth-child(2n) {
    background-color: #f3f3f3;
}

.activity-logs table tr td:first-child p {
    font-family: 'Poppins', 'Open Sans', sans-serif;
    width: fit-content;
    color: #818181;
    font-weight: 400;
    font-size: 14px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 5px;
}

.activity-logs table tr td:first-child .user-ico {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    text-transform: uppercase;
}

.activity-logs table tr td p {
    font-family: 'Poppins', 'Open Sans', sans-serif;
    width: fit-content;
    color: #818181;
    font-weight: 600;
    font-size: 14px;
}

.activity-logs table tr td:last-child p {
    font-family: 'Poppins', 'Open Sans', sans-serif;
    width: fit-content;
    color: #818181;
    font-weight: 600;
    font-size: 14px;
}

.activity-logs .selector {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    padding: 20px 0px;
    position: sticky;
    top: 0px;
    left: 0px;
    background-color: #fff;
}

.activity-logs .selector button {
    font-family: 'Poppins', 'Open Sans', sans-serif;
    width: fit-content;
    color: #fff;
    font-weight: 600;
    font-size: 14px;
    padding: 10px 20px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
}

.activity-logs .selector button p {
    color: inherit;
    font-size: inherit;
    font-weight: inherit;
}

.activity-logs .selector select {
    width: fit-content;
    font-family: 'Poppins', 'Open Sans', sans-serif;
    color: #157efb;
    font-weight: 600;
    font-size: 14px;
    border: 0px;
    padding: 3px 10px;
    background-color: #f6f6f6;
    border-radius: 5px;
}

.activity-logs .selector select:focus {
    border: 0px;
}

.activity-logs .selector p {
    width: fit-content;
    font-family: 'Poppins', 'Open Sans', sans-serif;
    width: fit-content;
    color: #818181;
    font-weight: 600;
    font-size: 14px;
}

.overflow-table-scroll {
    width: 100%;
    height: calc(100% - 75px);
    overflow: scroll;
}

.no-padding-top {
    padding-top: 0px !important;
}

.document-header {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-wrap: nowrap;
    flex-direction: row;
    position: relative;
}

.document-header .sort-filter {
    background-color: #fff;
    padding: 10px 20px;
    border-radius: 5px;
    height: 100%;
    min-width: 56px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    position: relative;
}

.document-header .dropdown-filter {
    position: absolute;
    top: 47px;
    right: 0;
    padding: 5px;
    background-color: #fff;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
    width: 140px;
    z-index: 99;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 0px;
    border-radius: 5px;
    flex-direction: column;
}

.document-header .dropdown-filter p {
    font-size: 12px;
    font-weight: 600;
    color: #818181;
    padding: 5px 10px;
    width: 100%;
    transition: 0.3s;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.document-header .dropdown-filter p:hover {
    background-color: #00000011;
    transition: 0.3s;
}

.popup-storagewarn {
    width: 100%;
    padding: 10px 15px;
    border-radius: 5px;
    background-color: #cfe2de;
    color: #000;
    height: fit-content;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
}

.popup-storagewarn p {
    color: #818181;
    font-size: 12px;
    font-weight: 600;
}

.popup-storagewarn p span {
    color: #21937b;
    font-size: 16px;
}

.popup-storagewarn a {
    text-decoration: none;
    background-color: #fff;
    color: #21937b;
    padding: 5px 10px;
    font-size: 14px;
    font-weight: 600;
    border-radius: 5px;
    border: none;
    display: block;
}

.popup-storagewarn.above90 {
    background-color: #d5415a;
    color: #fff;
}

.popup-storagewarn.above70 {
    background-color: #ff980069;
}

.popup-storagewarn .storage-progress {
    min-width: 200px;
    height: 10px;
    border-radius: 5px;
    background-color: #f1f1f1;
    overflow: hidden;
}

.popup-storagewarn .storage-progress .bar {
    height: 100%;
    background-color: #21937b;
}

.gdrive-video-1 {
    max-width: 100%;
    min-width: 80%;
    aspect-ratio: 3 / 2;
    height: auto;
    border: none;
    border-radius: 10px;
    display: block;
    margin: auto;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.tutorial-opacity {
    opacity: 0.4;
    transition: 0.3s;
}

.tutorial-opacity:hover {
    opacity: 1;
    transition: 0.3s;
}

.warn-red-color {
    color: #d5415a !important;
    font-size: 12px;
}

.empty-message {
    padding: 10px;
    border-radius: 5px;
    width: fit-content;
    background-color: transparent;
    color: #002d63;
    width: 100%;
    height: 100%;
    display: flex;
    font-weight: 600;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 20px;
}

.empty-message i {
    font-size: 3rem;
    color: #2977c9;
}

.social-share-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #00000069;
    backdrop-filter: blur(5px);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
    flex-direction: column;
    gap: 10px;
}

.social-share-modal .container,
.social-share-modal .wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
    flex-direction: column;
    background-color: #fff;
    padding: 20px;
    border-radius: 5px;
    min-width: 280px;
    max-width: 400px;
    width: 90%;
}

.social-share-modal .wrapper {
    padding: 0px;
    align-items: flex-end;
    background-color: transparent;
}

.social-share-modal .container button {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
    gap: 10px;
    align-items: center;
    flex-direction: row;
    font-family: 'Poppins', 'Open Sans', sans-serif;
    font-size: 16px;
    font-weight: 600;
    border-radius: 5px;
}

.social-share-modal .container h1 {
    text-align: left;
    font-family: 'Poppins', 'Open Sans', sans-serif;
    font-size: 16px;
    font-weight: 600;
    color: #002d63;
    width: 100%;
}

.social-share-modal .close-btn {
    position: relative;
    top: auto;
    right: auto;
    transform: translate(0%, 0%);
    width: fit-content;
    border-radius: 5px;
    padding: 5px;
    color: #d5415a;
    font-family: 'Poppins', 'Open Sans', sans-serif;
    font-size: 14px;
    font-weight: 400;
    gap: 10px;
    border: 1px solid #fff;
    height: 35px;
}

.social-share-modal .private-share-warning {
    padding: 15px;
    border-radius: 10px;
    border: 2px dashed #d5415a;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
    flex-direction: row;
    width: 100%;
    color: #818181;
    flex-wrap: wrap;
}

.social-share-modal .private-share-warning i {
    font-size: 2rem;
    color: #d5415a;
    min-width: 10%;
}

.social-share-modal .private-share-warning p {
    font-size: 12px;
    font-weight: 400;
    max-width: 80%;
}

.social-share-modal .private-share-warning p b {
    color: #d5415a;
    font-size: 12px;
    font-weight: 400;
}

.social-share-modal .private-share-warning button {
    width: fit-content;
    background-color: #21937b !important;
    font-size: 14px;
    padding: 10px 20px;
    border-radius: 20px;
    flex-direction: row;
    flex-wrap: nowrap;
    margin: auto;
    margin-right: 0px;
}

.social-share-modal .private-share-warning button:hover {
    padding: 10px 20px !important;
}

.social-share-modal .private-share-warning button i {
    color: #fff;
    font-size: 14px !important;
    display: none;
}

.social-share-modal .private-share-warning button:hover i {
    display: block;
}

.social-share-modal .close-btn:hover {
    border: 1px solid #d5415a;
    background-color: #fff;
}

.social-share-modal .container button:hover {
    padding-right: 40px;
}

.social-share-modal .container button:nth-child(3) {
    background-color: #16b6e8;
}

.social-share-modal .container button:nth-child(4) {
    background-color: #1fd5b0;
}

.social-share-modal .container button:nth-child(5) {
    background-color: #fff;
    color: #818181;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

.social-share-modal .container button i {
    font-size: 21px;
}

.create-org-module {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 5px;
    flex-direction: column;
    padding: 20px;
    border-radius: 10px;
    background-color: #f6f6f6;
    max-width: 380px;
}

.create-org-module h2 {
    color: #157efb;
    font-weight: 600;
    font-size: 16px;
}

.create-org-module p {
    color: #494949;
    font-size: 12px;
}

.create-org-module button {
    font-size: 14px;
    align-self: flex-end;
    margin-top: 10px;
    cursor: pointer;
}

.whats-this {
    color: #818181;
    font-size: 12px;
}

.create-org-module button.non-cta {
    font-size: 14px;
    align-self: flex-start;
    background-color: transparent;
    color: #157efb;
    border: 1px solid #818181;
    margin-top: 0px;
    cursor: pointer;
}

.create-org-module a.cta {
    color: #157efb;
    border: 1px solid #157efb;
    text-decoration: none;
    padding: 5px 20px;
    border-radius: 10px;
}

.functional-tutorial {
    width: 100vw;
    max-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #002d63;
}

.functional-tutorial .tutorial-wrap {
    position: relative;
    width: fit-content;
    height: fit-content;
    max-height: 100vh;
    max-width: 100vw;
    overflow: hidden;
}

.functional-tutorial video {
    max-width: 100vw;
    max-height: 100vh;
}

.functional-tutorial .start-button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding: 10px 20px;
    border-radius: 10px;
    background-color: #157efb;
    color: #fff;
    border: none;
    cursor: pointer;
}

.functional-tutorial .skip-functional-tutorial {
    position: fixed;
    top: 10px;
    right: -1px;
    background-color: #002d63;
    border: 1px solid #fff;
    color: #fff;
    border-radius: 10px 0px 0px 10px;
    overflow: hidden;
    animation: prompt 3s infinite;
}

.functional-tutorial .skip-functional-tutorial button {
    padding: 10px 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    min-width: 150px;
    border-radius: 0px;
    font-size: 16px;
    font-weight: 500;
}

.functional-tutorial .previous-functional-tutorial {
    position: fixed;
    top: 10px;
    left: 10px;
    background-color: #ffcb74;
    border: none;
    color: #000;
    width: fit-content;
    padding: 10px 20px;
    height: auto;
    font-size: 16px;
    font-weight: 400;
}

@keyframes prompt {
    0% {
        transform: translateX(0px);
    }

    10% {
        transform: translateX(10px);
    }

    20% {
        transform: translateX(0px);
    }
}

.esign-modal {
    position: fixed;
    top: 0;
    left: 0;
    background-color: #00000069;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 997;
    width: 100%;
    height: 100%;
}

.esign-modal.scanned-view {
    background-color: transparent;
    position: relative;
    top: auto;
    left: auto;
    align-items: flex-start;
}

.esign-modal.scanned-view .container {
    padding: 0px;
    background-color: transparent;
    height: 100%;
    width: 100%;
    margin-top: 20px;
}

.esign-modal.scanned-view .container .preview {
    background-color: transparent;
    height: 100%;
    padding: 0px;
}

.esign-modal.scanned-view .container .preview .wrapper {
    width: 90%;
    max-width: 320px;
}

.esign-modal.scanned-view .back-btn {
    padding: 5px 20px;
    background-color: #8e8e8e;
    color: #fff;
    font-size: 14px;
    width: fit-content;
    border-radius: 10px;
    font-weight: 600;
    margin-bottom: 10px;
}

.esign-modal .container {
    background-color: #f1f1f1;
    padding: 20px;
    border-radius: 10px;
    display: flex;
    justify-content: space-around;
    align-items: stretch;
    gap: 20px;
    flex-direction: row;
    flex-wrap: wrap;
    width: 90%;
    max-width: 800px;
    max-height: 80vh;
    overflow-y: auto;
    position: relative;
}

.esign-modal .container h3 {
    font-size: 21px;
    color: #157efb;
}

.esign-modal .container h2 {
    font-size: 18px;
    color: #494949;
    text-align: center;
}

.esign-modal .container h2 span {
    font-size: 12px;
    color: #818181;
    font-weight: 400;
    display: block;
}

.esign-modal .container .form {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 15px;
    flex-direction: column;
    max-width: 340px;
    width: 100%;
}

.esign-modal .container .input {
    width: 100%;
}

.esign-modal .container .form label {
    color: #494949;
    font-size: 12px;
    font-weight: 600;
    display: block;
    padding-bottom: 5px;
}

.esign-modal .container .advanced-btn {
    font-size: 14px;
    padding: 10px;
    background-color: #f1f1f1;
    color: #157efb;
    font-weight: 400;
    position: sticky;
    border-radius: 0px;
    bottom: -20px;
}

.esign-modal .container .preview {
    background-color: #157efb21;
    border-radius: 10px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
    flex-direction: column;
    padding: 10px;
    flex-grow: 1;
    position: sticky;
    top: 0px;
}

.esign-modal .container .preview .wrapper {
    background-color: #fff;
    border-radius: 10px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    padding: 20px;
    flex-direction: column;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
    width: 100%;
    max-width: 280px;
}

.esign-modal .container .preview .user-icons {
    background-color: #8e8e8e;
    color: #fff;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.esign-modal .container .preview .user-icons img {
    width: 90%;
    height: 90%;
    object-fit: cover;
    border-radius: 50%;
}

.esign-modal .container .preview .user-icons .fa-user {
    font-size: 26px;
}

.esign-modal .container .preview .user-icons .fa-circle-check {
    font-size: 16px;
    color: #21937b;
    position: absolute;
    bottom: -3px;
    right: -3px;
    background-color: #fff;
    border-radius: 50%;
    padding: 3px;
}

.esign-modal .container .preview .basic {
    display: block;
    width: 100%;
}

.esign-modal .container .preview .basic h2 {
    font-size: 18px;
    text-align: left;
}

.esign-modal .container .preview label {
    display: block;
    font-size: 14px;
    color: #494949;
    word-break: break-word;
}

.esign-modal .container .preview .basic p {
    padding: 10px 0px;
    color: #002d63;
    font-weight: 600;
}

.esign-modal .container .preview .basic p span {
    display: block;
    font-weight: 400;
    word-break: break-word;
}

.esign-modal .container .preview .basic .use {
    font-size: 12px;
    color: #818181;
    text-align: center;
}

.esign-modal .container .preview .basic .use span {
    display: block;
    padding: 3px 15px;
    font-size: 14px;
    margin-top: 3px;
    background-color: #21937b;
    color: #fff;
    width: 100%;
    text-align: center;
    border-radius: 30px;
    position: sticky;
    top: 0;
    z-index: 1;
}

.esign-modal .container .preview .basic .use span.red {
    background-color: #d5415a;
}

.esign-modal .container .preview .basic .use b {
    background-color: #d5415a39;
    color: #d5415a;
    display: block;
    width: 100%;
    font-weight: 600;
    font-size: 12px;
    padding: 10px;
    padding-top: 12px;
    transform: translateY(-10px);
    border-radius: 0px 0px 10px 10px;
    padding-bottom: 2px;
    z-index: 0;
}

.esign-modal .input-wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    flex-direction: row;
    flex-wrap: wrap;
    margin-bottom: 10px;
}

.esign-modal textarea {
    resize: none;
}

.esign-modal .container .preview .advanced {
    border-top: 2px solid #ccc;
    padding-top: 10px;
    word-break: break-word;
}

.esign-modal .container .preview .advanced .use {
    text-align: left;
}

.esign-modal .container .preview .submit {
    position: sticky;
    bottom: 0px;
    z-index: 9;
}

.new-esign-btn {
    width: 100% !important;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 5px;
    font-size: 16px;
    background-color: #002d63;
    cursor: pointer;
}

.new-esign-btn:hover {
    background-color: #000;
}

.new-esign-btn i {
    font-size: 2rem;
    color: #157efb;
}

.eSignatures {
    flex-grow: 1;
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    gap: 20px;
    flex-direction: row;
    flex-wrap: wrap;
}

.eSignatures .wrapper {
    padding: 10px;
    border-radius: 10px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    width: 100%;
    max-width: 300px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 15px;
    position: relative;
    overflow: hidden;
}

.eSignatures .wrapper .bg-download {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    background-color: #000000c9;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    color: #fff;
    font-weight: 600;
    font-size: 18px;
    opacity: 0;
    transition: 0.3s;
}

.eSignatures .wrapper .bg-download i {
    font-size: 21px;
}

.eSignatures .wrapper:hover .bg-download {
    opacity: 1;
    transition: 0.3s;
}

.eSignatures .wrapper .content {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    flex-direction: column;
    gap: 5px;
    flex-grow: 1;
    width: 100%;
    overflow: hidden;
}

.eSignatures .wrapper h2 {
    font-size: 16px;
    color: #14304d;
}

.eSignatures .wrapper p {
    color: #494949;
    font-size: 12px;
}

.eSignatures .wrapper p b {
    color: #157efb;
    font-size: 12px;
    font-weight: 600;
}

.eSignatures .wrapper .fa-lock {
    color: #2bcead;
}

.eSignatures .wrapper .fa-lock-open {
    color: #d5415a;
}

.eSignatures .wrapper .qr {
    height: 100px;
    width: 100px;
}

.eSignatures .wrapper button {
    font-size: 12px;
    font-weight: 600;
    padding: 5px 10px;
    border-radius: 5px;
    align-self: flex-end;
    background-color: #d5415a39;
    color: #d5415a;
    z-index: 2;
}

.eSignatures .wrapper:hover button {
    background-color: #d5415a;
    color: #fff;
}

.esign-modal .qr-container {
    display: flex;
    justify-content: center;
    align-items: stretch;
    gap: 20px;
    padding: 20px;
    border-radius: 10px;
    background-color: #f1f1f1;
    flex-direction: row;
    flex-wrap: wrap;
    max-width: 600px;
    width: 90%;
}

.esign-modal .qr-container .sub {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 10px;
    flex-direction: column;
}

.esign-modal .qr-container .download {
    background-color: #fff;
    border-radius: 10px;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 10px;
    max-width: 310px;
}

.esign-modal .qr-container .render {
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
    gap: 10px;
    width: 100%;
}

.esign-modal .qr-container .render.vertical {
    flex-direction: column;
}

.esign-modal .qr-container .render.horizontal {
    flex-direction: row;
}

.esign-modal .qr-container .render.vertical.invert {
    flex-direction: column-reverse;
}

.esign-modal .qr-container .render.horizontal.invert {
    flex-direction: row-reverse;
}

.esign-modal .qr-container .rectangle {
    position: relative;
    width: fit-content;
    height: 100px;
    background-color: #f0f0f0;
}

.esign-modal .qr-container .rectangle::before,
.esign-modal .qr-container .rectangle::after {
    content: '';
    position: absolute;
    width: 20px;
    height: 20px;
    border: 2px solid #157efb;
}

.esign-modal .qr-container .rectangle::before {
    top: 0;
    left: 0;
    border-right: none;
    border-bottom: none;
}

.esign-modal .qr-container .rectangle::after {
    bottom: 0;
    right: 0;
    border-left: none;
    border-top: none;
}

.esign-modal .qr-container .rectangle span::before,
.esign-modal .qr-container .rectangle span::after {
    content: '';
    position: absolute;
    width: 20px;
    height: 20px;
    border: 2px solid #157efb;
}

.esign-modal .qr-container .rectangle span::before {
    top: 0;
    right: 0;
    border-left: none;
    border-bottom: none;
}

.esign-modal .qr-container .rectangle span::after {
    bottom: 0;
    left: 0;
    border-right: none;
    border-top: none;
}

.esign-modal .qr-container .render .signature {
    height: 100px;
}

.esign-modal .qr-container .badge {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0px;
    font-size: 12px;
    flex-direction: column;
    padding: 0px;
    border-radius: 10px;
    background-color: transparent;
    color: #157efb;
    line-height: 12px;
}

.esign-modal .qr-container .input-wrapper {
    justify-content: flex-start;
    width: 100%;
}

.esign-modal .qr-container .badge label {
    font-size: 10px;
    color: #494949;
}

.esign-modal .qr-container button {
    font-size: 14px;
    border-radius: 10px;
    background-color: #14304d;
    padding: 10px 20px;
}

.esign-modal .qr-container .details {
    min-height: 100px;
    min-width: 178px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 2px;
    flex-direction: column;
}

.esign-modal .qr-container .details h2 {
    font-size: 16px;
    color: #14304d;
}

.esign-modal .qr-container .details label {
    color: #8e8e8e;
    font-size: 12px;
}

.esign-modal .qr-container .details p {
    font-size: 14px;
    color: #494949;
    word-break: break-word;
}

.esign-modal .qr-container .qr-footer {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    gap: 5px;
    flex-direction: row;
    flex-wrap: nowrap;
    width: 100%;
}

.esign-modal .qr-container .horizontal .qr-footer {
    justify-content: flex-end;
}

.esign-modal .qr-container .download.invert {
    flex-direction: column-reverse;
}

.esign-modal .qr-container .horizontal.invert {
    flex-direction: column;
}

.esign-modal .qr-container .horizontal.invert .qr-footer {
    justify-content: flex-start;
}

.esign-modal .qr-container .qr-footer img {
    height: 25px;
}

.max-width-400-modal {
    width: 90%;
    max-width: 400px !important;
}

.achievement-modal {
    position: fixed;
    top: 0;
    left: 0;
    background-color: #00000069;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 997;
    width: 100%;
    height: 100%;
}

.achievement-modal .modal-content {
    background-color: #fff;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0px;
    flex-direction: column;
    max-width: 450px;
    width: 90%;
    overflow: hidden;
}

.achievement-modal .modal-header {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-direction: column;
    width: 100%;
    padding: 20px;
}

.achievement-modal .iron .modal-header {
    background-color: #f6f6f6;
}

.achievement-modal .bronze .modal-header {
    background-color: #f4eada;
}

.achievement-modal .silver .modal-header {
    background-color: #ebedf8;
}

.achievement-modal .gold .modal-header {
    background-color: #fef8e0;
}

.achievement-modal .platinum .modal-header {
    background-color: #e5f9ff;
}

.achievement-modal .emerald .modal-header {
    background-color: #e5faf4;
}

.achievement-modal .modal-content h3 {
    font-size: 21px;
    font-weight: 600;
    color: #000;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0px;
    flex-direction: column;
    width: 100%;
    text-transform: capitalize;
    line-height: 29px;
}

.achievement-modal .modal-content h3 span {
    font-size: 16px;
}

.achievement-modal .modal-content h2 {
    font-size: 16px;
    color: #000;
    padding: 20px;
    padding-top: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0px;
    flex-direction: column;
    width: 100%;
    text-transform: capitalize;
    line-height: 24px;
}

.achievement-modal .modal-content h2 span {
    font-weight: 400;
    font-size: 21px;
}

.achievement-modal .iron .modal-header h2 {
    color: #635e58;
}

.achievement-modal .bronze .modal-header h2 {
    color: #b17e3b;
}

.achievement-modal .silver .modal-header h2 {
    color: #555a80;
}

.achievement-modal .gold .modal-header h2 {
    color: #925c0b;
}

.achievement-modal .platinum .modal-header h2 {
    color: #0087ab;
}

.achievement-modal .emerald .modal-header h2 {
    color: #00906b;
}

.achievement-modal .modal-content img {
    width: 100%;
    max-width: 200px;
    height: auto;
}

.achievement-modal .modal-body {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-direction: column;
    width: 90%;
    padding: 20px;
}

.achievement-modal .progress-bar {
    width: 100%;
    height: 15px;
    background-color: #e0e0e0;
    border-radius: 20px;
    position: relative;
}

.achievement-modal .progress-bar .progress {
    height: 100%;
    background-color: #00c996;
    border-radius: 20px;
}
.achievement-modal .modal-body p {
    font-size: 14px;
    color: #494949;
    text-align: center;
    line-height: 20px;
}

.achievement-modal .modal-body .help-underline {
    font-size: 14px;
    color: #157efb;
    text-align: center;
    line-height: 20px;
    text-decoration: none;
    border-bottom: 1px dashed #157efb;
    margin-bottom: 10px;
}

.achievement-modal .modal-body button {
    font-size: 16px;
    padding: 10px 40px;
    border-radius: 10px;
    background-color: #2977c9;
    color: #fff;
    cursor: pointer;
    margin-top: 10px;
    width: 100%;
    max-width: 250px;
    font-weight: 500;
}

.achievement-modal .shimmer {
    color: grey;
    display: inline-block;
    mask: linear-gradient(-60deg, #000 30%, #0005, #000 70%) right/350% 100%;
    animation-delay: 0.6s;
    animation: achievementshimmer 2.5s;
    font-size: 50px;
    max-width: 200px;
}

@keyframes achievementshimmer {
    0% {
        transform: scale(0.8);
    }
    10% {
        transform: scale(1.2);
    }
    20% {
        transform: scale(1);
    }
    100% {
        mask-position: left;
    }
}

.achievement-modal .modal-header .confetti {
    position: absolute;
    z-index: 0;
    top: 75%;
    left: 75%;
    transform: translate(-50%, -50%);
    scale: 1.5;
    width: 100%;
    height: 100%;
    opacity: 0;
    animation-name: confetti;
    animation-duration: 0.6s;
    animation-delay: 2.5s;
    animation-fill-mode: forwards;
}

@keyframes confetti {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.achievement-modal .modal-header .image-block {
    position: relative;
}

@media screen and (max-width: 1620px) {
    .element .fadeout {
        display: none;
    }

    /* .marketplace .sub-nav {
    display: none;
  } */

    .element .settings .sub-nav {
        height: fit-content;
        /* width: 100%; */
        width: fit-content;
        flex-grow: 1;
    }

    .profile-progress {
        display: none;
    }

    .sub-nav .profile-storage {
        position: relative;
    }
}

@media screen and (max-width: 1250px) {
    .element {
        max-width: 100%;
    }
}

@media screen and (max-width: 820px) {
    .element .container .header .back-btn {
        display: none;
    }

    .element .container .header h1 label {
        width: fit-content;
    }

    .element .container .header h1 {
        text-align: left;
    }

    .sub-nav .approvals-signature {
        display: none;
    }

    .approval-wrapper .approvals-signature {
        display: flex;
    }

    .approval-wrapper .actions {
        width: 100%;
    }

    .approval-wrapper .content-wrapper {
        width: calc(100% - 123px);
    }

    .approval-wrapper .flex-grow {
        display: none;
    }

    .approval-wrapper {
        max-width: none;
    }

    .signature-tile {
        height: calc(100vh - 260px) !important;
    }

    .onboarding-tutorial {
        display: none;
    }

    .onboarding-tutorial-btn {
        display: none;
    }

    .complete-profile {
        width: 100%;
        box-shadow: none;
        margin: 0px;
        padding: 0px;
    }

    .complete-profile .circle-wrap {
        display: none;
    }

    .marketplace .template-wrapper {
        width: 100%;
    }

    .marketplace .template-tile {
        width: fit-content;
        min-width: 276px;
    }

    .element {
        max-width: 100%;
        height: 100%;
    }

    .element .tiles {
        min-width: 300px;
        width: 100%;
        gap: 10px;
    }

    .element .tiles::-webkit-scrollbar {
        display: none;
    }

    .element-modal .content {
        min-width: auto;
        max-width: 90%;
    }

    .element-modal .content.issue-conf-modal {
        height: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        padding: 10px 5px;
    }

    .element-modal .content.issue-conf-modal .container {
        min-height: 25px;
        height: fit-content;
    }

    .element-modal .content .issue-preview,
    .element-modal .content .issue-actions {
        overflow-y: auto;
        overflow-x: hidden;
        height: 100%;
    }

    .tile-wrapper {
        width: 100%;
        height: fit-content;
        align-items: center;
    }

    .element .tiles .tile {
        width: 100%;
        max-width: 400px;
        height: 240px;
    }

    .tile-wrapper .action {
        display: none;
    }

    .element .container .header .toggle button {
        font-size: 15px;
        border-radius: 5px;
    }

    .element .container .header .toggle {
        width: 100%;
        justify-content: space-between;
    }

    .btn-issue {
        display: none;
    }

    .marketplace .sub-nav {
        min-width: auto;
        display: none;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 9;
    }

    .marketplace .wrapper::-webkit-scrollbar {
        display: none;
    }

    .marketplace .wrapper {
        width: 100%;
        padding: 0px;
    }

    .marketplace .wrapper.pad5 {
        padding: 5px;
    }

    .element .tiles.marketplace {
        padding: 10px;
        /* height: calc(100% - 70px); */
        height: 100vh;
    }

    .element .tiles.mydocuments {
        padding: 10px;
        height: calc(100vh - 320px);
    }

    .upload-doc-btn {
        text-decoration: none;
        color: #157efb;
        padding: 10px;
        min-width: 80%;
        text-align: center;
        border-radius: 10px;
        border: 1px solid #157efb;
        justify-content: center;
        align-items: center;
    }

    .element .settings .sub-nav {
        height: fit-content;
    }

    .sub-nav .profile-storage {
        position: relative;
    }

    .profile-progress {
        display: none;
    }

    .settings .wrapper {
        height: fit-content;
    }

    .marketplace .sub-nav.show {
        display: flex;
    }

    .marketplace .sub-nav.hide {
        display: none;
    }

    .feedback-wrapper {
        width: 100%;
        flex-wrap: wrap;
        height: fit-content;
        flex-direction: column;
    }

    .feedback-wrapper .container {
        height: fit-content;
    }

    .feedback-wrapper hr {
        display: none;
    }

    .feedback-wrapper .date {
        margin: 0px;
    }

    .activity-logs .selector button p {
        display: none;
    }

    .settings-container .apply-btn {
        position: fixed;
        width: fit-content;
        bottom: 70px;
        right: 10px;
    }

    .settings-container .apply-btn button:hover {
        width: 60px;
        border-radius: 50%;
    }

    .settings-container .apply-btn button:hover p {
        display: none;
    }

    .cert-search {
        display: none;
        justify-content: flex-start;
        align-items: center;
        align-content: center;
        flex-direction: column;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 997;
        background-color: #00000069;
        padding: 10px;
        border-radius: 0px;
    }

    .cert-search input,
    .cert-search select {
        width: 100%;
        height: 52px;
        background-color: #fff;
    }

    .cert-search span {
        display: none;
    }

    .cert-search .search-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 15px;
        padding: 0px;
        right: 30px;
        height: 42px;
        width: 42px;
        border-radius: 50%;
        background-color: #c2e9fb;
        color: #157efb;
    }

    .cert-search .search-btn i {
        pointer-events: none;
    }

    .cert-search.show {
        display: flex;
    }

    .cert-search.hide {
        display: none;
    }

    .qr-verify-container {
        justify-content: flex-start;
    }

    .tutorial-video-modal .skip-btn {
        top: 10px;
        bottom: auto;
    }

    .eSignatures {
        justify-content: center;
    }

    .eSignatures .wrapper {
        width: 100%;
        padding: 10px;
    }

    .esign-modal .container::-webkit-scrollbar {
        width: 0;
        height: 0;
        display: none;
    }

    .esign-modal .container .preview .submit {
        bottom: -26px;
    }

    .add-organiser-container {
        flex-direction: column !important;
    }

    .achievement-modal .modal-content {
        align-self: flex-start;
        margin-top: 20px;
    }
}

@media screen and (max-width: 890px) {
    .approval-wrapper .container .approval-icon {
        display: none;
    }
}

@media screen and (max-width: 640px) {
    .tileV2 {
        width: calc(50% - 5px);
    }

    .headboard {
        flex-direction: column;
        gap: 10px;
        min-height: auto;
    }

    .headboard h2,
    .head-container {
        width: 100%;
    }

    .headboard h2 {
        overflow: hidden;
    }

    .headboard .content-container {
        flex-direction: row;
        width: 100%;
        justify-content: space-between;
        flex-wrap: wrap;
    }

    .headboard .content-container .content {
        align-items: flex-start;
    }

    .reactEasyCrop_Container {
        pointer-events: none;
    }
}

@media screen and (max-width: 420px) {
    .tileV2 {
        width: 100%;
    }
}
