.account {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;
    flex-direction: row;
    height: calc(100vh - 100px);
}

.account-nav-container {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;
    flex-direction: column;
}

.account-nav.home {
    background-color: transparent;
}

.account-nav {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;
    flex-direction: column;
    padding: 10px;
    background-color: #fff;
    border-radius: 5px;
    width: 300px;
}

.account-nav .nav {
    color: #2977c9;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    width: 100%;
    padding: 10px;
    border-radius: 5px;
}

.account-nav .nav.active {
    background-color: #d0e5fe;
}

.account-nav .nav:hover {
    background-color: #f6f6f6;
}

.account-main {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    flex-direction: column;
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    padding-bottom: 30px;
}

.account-main .profile-icon-edit {
    position: relative;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 25px 50px -12px;
}

.account-main .profile-icon-edit img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
}

.account-main .profile-icon-edit .edit-btn {
    position: absolute;
    bottom: 0px;
    right: 0px;
    padding: 0px;
    width: fit-content;
    z-index: 2;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.account-main .element-modal .content {
    max-width: 600px;
}

.account-main h1 {
    font-size: 22px;
    font-weight: 400;
    max-width: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
}

.account-main h1 b {
    max-width: 100%;
    display: inline-block;
    font-size: inherit;
    font-weight: inherit;
    color: inherit;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.account-main h1 span {
    font-size: inherit;
    font-weight: 600;
    color: #2977c9;
}

.account-main p {
    color: #444746;
}

.account-main .account-container {
    display: flex;
    justify-content: center;
    align-items: stretch;
    gap: 10px;
    flex-wrap: wrap;
    width: fit-content;
    max-width: 1220px;
}

.account-main .account-container .container {
    background-color: #fff;
    border-radius: 5px;
    padding: 10px;
    min-width: 260px;
    max-width: 400px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;
    flex-direction: column;
    flex-grow: 1;
}

.account-main .account-container .container.full {
    min-width: 70%;
    max-width: none;
}

.account-main .red {
    font-size: inherit;
    color: #df7184;
}

.account-main .link {
    color: #2977c9;
    font-size: 12px;
    font-weight: 400;
}

.account-main .account-container .container.half {
    min-width: 67%;
    max-width: 810px;
}

.account-main .account-container .container.highlight {
    border: 1px solid #df7184;
    margin-top: 15px;
}

.account-main .account-container .container.organiser-account-tutorial {
    background-image: linear-gradient(60deg, #abecd6 0%, #fbed96 100%);
    align-items: center;
    justify-content: center;
    height: calc(100vh - 360px);
}

.account-main .account-container .container.organiser-account-tutorial .secondary-btn {
    background-color: #2977c9;
    color: #fff;
    padding: 10px 30px;
    border-radius: 60px;
    font-size: 18px;
}

.account-main .account-container .container.organiser-account-tutorial .play-tutorial-btn {
    width: fit-content;
    background-color: #f9f9f9;
    color: #3584a7;
    border-radius: 20px;
    font-size: 12px;
    font-weight: 600;
    animation: bounce 3s ease infinite;
}

@keyframes bounce {
    70% {
        transform: translateY(0%);
    }

    80% {
        transform: translateY(-15%);
    }

    90% {
        transform: translateY(0%);
    }

    95% {
        transform: translateY(-9%);
    }

    97% {
        transform: translateY(0%);
    }

    99% {
        transform: translateY(-5%);
    }

    100% {
        transform: translateY(0);
    }
}

.account-main .account-container .container.organiser-account-tutorial h2 {
    color: #4caf50;
    font-size: 2rem;
    font-weight: 400;
    text-align: center;
    max-width: 80%;
}

.account-main .account-container .container.organiser-account-tutorial h2 span {
    font-size: inherit;
    font-weight: 900;
    background-color: red;
    background-image: linear-gradient(45deg, #473b7b 0%, #3584a7 51%, #30d2be 100%);
    background-size: 100%;
    background-repeat: repeat;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-background-clip: text;
    -moz-text-fill-color: transparent;
}

.account-main .grid-wrap {
    display: grid;
    width: 100%;
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    gap: 10px;
}

.account-main .grid-wrap .column-container {
    width: 100%;
    box-sizing: border-box;
}

.account-main h2 {
    color: #2977c9;
    font-size: 16px;
    font-weight: 400;
}

.account-main .small-font {
    font-size: 12px !important;
    font-weight: 400 !important;
}

.account-main .small-gray {
    font-size: 12px !important;
    font-weight: 400 !important;
    color: #444746 !important;
}

.account-main p.blue {
    color: #2977c9;
}

.account-main button {
    padding: 5px 20px;
    border-radius: 5px;
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    color: #fff;
}

.account-main .row-container {
    gap: 5px;
}

.account-main .btn-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-direction: row;
    flex-wrap: wrap;
}

.account-main .btn-wrapper button {
    width: fit-content;
    font-size: 14px;
    font-weight: 400;
    background-color: #d0e5fe;
    color: #2977c9;
    border-radius: 30px;
    transition: 0.3s;
}

.account-main .btn-wrapper button:hover {
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
    transition: 0.3s;
}

.account-main .btn-wrapper button.secondary {
    background-color: #4caf50;
    color: #fff;
}

.account .column-container {
    padding: 10px;
    border-radius: 5px;
    background-color: #f9f9f9;
}

.account .column-container p {
    font-size: 12px;
    font-weight: 600;
}

.account .column-container p.info {
    color: #df7184;
    font-weight: 400;
}

.account .column-container input,
.account .column-container select {
    padding: 5px 10px;
    border-radius: 5px;
    background-color: inherit;
    max-width: 400px;
}

/* --------------data--------------------- */

.account-main .storage-bar {
    height: 10px;
    border-radius: 5px;
    width: 100%;
    position: relative;
    background: linear-gradient(
        to right,
        #4caf50 40%,
        /* Green: 70% */ #ffa500 80%,
        /* Orange: 20% */ #ff0000 100% /* Red: 10% */
    );
    display: flex;
    justify-content: flex-end;
    align-items: center;
    overflow: hidden;
    border: 1px solid #2977c9;
}

.account-main .storage-bar .neg-bar {
    height: 100%;
    width: var(--storage-var);
    animation: storage-animation 0.6s linear;
    background-color: #fff;
}

@keyframes storage-animation {
    0% {
        width: 100%;
    }

    100% {
        width: var(--storage-var);
    }
}

.account-main .column-container .saved-design {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    flex-direction: column;
}

.account-main .sharing-container {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;
    flex-wrap: wrap;
}

.account-main .column-container .saved-design img {
    height: 90px;
    width: auto;
    border-radius: 5px;
}

.account-main .secondary-btn {
    background-color: transparent;
    border: 1px solid #2977c9;
    color: #2977c9;
    font-size: 12px;
    font-weight: 600;
    width: fit-content;
    cursor: pointer;
}

.account-main .secondary-btn.red {
    border: 1px solid #df7184;
    color: #df7184;
}

.account-main .user-signature {
    width: 100%;
    height: auto;
}

.account-main .transactions {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 5px;
    flex-direction: column;
    width: 100%;
}

.account-main .transactions .from,
.account-main .transactions .to {
    font-size: 12px;
    color: #444746;
}

.account-main .transactions .from span {
    padding: 1px;
    color: #4caf50;
    border-radius: 5px;
    font-size: inherit;
    font-weight: 600;
}

.account-main .transactions .to span {
    padding: 1px;
    color: #df7184;
    border-radius: 5px;
    font-size: inherit;
    font-weight: 600;
}

.account-main .transactions .to.first span {
    padding: 1px 8px;
    background-color: #df7184;
    color: #fff;
    border-radius: 5px;
    font-size: inherit;
    font-weight: 600;
}

.account-main .transactions a.invoice-btn {
    font-size: 12px;
    font-weight: 600;
    color: #000;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 5px;
    text-decoration: none;
    border-bottom: 1px solid #000;
}

.account-main .action-btns {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.account-main .action-btns .accept {
    background-color: #4caf50;
}

.account-main .action-btns .reject {
    background-color: #df7184;
}

.account-main h1 i {
    font-size: inherit;
}

.account-nav .red {
    color: #df7184;
}

.account-main .verification-container {
    margin: 0px;
}

.api-table {
    width: 100%;
    background-color: #fff;
    padding: 10px;
    border-radius: 5px;
}

.api-table table {
    width: 100%;
    border-collapse: collapse;
}

.api-table td,
.api-table th {
    border: 1px solid #ddd;
    padding: 8px;
    max-width: 120px;
}

.api-table tr:nth-child(even) {
    background-color: #f2f2f2;
}

.api-table tr:hover {
    background-color: #ddd;
}

.api-table th {
    padding: 8px;
    text-align: left;
    background-color: #f9f9f9;
    color: #444746;
}

.api-table h2 {
    color: #4caf50;
    margin-bottom: 10px;
}

.api-table td p {
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.api-table td button {
    width: fit-content;
    max-width: 100px;
    font-size: 14px;
    background-color: #df7184;
    margin: 0px;
}

.api-table td:last-child {
    width: 120px;
}

.api-table button {
    width: fit-content;
    margin-top: 10px;
}

.api-modal {
    background-color: #fff;
    padding: 10px;
    border-radius: 5px;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    gap: 10px;
    flex-direction: column;
    width: 100%;
    max-width: 360px;
}

.api-modal .info-box {
    background-color: #d0e5fe69;
    padding: 10px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2px;
    flex-direction: column;
    width: 100%;
    color: #444746;
    text-decoration: none;
    transition: 0.3s;
}

.api-modal .info-box:hover {
    background-color: #d0e5fe99;
    transition: 0.3s;
}

.api-modal .info-box b {
    color: #2977c9;
    font-weight: 400;
}

.api-modal .input-wrapper {
    width: 100%;
}

.api-modal label {
    font-size: 12px;
    font-weight: 600;
    color: #444746;
}

.api-modal input,
.api-modal textarea,
.api-modal select {
    padding: 5px;
    border-radius: 5px;
    background-color: #f6f6f6;
    margin: none;
    resize: none;
    font-size: 14px;
    border-color: #f6f6f6;
    width: 100%;
}

.api-modal p.desc {
    font-size: 12px;
    color: #595959;
}

.api-modal p.warn {
    font-size: 12px;
    color: #ff0000;
}

.api-modal .input-wrapper button {
    width: fit-content;
    margin: auto;
    margin-right: 0px;
    margin-top: 5px;
    font-size: 12px;
    font-weight: 600;
}

.api-modal .input-wrapper .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 5px;
    flex-direction: row;
    flex-wrap: nowrap;
    position: relative;
    margin-bottom: 5px;
}

.api-modal .input-wrapper .container button {
    height: 100%;
    width: fit-content;
    padding: 5px;
    background-color: #df7184;
}

.api-modal .input-wrapper .container .fa-triangle-exclamation {
    color: #ffa500;
    display: none;
}

.api-modal .input-wrapper .container.invalid .fa-triangle-exclamation {
    display: block;
    position: absolute;
    top: 50%;
    right: 27px;
    transform: translateY(-50%);
    z-index: 1;
    padding: 10px;
    background-color: #f6f6f6;
    border-radius: 5px;
}

.api-modal .back-btn {
    width: fit-content;
    background-color: #444746;
    font-size: 14px;
}

.api-table .fa-copy {
    background-color: #4caf50;
    color: #fff;
    padding: 5px;
    border-radius: 20px;
    width: 24px;
    height: 24px;
    text-align: center;
    cursor: pointer;
}

.btn-fit {
    width: fit-content;
}

@media screen and (max-width: 820px) {
    .account-nav p {
        display: none;
    }

    .account-nav {
        min-width: auto;
        width: fit-content;
    }

    .account-nav.home {
        display: none;
    }

    .account-main {
        margin-left: 10px;
    }

    .account-main::-webkit-scrollbar {
        width: 0px;
        display: none;
    }
}

@media screen and (max-width: 500px) {
    .account-nav p {
        display: none;
    }

    .account {
        gap: 0px;
    }

    .account-nav {
        min-width: auto;
        width: fit-content;
        flex-direction: row;
        justify-content: space-between;
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px,
            rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
        z-index: 99;
    }

    .account-nav .nav {
        width: fit-content;
    }

    .account-main {
        margin-left: 0px;
        padding-bottom: 60px;
    }
}
