.landing-page {
    height: calc(100vh - 130px);
    overflow: scroll;
    width: 100%;
}

.landing-page::-webkit-scrollbar {
    width: 0px;
    display: none;
}

.full-height {
    min-height: calc(100vh - 68px);
    width: 100%;
}

.section {
    padding: 60px 0px;
}

.landing,
.process {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    gap: 30px;
    position: relative;
    flex-wrap: wrap;
}

.landing h1 {
    font-size: 5rem;
    color: #acc3d0;
    font-weight: 900;
    position: relative;
}

.landing h1 span {
    font-size: inherit;
    font-weight: inherit;
    color: #0082f9;
}

.landing p {
    color: #4a9dd0;
    font-size: 18px;
    font-weight: 600;
}

.flex-column {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 20px;
    flex-direction: column;
}

.landing button {
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: space-between;
    max-width: 400px;
}

.landing button i {
    font-size: 21px;
}

button.alt {
    background-color: #f6f6f6;
    color: #0082f9;
}

.landing .features {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 20px;
    padding: 20px;
    border-radius: 10px;
    background-color: #ffffff69;
    flex-direction: column;
    width: 300px;
    max-width: 100%;
}

.landing .features h2 {
    font-size: 21px;
    color: #000;
    font-weight: 600;
}

.landing .features p {
    font-size: 14px;
    color: #818181;
}

.landing .features img {
    height: 200px;
}

.features-container {
    display: flex;
    gap: 20px;
    justify-content: flex-end;
    max-width: 800px;
    flex-wrap: wrap;
}

.process h1 {
    font-size: 4rem;
    color: #a7beda;
    font-weight: 900;
    position: relative;
}

.process h1 span {
    font-size: inherit;
    font-weight: inherit;
    color: #0082f9;
}

.process img {
    max-width: 100%;
    height: 600px;
    object-fit: contain;
}

.process .tagline {
    flex-basis: 100%;
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    gap: 30px;
}

.process .features {
    background-color: #0082f939;
    color: #0082f9;
    padding: 20px;
    border-radius: 10px;
    padding-right: 40px;
}

.process .features li {
    line-height: 30px;
    font-size: 16px;
}

.process .tagline h2 {
    font-size: 21px;
    font-weight: 400;
    color: #4299ce;
}

.process .tagline h2 span {
    font-size: 28px;
    font-weight: 600;
    color: #4299ce;
    display: block;
}

.process p {
    color: #4299ce;
    font-size: 18px;
    font-weight: 400;
}

.pricing {
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
    gap: 30px;
    align-items: center;
}

.pricing h1 {
    font-size: 3rem;
    font-weight: 600;
    text-align: center;
    color: #0082f9;
}

.pricing .pricing-container {
    max-width: 300px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    min-width: 280px;
    position: relative;
}

.pricing .pricing-container .buy-btn {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0%);
    width: fit-content;
    z-index: -1;
    border-radius: 0px 0px 10px 10px;
    padding: 10px 20px;
    height: fit-content;
    min-width: 60%;
    transition: 0.3s;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell,
        'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: 600;
}

.pricing .pricing-container .buy-btn span {
    font-size: 12px;
    font-weight: 400;
    padding-right: 3px;
}

.pricing .pricing-container:hover .buy-btn,
.pricing .pricing-container .buy-btn:hover {
    transform: translate(-50%, 100%);
    transition: 0.3s;
}

.pricing .pricing-wrapper {
    display: flex;
    justify-content: center;
    align-items: stretch;
    gap: 30px;
    flex-wrap: wrap;
}

.pricing .pricing-container.t1 {
    background-color: transparent;
    padding: 0px;
}

.pricing .pricing-container.t1 .sub {
    background-color: #fff;
    padding: 30px;
    /* height: fit-content; */
    height: 100%;
    border-radius: 10px;
    box-shadow: 3px 3px 20px 0px #0081f92e;
}

.pricing .pricing-container.t1 h2 {
    padding-left: 30px;
}

.pricing .pricing-container.t2 {
    background-color: #fff;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 3px 3px 20px 0px #0081f92e;
}

.pricing .pricing-container.t2 .sub {
    background-color: transparent;
    padding: 30px 0px;
}

.pricing .pricing-container h2 {
    color: #000;
    font-size: 21px;
    font-weight: 600;
    padding-bottom: 20px;
    text-align: left;
}

.pricing .pricing-container li {
    line-height: 30px;
    color: #0082f9;
    font-weight: 600;
}

.pricing .pricing-container.blue {
    background-color: #2977c9;
}

.pricing .pricing-container.dark .sub {
    background-color: #002d63;
}

.pricing .pricing-container.blue h2 {
    color: #fff;
}

.pricing .pricing-container.dark h2 {
    color: #002d63;
}

.pricing .pricing-container.blue p,
.pricing .pricing-container.dark p {
    color: #fff;
    font-weight: 600;
}

.pricing .pricing-container.white p {
    color: #0082f9;
    font-weight: 600;
}

.pricing .pricing-container p {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
}

.pricing .pricing-container .sub {
    display: flex;
    /* justify-content: flex-start; */
    justify-content: center;
    align-items: flex-start;
    gap: 15px;
    flex-direction: column;
}

.pricing .pricing-container .sub i {
    color: #82e2ce;
}

.pricing .pricing-container.dark .sub i {
    color: #fdcd74;
}

.moto {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 30px;
    flex-wrap: wrap;
}

.moto h1 {
    font-size: 3rem;
    color: #4299ce;
    font-weight: 900;
    min-width: 200px;
}

.moto h1:nth-child(1) {
    text-align: right;
}

.rel-footer-space {
    height: 200px;
}

.footer {
    padding: 0px;
    width: 100%;
    height: 200px;
    background-color: #2977c9;
    border-radius: 0px;
    position: fixed;
    bottom: -210px;
    left: 0px;
    transition: 0.6s;
}

.footer.active {
    bottom: 0px;
    transition: 0.6s;
}

.footer h2 {
    color: #fff !important;
    font-size: 21px !important;
    font-weight: 400 !important;
    margin: 0px !important;
    padding: 0px !important;
}

.footer .footer-wrapper {
    padding-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    flex-wrap: wrap;
    align-content: center;
}

.footer .footer-wrapper a {
    text-decoration: none;
    color: #fff;
    font-weight: 600;
    padding: 5px 20px;
}

.footer .footer-wrapper a:last-child {
    background-color: #0f3e75;
    padding: 5px 20px;
    border-radius: 10px;
}

.footer .footer-img-container {
    display: block;
    position: absolute;
    top: 0px;
    left: 0px;
    background-color: transparent;
    padding: 0px;
    height: fit-content;
    width: 600px;
    z-index: 0;
}

.footer .footer-img-container img {
    background-color: transparent;
    width: 30%;
    height: 40px;
    object-fit: contain;
    position: absolute;
    top: 15px;
    left: 0px;
}

.footer .footer-img-container .content {
    width: 50%;
    height: 40px;
    position: absolute;
    top: 15px;
    left: 40%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
}

.footer .footer-img-container .content h2 {
    font-weight: 600;
}

.footer .footer-img-container .content p {
    font-size: 12px;
    color: #ccc;
    text-align: center;
}

.privacy-policy {
    padding: 30px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 10px;
    margin: auto;
    max-width: 800px;
    height: calc(100% - 68px);
    overflow-y: scroll;
}

.privacy-policy h1 {
    color: #0082f9;
    font-size: 28px;
    font-weight: 400;
}

.privacy-policy h2 {
    font-size: 21px;
    color: #002d63;
    font-weight: 400;
}

.privacy-policy h3 {
    font-size: 16px;
    color: #002d63;
    font-weight: 400;
}

.privacy-policy p {
    color: #818181;
    font-size: 14px;
}

/* -------------------- Help Page --------------------- */

.help-section {
    width: 100%;
    height: calc(100vh - 100px);
    overflow-y: scroll;
}

.help-section::-webkit-scrollbar {
    width: 0px;
    display: none;
}

.help-section .header {
    background-color: #002d63;
    padding: 5px;
    padding-left: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    border-radius: 10px;
    position: sticky;
    top: 0;
    left: 0;
    z-index: 10;
    flex-wrap: wrap;
}

.help-section .header .search {
    min-width: 200px;
}

.help-section .header select {
    display: none;
    width: fit-content;
    padding: 10px 20px;
    border-radius: 5px;
    font-size: 14px;
    background-color: #002d63;
    border: 1px solid #0082f9;
    color: #fff;
    min-width: 120px;
}

.help-section .header h1 {
    color: #fff;
    font-size: 16px;
    font-weight: 400;
}

.help-section .header .left-sec {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
}

.help-section .header .faq-type {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    flex-direction: row;
}

.help-section .header .faq-type button {
    font-family: 'Poppins', 'Open Sans', sans-serif;
    font-size: 14px;
    font-weight: 400;
    background-color: transparent;
    border: 1px solid #fff;
    padding: 10px;
    border-radius: 5px;
}

.help-section .header .faq-type button.active {
    background-color: #0082f9;
    border: 1px solid #0082f9;
}

.help-section .header .search {
    max-width: 300px;
}

.help-section .header input {
    border-radius: 5px;
}

.help-section .header button {
    border-radius: 0px 5px 5px 0px;
}

.help-section h2 {
    font-size: 18px;
    color: #0082f9;
    font-weight: 600;
    padding: 20px;
    margin-top: 20px;
}

.help-section .faq-section {
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    gap: 20px;
    flex-wrap: wrap;
    padding: 20px;
}

.help-section .faq-tile {
    padding: 20px;
    border-radius: 5px;
    background-color: #fff;
    color: #002d63;
    font-size: 14px;
    font-weight: 600;
    width: fit-content;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    flex-basis: 30%;
    box-shadow: 0px 0px 15px 0px #00000019;
    transition: 0.3s;
    min-width: 300px;
}

.help-section .faq-tile:hover {
    box-shadow: none;
    transition: 0.3s;
}

.video-help-section {
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    gap: 20px;
    flex-wrap: wrap;
    padding: 20px 0px;
    padding-top: 110px;
}

.video-help-section .vid-tile {
    padding: 20px;
    background-color: #fff;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-direction: column;
    width: 240px;
    padding-top: 120px;
    position: relative;
}

.video-help-section .vid-tile p {
    font-size: 14px;
    font-weight: 600;
    color: #0082f9;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    width: 100%;
    gap: 10px;
    padding-top: 10px;
}

.video-help-section .vid-tile p i {
    font-size: 26px;
}

.video-help-section .vid-tile p span {
    font-size: inherit;
}

.video-help-section .vid-tile img {
    border: 1px solid #0082f9;
    border-radius: 10px;
    width: 200px;
    height: 200px;
    object-fit: cover;
    box-shadow: 0px 5px 20px 0px #00000039;
    position: absolute;
    top: -90px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
}

.help-section .signin-section {
    background-color: #002d63;
    color: #4299ce;
    font-size: 16px;
    padding: 30px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    margin-top: 10px;
    border-radius: 10px;
    position: relative;
    overflow: hidden;
}

.help-section .signin-section img {
    position: absolute;
    top: 50%;
    left: 70%;
    height: 100%;
    transform: translate(-50%, -50%);
}

.help-section .signin-section p {
    font-weight: 600;
    font-size: 18px;
    color: #acc3d0;
    z-index: 2;
}

.help-section .signin-section a {
    text-decoration: none;
    background-color: #fff;
    color: #002d63;
    padding: 10px 30px;
    border-radius: 5px;
    font-weight: 600;
    z-index: 2;
}

.help-section .faq-content {
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    gap: 20px;
    flex-direction: row;
    flex-wrap: wrap;
}

.help-section .faq-heading {
    padding: 20px;
    background-color: #fff;
    border-radius: 10px;
    flex-basis: 30%;
}

.help-section .faq-heading p {
    width: 100%;
    padding: 10px 20px;
    text-align: center;
    background-color: #f3f5f8;
    color: #002d63;
    border-radius: 10px;
    font-weight: 600;
}

.help-section .faq-heading ul {
    padding-top: 20px;
    color: #002d63;
}

.help-section .faq-heading ul li {
    padding-bottom: 10px;
    transition: 0.3s;
}

.help-section .faq-heading ul li:hover {
    color: #0082f9;
    cursor: pointer;
    transition: 0.3s;
}

.help-section .faq-description {
    padding: 20px;
    background-color: #fff;
    border-radius: 10px;
    flex-basis: 60%;
}

.help-section .faq-description h1 {
    font-size: 21px;
    font-weight: 400;
    color: #002d63;
    padding-bottom: 20px;
}

.help-section .faq-description p {
    line-height: 24px;
    color: #002d63;
}

.help-section .back-btn {
    width: fit-content;
    padding: 0px 10px;
    background-color: #f3f5f8;
    color: #0082f9;
    font-size: 12px;
    font-weight: 600;
    border-radius: 5px;
    border: 1px solid #0082f9;
    cursor: pointer;
    margin-right: 10px;
    transition: 0.3s;
    font-family: 'Poppins', 'Open Sans', sans-serif;
}

.help-section .back-btn:hover {
    background-color: #0082f9;
    color: #fff;
    transition: 0.3s;
}

.help-section .hr-wrapper {
    position: relative;
}

.help-section .hr-wrapper i {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 16px;
    color: #0082f9;
    background-color: #fff;
    border-radius: 50%;
    padding: 5px;
    border: 2px solid #0082f9;
}

.help-section hr {
    display: block;
    margin: 20px auto;
    width: 50%;
    border: none;
    height: 2px;
    background-color: #0082f9;
}

.wrap-sec {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 10px;
    flex-wrap: wrap;
}

.faq-sec {
    width: 74%;
    min-width: 300px;
}

.callback-sec {
    flex-grow: 1;
    width: 25%;
    min-width: 300px;
    padding: 20px;
    background-color: #0082f914;
    border-radius: 10px;
    position: sticky;
    top: 0;
    right: 0;
    max-width: 400px;
}

.support-type {
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    gap: 10px;
    flex-wrap: wrap;
}

.callback-sec h2 {
    padding: 0px;
    padding-bottom: 10px;
    margin: 0px;
}

.callback-sec h2 i {
    font-size: 28px;
}

.callback-sec p {
    color: #626262;
    font-weight: 400;
    width: fit-content;
}

.callback-sec p span {
    color: #0082f9;
    font-weight: 600;
}

.callback-sec h3 {
    color: #2bcead;
    font-weight: 600;
    font-size: 18px;
    margin-top: 20px;
    width: fit-content;
}

.callback-sec button {
    margin-top: 20px;
    padding: 10px 20px !important;
    width: fit-content;
    border-radius: 5px;
    background-color: #2bcead;
    color: #fff;
}

.callback-sec .label {
    color: #002d63;
    font-size: 12px;
    font-weight: 600;
    width: fit-content;
    padding-bottom: 10px;
    padding-top: 20px;
}

.callback-sec .radio-btn {
    padding: 0px;
    border-radius: 5px;
    width: fit-content;
    position: relative;
}

.callback-sec input[type='radio'] {
    width: 100%;
    opacity: 0;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
}

.callback-sec .radio-btn label {
    color: #fff;
    padding: 10px 20px;
    border-radius: 5px;
    background-color: #0082f9;
    display: block;
}

.callback-sec .radio-btn label i {
    color: #2bcead;
    display: none;
}

.callback-sec input[type='radio']:focus + label {
    color: #fff;
    letter-spacing: normal;
}

.callback-sec input[type='radio']:checked + label i {
    display: inline-block;
}

.callback-sec input[type='radio']:checked + label {
    background-color: #002d63;
}

.help-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #00000039;
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
}

.help-modal .wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 20px;
    flex-direction: row;
    padding: 20px;
    width: 90%;
    max-width: 1200px;
    height: 80%;
    position: relative;
}

.help-modal .sections {
    width: 100%;
}

.help-modal .sections.explore {
    height: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.help-modal .sections.explore .content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 20px;
}

.help-modal .sections video.confetti {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 0;
}

.help-modal .sections.explore .content h2 {
    font-size: 3rem;
    max-width: 320px;
    font-weight: bold;
    background: linear-gradient(to right, #3175ff, #5456ff);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: center;
    line-height: 3.1rem;
    text-transform: none;
}

.help-modal .sections.explore .content h3 {
    font-size: 24px;
    color: #000;
    font-weight: 600;
}

.help-modal .sections.explore .content p {
    max-width: 420px;
    text-align: center;
}

.help-modal .sections.explore .content button {
    padding: 5px 30px;
    border-radius: 10px;
    background-image: linear-gradient(45deg, #0d53a9, #0c77ee);
}

.help-modal .nav-toggle {
    background-color: #0082f9;
    padding: 10px;
    border-radius: 5px;
    display: none;
    color: #fff;
    height: 39px;
    min-width: 39px;
    justify-content: center;
    align-items: center;
}

.help-modal .nav-toggle.close {
    background-color: #ccc;
}

.help-modal .sections.callback .wrapper-container {
    align-items: center !important;
    justify-content: space-between !important;
}

.help-modal .wrapper h2 {
    font-size: 18px;
    font-weight: 600;
    color: #0082f9;
    text-transform: capitalize;
}

.help-modal .wrapper .content .callback-sec {
    width: auto;
}

.help-modal .wrapper .content {
    flex-grow: 1;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;
    flex-direction: column;
    height: 100%;
    overflow-y: scroll;
    position: relative;
}

.help-modal .wrapper .content .main-img {
    height: 300px;
}

.help-modal .wrapper .content::-webkit-scrollbar {
    width: 0px;
    display: none;
}

.help-modal .wrapper .content b {
    font-size: 12px;
    color: #626262;
    font-weight: 600;
}

.help-modal .wrapper .content p.normal {
    color: #626262;
    line-height: 26px;
}

.help-modal .wrapper .content button {
    width: fit-content;
    padding: 5px 20px;
    border-radius: 5px;
    font-family: 'Poppins', 'Open Sans', sans-serif;
    font-size: 16px;
    font-weight: 400;
    transition: 0.3s;
}

.help-modal .wrapper .content button:hover {
    background-color: #002d63;
    transition: 0.3s;
}

.help-modal .wrapper .content .wrapper-container {
    padding: 10px;
    margin-top: 10px;
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    gap: 20px;
    flex-wrap: wrap;
    width: 100%;
}

.help-modal .wrapper .content .wrapper-container .vid-tile {
    border-radius: 10px;
    flex-basis: 30%;
    width: 30%;
    min-width: 250px;
    background-color: #f6f6f6;
    padding: 10px;
    height: fit-content;
    position: relative;
}

.help-modal .wrapper .content .wrapper-container .vid-tile:hover i {
    color: #0082f9;
}

.help-modal .wrapper .content .wrapper-container .vid-tile .play-button {
    opacity: 0;
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-image: linear-gradient(to bottom, #000000af, transparent);
    border-radius: 10px;
    justify-content: center;
    align-items: flex-start;
    padding: 0px;
    overflow: hidden;
    transition: 0.3s;
}

.help-modal .wrapper .content .wrapper-container .vid-tile:hover .play-button {
    opacity: 1;
    transition: 0.3s;
}

.help-modal .wrapper .content .wrapper-container .vid-tile .play-button button {
    font-size: 14px;
    font-weight: 600;
    background-color: #0082f9;
    border-radius: 0px 0px 10px 10px;
}

.help-modal .wrapper .content .wrapper-container .vid-tile .play-button button:hover {
    background-color: #2977c9;
}

.help-modal .wrapper .content .wrapper-container .vid-tile .play-button i {
    color: #fff;
    font-size: 16px;
}

.help-modal .wrapper .content .wrapper-container .vid-tile:first-child {
    flex-basis: 40%;
    width: 40%;
    background-color: #fff;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.help-modal .wrapper .content .wrapper-container .vid-tile img {
    width: 100%;
    border-radius: 10px;
}

.help-modal .wrapper .content .wrapper-container .vid-tile p {
    line-height: 21px;
}

.help-modal .wrapper .content .wrapper-container .container {
    padding: 20px;
    background-color: #1c2127;
    color: #fff;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-direction: column;
    min-width: 200px;
    min-height: 200px;
    flex-basis: 30%;
    transition: 0.3s;
}

.help-modal .wrapper .content .wrapper-container .container:hover {
    background-color: #002d63;
    transition: 0.3s;
}

.help-modal .wrapper .content .wrapper-container .container i {
    color: #fff;
    font-size: 3rem;
}

.help-modal .wrapper .content .wrapper-container .container h3 {
    font-size: 18px;
    color: #fff;
    font-weight: 400;
}

.help-modal .wrapper .content .wrapper-container .container p {
    font-size: 12px;
    color: #f3f5f8;
    font-weight: 600;
    text-align: center;
}

.help-modal .wrapper .content .wrapper-container ul {
    width: 100%;
    color: #1c2127;
}

.help-modal .wrapper .content .wrapper-container a {
    color: #0082f9;
    width: 100%;
}

.help-modal .wrapper .content .wrapper-container ul li {
    padding-bottom: 5px;
}

.help-modal .wrapper .content .wrapper-container ul span {
    display: block;
    font-size: 12px;
    color: #626262;
}

.help-modal .wrapper .header {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    width: 100%;
    position: sticky;
    top: 0;
    left: 0;
    background-color: #fff;
    padding-bottom: 10px;
    z-index: 2;
}

.help-modal .wrapper .header input {
    border: 2px solid #0082f9;
    max-width: 300px;
    padding: 10px 13px;
    font-size: 14px;
}

.help-modal .wrapper .header .back-btn {
    width: fit-content;
    padding: 12px 15px;
    font-size: 14px;
    transition: 0.3s;
    border-radius: 10px;
}

.help-modal .wrapper .header .back-btn:hover {
    background-color: #002d63;
    transition: 0.3s;
}

.help-modal .wrapper .header .search-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    flex-direction: row;
    flex-grow: 1;
}

.help-modal .wrapper .modal-close-btn {
    padding: 0px !important;
    width: fit-content;
    height: 30px;
    width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: transparent;
    color: #d5415a;
}

.help-modal .wrapper .modal-close-btn:hover {
    background-color: transparent !important;
}

.help-modal .wrapper .modal-close-btn i {
    font-size: 21px;
    color: inherit;
}

.help-modal .wrapper .navigation {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 5px;
    flex-direction: column;
    border-right: 2px solid #ccc;
    padding-right: 20px;
    height: 100%;
}

.help-modal .wrapper .navigation button {
    padding: 8px 10px;
    border-radius: 5px;
    color: #626262;
    background-color: transparent;
    font-family: 'Poppins', 'Open Sans', sans-serif;
    font-size: 14px;
    font-weight: 400;
    transition: 0.3s;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    display: flex;
    position: relative;
    width: 200px;
    border: 1px solid #fff;
}

.help-modal .wrapper .navigation button.coming-soon {
    color: #ccc;
}

.help-modal .wrapper .navigation button.coming-soon:hover {
    background-color: transparent;
}

.help-modal .wrapper .navigation button.coming-soon:hover i:nth-child(2) {
    display: none;
}

.help-modal .wrapper .navigation button.active {
    border: 1px solid #0082f9;
    color: #0082f9;
}

.help-modal .wrapper .navigation hr {
    border: none;
    width: 100%;
    background-color: transparent;
    height: 10px;
}

.help-modal .wrapper .navigation button i {
    width: 18px;
}

.help-modal .wrapper .navigation button i:nth-child(2) {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    color: #0082f9;
    display: none;
}

.help-modal .wrapper .navigation button:hover i:nth-child(2) {
    display: block;
}

.help-modal .wrapper .navigation button:hover {
    background-color: #f6f6f6;
    transition: 0.3s;
}

.menu-nav-btn {
    width: fit-content;
    padding: 5px;
    width: 40px;
}

.menu-nav-btn:hover {
    background-color: #002d63;
}

.menu-nav {
    position: fixed;
    top: 80px;
    right: 60px;
    padding: 20px;
    border-radius: 10px;
    background-color: #fff;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    gap: 5px;
    flex-direction: column;
    min-width: 280px;
    z-index: 999;
}

.menu-nav a {
    text-decoration: none;
    color: #002d63;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    transition: 0.3s;
    padding: 10px;
}

.menu-nav a:hover {
    color: #0082f9;
    gap: 15px;
    transition: 0.3s;
}

.old-pronetin {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100vh;
    background-color: #00000039;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999 !important;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;
}

.old-pronetin .container {
    background-color: #ffff;
    padding: 20px;
    border-radius: 5px;
    min-width: 250px;
    width: fit-content;
    max-width: 400px !important;
    min-height: 286px;
}

.old-pronetin img {
    max-width: 300px;
    height: 330px;
    border-radius: 10px;
}

.old-pronetin p {
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    font-size: 3rem;
    font-weight: 600;
    line-height: 3.1rem;
    color: #0082f9;
}

.old-pronetin span {
    font-family: 'Trebuchet MS', 'Gill Sans', 'Gill Sans MT', Calibri, sans-serif;
    display: block;
    color: #1c2127;
    margin-top: 10px;
    font-weight: 200;
    font-size: 16px;
    line-height: 19px;
}

.old-pronetin a {
    color: #0082f9;
    font-weight: 700;
}

.old-pronetin .explore-btn {
    display: flex;
    padding: 10px;
    border-radius: 5px;
    background-image: linear-gradient(22deg, #0082f9 0%, #a2b6df 100%, #6b8cce 100%, #a2b6df 100%);
    color: #fff;
    text-decoration: none;
    margin-top: 10px;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    font-weight: 300;
}

.action-completed-message {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100% - 70px);
}

.action-completed-message .container {
    background-color: #fff;
    padding: 20px;
    border-radius: 5px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    color: #626262;
    min-width: 250px;
    min-height: 300px;
}

@media screen and (max-width: 1400px) {
    .callback-sec {
        max-width: 400px;
        width: 100%;
    }

    .faq-sec {
        width: 100%;
    }

    .wrap-sec {
        justify-content: center;
        gap: 30px;
    }
}

@media screen and (max-width: 1200px) {
    .landing h1,
    .process h1 {
        font-size: 3rem;
    }

    .moto h1 {
        text-align: center;
    }

    .moto h1:nth-child(1) {
        text-align: center;
    }

    ::-webkit-scrollbar {
        width: 0px;
    }

    .features-container {
        justify-content: center;
    }

    .help-modal .wrapper .content .main-img {
        display: none;
    }
}

@media screen and (max-width: 1700px) {
    .features-container {
        max-width: none;
    }

    .landing {
        justify-content: flex-start;
    }
}

@media screen and (max-width: 630px) {
    .footer {
        overflow: hidden;
    }

    .footer .footer-img-container .content {
        display: none;
    }

    .footer .footer-wrapper {
        flex-wrap: wrap;
        height: fit-content;
        top: auto;
        bottom: 20px;
    }

    .help-section .header {
        padding-left: 5px;
        gap: 10px;
        justify-content: center;
    }

    .help-section .faq-tile {
        min-width: auto;
        width: 100%;
        flex-basis: 100%;
    }

    .help-section .faq-heading {
        flex-basis: 90%;
    }

    .help-section .faq-description {
        flex-basis: 100%;
    }

    .callback-sec {
        width: 100%;
        max-width: 100%;
    }

    .menu-nav {
        top: 60px;
        right: 10px;
    }

    .old-pronetin img {
        display: none;
    }
}

@media screen and (max-width: 730px) {
    .help-modal .wrapper {
        overflow: hidden;
    }

    .help-modal .navigation {
        position: absolute;
        top: 0;
        left: -300px;
        background-color: #fff;
        z-index: 3;
        padding: 20px;
    }

    .help-modal .navigation.active {
        left: 0px;
    }

    .help-modal .nav-toggle {
        display: flex;
    }

    .callback-sec {
        min-width: 250px;
        width: 100%;
    }

    .callback .main-img {
        display: none;
    }

    .help-modal .wrapper .content .wrapper-container {
        padding: 0px;
        justify-content: center;
    }

    .help-modal .wrapper .content .wrapper-container.jc-left {
        justify-content: left;
    }

    .help-modal .wrapper .content .main-img {
        max-width: 100%;
    }

    .moto {
        gap: 10px;
    }

    .moto h1 {
        font-size: 2rem;
        font-weight: 600;
        min-width: 92px;
    }

    .moto img {
        width: 80px;
    }

    .footer .footer-wrapper {
        gap: 5px;
    }

    .help-section .header select {
        display: block;
    }

    .help-section .header .faq-type {
        display: none;
    }
}
