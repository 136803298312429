.invoice-container {
    width: 794px;
    height: 1100px;
    margin: 0 auto;
    background-color: #fff;
    padding: 20px;
    border: 0px solid #000;
    border-radius: 0px;
    display: flex;
    flex-direction: column;
}

.invoice-title {
    text-align: center;
    font-size: 24px;
    margin-bottom: 20px;
    text-transform: uppercase;
    border-bottom: 2px solid #000;
    padding-bottom: 10px;
}

.invoice-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.left-column,
.right-column {
    width: 45%;
}

.left-column p,
.right-column p {
    margin: 5px 0;
}

.invoice-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
}

.invoice-table th,
.invoice-table td {
    border: 1px solid #000;
    padding: 10px;
    text-align: left;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell,
        'Open Sans', 'Helvetica Neue', sans-serif;
}

.invoice-table th {
    background-color: #f2f2f2;
}

.invoice-footer {
    margin-top: 20px;
}

.invoice-footer p {
    font-size: 16px;
    margin: 10px 0;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell,
        'Open Sans', 'Helvetica Neue', sans-serif;
}

.invoice-footer .words {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-direction: column;
    width: 100%;
}

.invoice-footer .words strong {
    text-transform: capitalize;
}

.invoice-footer .total {
    font-size: 24px;
    font-weight: 600;
}

.invoice-container .thank-you {
    text-align: center;
    margin-top: 30px;
    font-weight: bold;
    margin-bottom: 30px;
}

.pdfinvoice-download-button {
    width: fit-content;
    position: fixed;
    top: 10px;
    right: 10px;
}

.invoice-container .signature {
    width: 100%;
    height: 160px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    flex-direction: column-reverse;
    gap: 10px;
}

.invoice-container .signature img {
    height: 100%;
}

.invoice-container .signature p {
    margin-right: 20px;
    font-weight: 600;
}

.invoice-container .fg-1 {
    flex-grow: 1;
}

.thank-you-modal {
    width: 90%;
    max-width: 600px;
    background-color: #fff;
    box-shadow: rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset, rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
        rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
    padding: 20px;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    flex-direction: column;
}

.thank-you-modal h2 {
    font-size: 3rem;
    color: #1a7efb;
}

.thank-you-modal h3 {
    font-size: 18px;
    font-weight: 400;
    color: #616161;
}

.thank-you-modal h3 b {
    font-size: inherit;
}

.thank-you-modal p a {
    color: #000;
    font-weight: 600;
}

.thank-you-modal video {
    width: 100%;
    border-radius: 10px;
}

.thank-you-modal .video {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;
    flex-direction: column;
    width: 100%;
    padding-top: 20px;
    border-top: 1px solid #cccc;
}

.thank-you-modal .video p {
    color: #1a7efb;
    font-weight: 600;
}

.thank-you-modal img {
    max-height: 200px;
    margin: 20px 0px;
}
