.header {
  width: 100%;
  flex: 0 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 20px;
}

.header .logo {
  height: 38px;
  width: auto;
  object-fit: contain;
  padding: 0px;
  border-radius: 0px;
  cursor: pointer;
}

.signout {
  font-family: 'Poppins', 'Open Sans', sans-serif;
  font-size: 12px;
  background-color: transparent;
  color: #df7184;
  border: 1px solid #df7184;
  transition: 0.3s;
  font-weight: 600;
  text-decoration: none;
  padding: 10px;
  border-radius: 5px;
  width: fit-content;
  text-align: center;
  white-space: nowrap;
}

.signout:hover {
  background-color: #df7184;
  color: #fff;
  border: 1px solid #df7184;
  transition: 0.3s;
}

.private-doc-message .signin-btn {
  border-radius: 5px;
  text-decoration: none;
  cursor: pointer;
  margin-top: 20px;
  background-color: #2977c9;
  color: #fff;
}

.private-doc-message .signin-btn i {
  font-size: inherit;
  color: inherit;
  margin-top: 0px;
}

.private-doc-message .signin-btn:hover {
  padding: 3px 40px;
  transition: 0.3s;
}

.signin-btn {
  width: fit-content;
  background-color: transparent;
  color: #2977c9;
  border: 2px solid #2977c9;
  transition: 0.3s;
  padding: 3px 30px;
}

.signin-btn:hover {
  width: fit-content;
  background-color: #2977c9;
  color: #fff;
  border: 2px solid #2977c9;
  transition: 0.3s;
}

.header .container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
}

.header .container .create-btn {
  width: fit-content;
  background-color: transparent;
  background-image: linear-gradient(-20deg, #b721ff 0%, #21d4fd 100%);
  color: #fff;
  border: none;
  transition: 0.3s;
  padding: 5px 32px;
  position: relative;
}

.header .container .create-btn label {
  position: absolute;
  top: -10px;
  right: -10px;
  padding: 2px 10px;
  border-radius: 5px;
  background: radial-gradient(
      ellipse farthest-corner at right bottom,
      #fedb37 0%,
      #fdb931 8%,
      #9f7928 30%,
      #8a6e2f 40%,
      transparent 80%
    ),
    radial-gradient(
      ellipse farthest-corner at left top,
      #ffffff 0%,
      #ffffac 8%,
      #d1b464 25%,
      #5d4a1f 62.5%,
      #5d4a1f 100%
    );
  color: #fff;
  font-size: 12px;
  font-weight: 600;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}

.header .container .find-btn {
  width: fit-content;
  background-color: #2977c939;
  border: none;
  color: #2977c9;
  transition: 0.3s;
  padding: 5px 32px;
}

.header .container-left {
  display: flex;
  gap: 30px;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  align-content: flex-start;
}

.header .container-right {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  align-content: flex-start;
}

.header .download-btn {
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  flex-direction: row;
}

.header .download-btn p {
  font-weight: 600;
}

.header .download-btn-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  gap: 10px;
  background-color: #fff;
  border-radius: 10px;
  padding: 7px 12px;
}

.profile-icon {
  height: 37px;
  width: 37px;
  /* border: 1px solid #626262; */
  border-radius: 50%;
  object-fit: cover;
  transition: 0.3s;
}

:root {
  --random-color: #000000;
  /* Default to black */
}

p.profile-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 21px;
  font-weight: 600;
  background-color: var(--random-color);
  color: #fff;
  text-transform: uppercase;
}

.profile-icon:hover {
  transition: 0.3s;
  box-shadow: #2977c999 0px 0px 12px;
}

.profile-icon-container {
  position: relative;
  height: 39px;
  width: 39px;
  background-color: transparent;
  padding: 1px;
  border-radius: 50%;
}

.profile-icon-container .user-nav {
  position: absolute;
  min-width: 280px;
  max-width: 360px;
  width: 95vw;
  top: 50px;
  right: 0px;
  padding: 10px;
  border-radius: 10px;
  background-color: #fff;
  z-index: 999;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  flex-direction: column;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  max-height: 80vh;
  overflow: auto;
}

.profile-icon-container .more-icon {
  padding: 0px;
  height: 39px;
  width: 39px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  overflow: hidden;
}

.profile-icon-container .more-icon img {
  width: 100%;
  height: 100%;
}

.user-nav .blocks-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
}

.user-nav .blocks {
  width: 90px;
  height: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-direction: column;
  font-size: 12px;
  text-decoration: none;
  font-weight: 600;
  color: #2977c9;
  border-radius: 8px;
}

.user-nav .blocks.coming-soon {
  color: #ccc;
}

.user-nav .blocks.coming-soon:hover {
  background-color: transparent;
}

.user-nav .privacy {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  background-color: #f6f6f6;
  padding: 10px;
  width: 100%;
  border-radius: 8px;
}

.user-nav .privacy a {
  font-size: 12px;
  font-weight: 600;
  color: #626262;
  text-decoration: none;
}

.user-nav .privacy a:hover {
  color: #2977c9;
}

.user-nav .privacy .dot {
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: #626262;
}

.user-nav .blocks i {
  font-size: 1.6rem;
}

.user-nav .blocks:hover {
  background-color: #2977c919;
}

.user-nav .container {
  background-color: #f6f6f6;
  padding: 10px;
  border-radius: 8px;
  width: 100%;
  gap: 10px;
  flex-wrap: wrap;
  align-items: flex-start;
}

.user-nav .icon {
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  color: #2977c9;
}

.user-nav .icon i {
  color: inherit;
}

.user-nav .content {
  width: calc(100% - 50px);
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 0px;
  flex-direction: column;
}

.user-nav h2 {
  font-size: 12px;
  color: #626262;
  font-weight: 400;
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
}

.user-nav h3 {
  font-size: 14px;
  color: #626262;
  font-weight: 600;
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
}

.user-nav .container a {
  font-size: 12px;
  background-color: transparent;
  color: #2977c9;
  border: 1px solid #2977c9;
  font-weight: 600;
  text-decoration: none;
  padding: 10px;
  border-radius: 5px;
  width: 100%;
  text-align: center;
}

.user-nav .container a:hover {
  background-color: #00000009;
}

.header .home {
  width: fit-content;
  background-color: transparent;
  color: #2977c9;
  border: 2px solid transparent;
  transition: 0.3s;
  padding: 3px 10px;
  text-decoration: none;
  font-size: 18px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  height: 39px;
}

.header .help {
  width: fit-content;
  background-color: transparent;
  color: #626262;
  /* border: 1px solid #626262; */
  transition: 0.3s;
  padding: 4px 10px;
  text-decoration: none;
  font-size: 18px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  height: 39px;
}

.header .help span {
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  color: inherit;
}

.header .help i {
  font-size: 20px;
}

.header .onboarding-help {
  position: relative;
}

.header .help .help-prompt {
  position: absolute;
  top: 40px;
  right: 5px;
  min-width: 240px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
  flex-direction: row;
  flex-wrap: nowrap;
  background-color: transparent;
  z-index: 3;
}

.header .help .help-prompt p {
  padding: 10px;
  background-color: #fff;
  font-weight: 600;
  color: #626262;
  border-radius: 10px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px;
  scale: 1;
  animation: pop-in 0.3s ease-in;
  position: relative;
  padding-bottom: 13px;
  overflow: hidden;
}

@keyframes pop-in {
  0% {
    scale: 0.3;
  }

  100% {
    scale: 1;
  }
}

.header .help .help-prompt i {
  color: #9f7928;
  font-size: 2rem;
  animation: point-to 0.6s linear infinite;
}

@keyframes point-to {
  0% {
    margin-top: 0px;
  }

  50% {
    margin-top: 10px;
  }

  100% {
    margin-top: 0px;
  }
}

.header .help .help-prompt .timer-bar {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 6px;
  background-color: #f1f1f1;
}

.header .help .help-prompt .timer-bar .fill {
  width: 0%;
  height: 100%;
  background-color: #2977c9;
  animation: timer-bar linear 8s;
}

@keyframes timer-bar {
  0% {
    width: 100%;
  }

  100% {
    width: 0%;
  }
}

.header .home i {
  font-size: 16px;
}

.header .home:hover {
  width: fit-content;
  background-color: #2977c9;
  color: #fff;
  border-color: #2977c9;
  transition: 0.3s;
}

.header .help:hover {
  background-color: #00000019;
}

.more-action-btn {
  display: none;
}

.notification-icon {
  width: fit-content;
  background-color: transparent;
  color: #626262;
  /* border: 1px solid #DF7184; */
  transition: 0.3s;
  padding: 4px 10px;
  text-decoration: none;
  font-size: 18px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  height: 39px;
  position: relative;
}

.notification-icon span {
  background-color: #df7184;
  color: #fff;
  border-radius: 10px;
  font-size: 10px;
  width: fit-content;
  padding: 0px 6px;
  height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0px;
  right: 0px;
}

.notification-icon i {
  font-size: 18px;
}

.notification-icon-container {
  position: relative;
}

.notification-icon-container .notification-nav {
  position: absolute;
  min-width: 280px;
  max-width: 360px;
  width: 95vw;
  top: 50px;
  right: 0px;
  padding: 10px;
  border-radius: 10px;
  background-color: #fff;
  z-index: 999;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  flex-direction: column;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  max-height: 80vh;
  overflow: auto;
}

.notification-icon-container .notification-nav::-webkit-scrollbar {
  height: 0px;
  width: 0px;
}

.notification-nav .content {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  flex-wrap: nowrap;
  background-color: #f6f6f6;
  padding: 10px;
  border-radius: 5px;
  width: 100%;
}

.notification-nav .icon {
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  color: #2977c9;
}

.notification-nav .icon i {
  color: inherit;
}

.notification-nav p {
  width: calc(100% - 85px);
  overflow: hidden;
}

.notification-nav .close-btn {
  position: relative;
  top: auto;
  right: auto;
  background-color: #df7184;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  line-height: 25px;
  width: 25px;
  height: 25px;
}

.sub-organisation-select {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
  flex-direction: row;
  flex-wrap: wrap;
  padding-bottom: 2px;
}

.sub-organisation-select label {
  font-size: 12px;
  font-weight: 600;
  color: #626262;
}

.sub-organisation-select button {
  padding: 1px 10px;
  border-radius: 5px;
  font-size: 12px;
  width: fit-content;
}

.sub-org-name {
  height: 21px;
  background-color: #b721ff;
  color: #fff;
  padding: 1px 10px;
  border-radius: 5px;
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 120px;
  width: fit-content;
}

.subOrg-modal .subOrg-container {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
  flex-direction: column;
  width: 100%;
  max-height: 70vh;
  overflow-y: auto;
  min-width: 220px;
}

.subOrg-modal .subOrg-container .column-container {
  width: 100%;
  background-color: #f6f6f6;
  padding: 10px;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: space-between;
  align-items: center;
  transition: 0.3s;
}

.subOrg-modal .subOrg-container .column-container label {
  background-color: #fff;
  color: #b721ff;
  font-weight: 600;
  padding: 1px 10px;
  border-radius: 5px;
  font-size: 12px;
}

.subOrg-modal .content {
  max-width: 300px;
}

.subOrg-modal .subOrg-container .column-container.active {
  background-color: #b721ff;
  color: #fff;
}

.subOrg-modal .subOrg-container .column-container.hover:hover {
  background-color: #b721ff19;
  color: #b721ff;
  font-weight: 600;
  padding: 10px 20px;
  transition: 0.3s;
}

.subOrg-modal .subOrg-container .column-container p {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.subOrg-modal .subOrg-container button {
  padding: 10px 10px;
  border: 1px solid #2977c9;
  background-color: #fff;
  color: #2977c9;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
}

.sub-org-consent {
  width: 100%;
  background-color: #21937b29;
  padding: 10px;
  border-radius: 5px;
  max-width: 800px;
}

.sub-org-consent .sub-org-name {
  white-space: nowrap;
  overflow: visible;
  text-overflow: unset;
}

.sub-org-consent .agree {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 100%;
}

.sub-org-consent .agree input {
  width: 13px;
}

.sub-org-consent .agree p {
  max-width: none !important;
  color: #626262 !important;
}

.sub-org-consent .agree p b {
  color: #d5415a;
  font-weight: 600;
}

.signup-prompt {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  min-height: 70vh;
  max-height: 100vh;
  overflow-y: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0px;
  flex-direction: column;
  transform: translateY(100%);
  transition: 1.2s;
  z-index: 999;
}

.signup-prompt.active {
  transform: translateY(0%);
  transition: 1.2s;
}

.signup-prompt .content {
  background-color: #fff;
  padding: 20px;
  width: 100%;
  flex-grow: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 20px;
  flex-direction: column;
  padding: 10px;
}

.signup-prompt .fade-out {
  background-image: linear-gradient(rgba(255, 255, 255, 0), rgb(255, 255, 255));
  height: 250px;
  width: 100%;
}

.signup-prompt .timer {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  background-color: #f9f9f9;
  flex-direction: column;
  padding: 10px 30px;
  border-radius: 5px;
}

.signup-prompt .timer .time {
  color: #d5415a;
  padding: 0px 10px;
  border-radius: 5px;
  font-size: 2rem;
  text-align: center;
  font-weight: 600;
}

.signup-prompt button {
  width: fit-content;
  padding: 10px 30px;
  font-size: 16px;
  border-radius: 5px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}

.signup-prompt button.non-cta {
  padding: 0px 30px;
  background-color: transparent;
  color: #626262;
  box-shadow: none;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
}

.signup-prompt .usecases {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 20px;
  color: #626262;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 10px 30px;
  border-radius: 5px;
}

.signup-prompt .usecases .split {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  gap: 15px;
  flex-grow: 1;
}

.signup-prompt .usecases i {
  font-size: 21px;
}

.signup-prompt .usecases b {
  flex-basis: 1;
  width: 100%;
  text-align: center;
}

.signup-prompt .usecases p {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}

.blackout {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000000c9;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.blackout p {
  font-size: 13vw;
  font-weight: 600;
  color: #ffffff39;
  transform: rotate(-30deg);
}

.pulse {
  box-shadow: 0 0 0 rgba(44, 113, 216, 0.4);
  animation: pulse 2s infinite;
  margin: 20px 0px;
}

.pulse:hover {
  animation: none;
}

@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(44, 113, 216, 0.4);
  }

  70% {
    -webkit-box-shadow: 0 0 0 10px rgba(44, 113, 216, 0);
  }

  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(44, 113, 216, 0);
  }
}

@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(44, 113, 216, 0.4);
    box-shadow: 0 0 0 0 rgba(44, 113, 216, 0.4);
  }

  70% {
    -moz-box-shadow: 0 0 0 10px rgba(44, 113, 216, 0);
    box-shadow: 0 0 0 20px rgba(44, 113, 216, 0);
  }

  100% {
    -moz-box-shadow: 0 0 0 0 rgba(44, 113, 216, 0);
    box-shadow: 0 0 0 0 rgba(44, 113, 216, 0);
  }
}

.profile-icon-container .user-nav .bottom-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-direction: row;
  flex-wrap: wrap;
  flex-wrap: nowrap;
  width: 100%;
}

.profile-icon-container .user-nav .bottom-wrapper button.settings {
  font-family: 'Poppins', 'Open Sans', sans-serif;
  font-size: 12px;
  background-color: #fff;
  color: #626262;
  border: 1px solid #626262;
  transition: 0.3s;
  font-weight: 600;
  text-decoration: none;
  padding: 10px;
  border-radius: 5px;
  flex-grow: 1;
  text-align: center;
  cursor: pointer;
  white-space: nowrap;
}

.profile-icon-container .user-nav .bottom-wrapper button.settings:hover {
  background-color: #f6f6f6;
}

@media screen and (max-width: 1100px) {
  .header .container .help {
    height: 39px;
  }

  .header .container .help span,
  .header .container .home {
    display: none;
  }
}

@media screen and (max-width: 820px) {
  .header .container .help span,
  .header .container .home {
    display: none;
  }

  .header .container .help {
    height: 39px;
  }

  .header .container .signin-btn {
    display: none;
  }

  .header .container-left {
    width: 100%;
    justify-content: space-between;
    gap: 10px;
  }

  .more-action-btn {
    display: block;
    width: fit-content;
    padding: 0px;
    background-color: #2977c9;
    color: #fff;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding: 5px 15px;
    border-radius: 5px;
  }

  .more-action-btn:hover {
    background-color: #2977c9;
  }

  .more-action-btn i {
    font-size: 14px;
    color: inherit;
  }

  .notification-icon-container .notification-nav {
    right: -60px;
  }

  .main-header {
    position: fixed;
    top: 0;
    left: 0;
    padding: 10px;
    background-color: #f1f1f1;
    z-index: 99;
  }

  .main-landing-page {
    height: 100vh !important;
    padding-top: 60px;
  }

  .blackout p {
    transform: rotate(0deg);
  }
}
