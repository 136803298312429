.sidenav,
.ads-sidenav {
    padding: 0px;
    border-radius: 10px;
    background-color: transparent;
    max-width: 300px;
    width: 100%;
    overflow-y: hidden;
    position: relative;
    height: fit-content;
}

.sidenav .scroll {
    height: calc(100vh - 336px);
    overflow-y: scroll;
    color: rgba(0, 0, 0, 0);
    transition: color 0.3s ease;
}

.copyright {
    color: #000;
}

.sidenav .scroll:hover {
    color: rgba(0, 0, 0, 0.3);
}

.sidenav .container {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 20px;
    flex-direction: column;
    width: 100%;
}

.sidenav .container .profile {
    width: 100%;
    display: flex;
    gap: 10px;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    flex-direction: column;
}

.sidenav .container .profile .user-sec {
    display: flex;
    flex-direction: row;
    gap: 15px;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    min-height: 48px;
    margin-bottom: 15px;
}

.sidenav .container .profile .org-upgrade {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
}

.sidenav .container .profile .plan {
    background-color: transparent;
    color: #2977c9;
    border: 1px solid #2977c9;
    padding: 1px 10px;
    border-radius: 15px;
    font-size: 12px;
    font-weight: 600;
    width: fit-content;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    text-transform: capitalize;
}

.sidenav .container .profile .plan span {
    font-size: inherit;
}

.min-sidenav .container .profile .plan span {
    font-size: inherit;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 26px;
}

.sidenav .container .profile .plan button {
    position: absolute;
    bottom: 0px;
    left: 0px;
    z-index: -1;
    background-color: #eec65e;
    color: #000;
    padding: 1px 10px;
    border-radius: 15px;
    font-size: 10px;
    font-weight: 600;
    width: fit-content;
    transform: scale(0.1);
    transition: 0.3s;
}

.sidenav .container .profile .plan:hover button {
    bottom: -21px;
    left: 0px;
    z-index: 1;
    transform: scale(1);
    transition: 0.3s;
}

.sidenav .container .profile .plan.credits {
    background-color: #f1f1f1;
    color: #000;
}

.org-sec {
    display: flex;
    flex-direction: row-reverse;
    gap: 5px;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    flex-grow: 1;
    padding: 4px 20px;
    padding-right: 63px;
    border: 1px solid #2977c9;
    border-radius: 10px;
    width: 100%;
    position: relative;
}

.org-sec p {
    color: #8e8e8e;
}

.org-sec h1 {
    font-family: 'Poppins', 'Open Sans', sans-serif !important;
    font-size: 25px !important;
    font-weight: 600 !important;
    flex-direction: row !important;
    align-items: center;
}

.org-sec .org-name {
    position: absolute;
    top: -20px;
    left: 0px;
    background-color: #fff;
    color: #2977c9;
    padding: 0px;
    font-size: 12px;
    font-weight: 600;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 100%;
}

.org-sec .org-plan {
    background-color: #fff;
    border: 1px solid #ffc76a;
    padding: 2px 10px;
    color: #d49a39;
    font-size: 12px;
    border-radius: 10px;
    text-decoration: none;
}

.org-sec .org-plan.premium {
    background-image: linear-gradient(20deg, #ffc76a, #d49a39);
    color: #fff;
    font-weight: 600;
}

.org-sec .add-credits {
    position: absolute;
    top: 0px;
    right: 0px;
    background-color: #2977c9;
    color: #fff;
    padding: 10px;
    font-size: 12px;
    font-weight: 600;
    height: 46px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0px 8px 8px 0px;
}

.org-sec .add-credits {
    text-decoration: none;
}

.org-sec .add-credits p {
    width: 0px;
    overflow-x: hidden;
    color: #fff;
    padding: 0px;
    text-align: center;
    transition: 0.3s;
}

.org-sec .add-credits:hover p {
    width: 120px;
    transition: 0.3s;
}

.org-sec .add-credits:hover {
    border-radius: 8px;
}

.sidenav .container img {
    width: 100px;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
}

.sidenav .container h1 {
    display: flex;
    font-size: 18px;
    gap: 10px;
    flex-direction: column;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    height: 27px;
    color: #000;
    width: 100%;
}

.sidenav .container .org-sec h1 {
    width: fit-content;
    height: 38px;
}

.sidenav .container h1 span {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: 16px;
    line-height: 22px;
    font-weight: 400;
}

.sidenav .container h1 .flex-fs b {
    font-size: 18px;
    font-weight: 900;
}

.sidenav .search {
    width: 100%;
    position: relative;
}

.sidenav .search input {
    background-color: #f1f1f1;
    padding: 12px;
}

.sidenav .search button {
    position: absolute;
    top: 50%;
    right: 0px;
    transform: translateY(-50%);
    padding: 8.5px 20px;
    width: fit-content;
    background-color: transparent;
    color: #2977c9;
    transition: 0.3s;
    cursor: pointer;
}

.sidenav .search button:hover {
    background-color: #2977c9;
    color: #fff;
    transition: 0.3s;
}

.sidenav .module {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: flex-start;
    flex-direction: column;
    gap: 10px;
    padding: 20px;
    padding-top: 15px;
    padding-bottom: 25px;
    border-radius: 10px;
    background-color: #fff;
    position: relative;
}

.sidenav .module label {
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    color: #8e8e8e;
}

.sidenav .module .nav {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 5px;
    text-decoration: none;
    color: #555;
    font-size: 14px;
    position: relative;
    padding: 0px 5px;
}

.min-sidenav .module .nav {
    padding: 5px 0px;
    width: 100%;
    justify-content: center;
}

.sidenav .module .nav:hover {
    background-image: linear-gradient(to right, #f1f1f1 10%, #fff 90%);
    border-radius: 5px;
}

.sidenav .module .nav.selected:hover {
    background-image: none;
    background-color: #d0e5fe;
    border-radius: 5px;
}

.sidenav .module .nav.selected {
    background-color: #d0e5fe;
    color: #2977c9 !important;
    border-radius: 5px;
}

.sidenav .module .nav.selected .description {
    padding: 10px 0px;
}

.sidenav .module .nav .description label {
    display: none;
    width: 100%;
    color: #555;
    font-size: 10px;
}

.sidenav .module .nav.selected .description label {
    display: block;
}

.sidenav .module .nav .bell-icon {
    font-size: 10px;
    flex-grow: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.sidenav .module .nav .bell-icon i {
    font-size: inherit;
    background-color: #df7184 !important;
    color: #fff !important;
    width: 20px !important;
    height: 20px !important;
    border-radius: 50% !important;
}

.sidenav .module .nav.selected .bell-icon i {
    font-size: inherit;
    background-color: transparent !important;
    color: #df7184 !important;
    width: 20px !important;
    height: 20px !important;
    border-radius: 50% !important;
}

.sidenav .user-name .bell-icon {
    font-size: 10px;
    position: absolute;
    top: 10px;
    right: 10px;
}

.sidenav .user-name .bell-icon i {
    font-size: inherit;
    background-color: #df7184 !important;
    color: #fff !important;
    width: 20px !important;
    height: 20px !important;
    border-radius: 50% !important;
    display: flex;
    justify-content: center;
    align-items: center;
}

.sidenav .module .selected i {
    background-color: #d0e5fe !important;
    color: #2977c9 !important;
}

.sidenav .module a,
.sidenav .module p {
    width: 100%;
    display: block;
    transition: 0.3s;
    border-radius: 10px;
}

.sidenav .module a i,
.sidenav .module p i {
    pointer-events: none;
}

.sidenav .module a:hover i,
.sidenav .module p:hover i {
    background-color: transparent;
    color: #2977c9;
    transition: 0.3s;
}

.sidenav .module a.active,
.sidenav .module p.active {
    background-color: #2977c9;
    color: #fff;
}

.sidenav .module a.active i,
.sidenav .module p.active i {
    background-color: #df7184;
    color: #fff;
}

.sidenav .module .nav i {
    color: #2977c9;
    background-color: transparent;
    border-radius: 5px;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 19px;
}

.sidenav .module .nav .notification-bell,
.sidenav .module .nav .notification-bell:hover {
    background-color: transparent !important;
    color: #df7184 !important;
}

.sidenav .icon {
    padding: 0px;
    border: none;
    background-color: #ccc;
    color: #fff;
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    height: 40px;
    width: 40px;
    position: relative;
}

.sidenav .verifiedbadge {
    position: absolute;
    top: -5px;
    right: -5px;
    padding: 0px;
    border: none;
    background-color: transparent;
    color: #fff;
    font-size: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    height: 20px;
    width: 20px;
    transition: 0.3s;
}

.sidenav .userbadge {
    position: absolute;
    top: -5px;
    right: -5px;
    padding: 0px;
    border: none;
    background-color: #dfdfdf;
    color: #fff;
    font-size: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    height: 20px;
    width: 20px;
    transition: 0.3s;
}

.sidenav .tooltip-premium {
    position: relative;
}

.sidenav .tooltip-premium .tooltiptext {
    visibility: hidden;
    width: 90px;
    background-color: #555;
    color: #fff;
    text-align: center;
    padding: 5px 0;
    border-radius: 6px;
    position: absolute;
    z-index: 60;
    bottom: 50%;
    left: 110%;
    transform: translateX(73%);
    margin-left: -60px;
    opacity: 0;
    transition: opacity 0.3s;
    font-size: 12px;
    font-weight: 600;
}

.sidenav .tooltip-premium .tooltiptext::after {
    content: '';
    position: absolute;
    bottom: 50%;
    left: -4%;
    transform: rotate(90deg);
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
}

.sidenav .tooltip-premium:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
}

.sidenav .userbadge:hover {
    box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
    transition: 0.3s;
}

.sidenav .userbadge i,
.sidenav .verifiedbadge i {
    font-size: inherit;
    color: #fff;
}

.sidenav .verifiedbadge i.outer {
    font-size: 21px;
    color: #2bcead;
}

.sidenav .verifiedbadge.not i.outer {
    font-size: 21px;
    color: #ff9800;
}

.sidenav .verifiedbadge i.inner {
    font-size: 10px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.sidenav .userbadge.premium {
    color: #fff;
    background-image: linear-gradient(20deg, #ffc76a, #d49a39);
    border: none;
}

.sidenav .storage {
    gap: 10px;
}

.sidenav .storage .buy-storage {
    background-color: #2bcead;
    color: #fff;
}

.sidenav .storage .policy {
    font-size: 12px;
    color: #8e8e8e;
    text-decoration: none;
}

.sidenav .progress-bar {
    width: 100%;
    height: 15px;
    background-color: #f1f1f1;
    border-radius: 10px;
}

.sidenav .progress-bar .value {
    background-color: #2977c9;
    border-radius: 10px;
    height: 15px;
    width: 30%;
}

.sidenav .user-sec .user-name {
    max-width: calc(100% - 50px);
}

.btn-yellow {
    background-color: #ffc76a;
    color: #000;
}

.coin-icon {
    height: 14px !important;
    width: 14px !important;
}

.sidenav .mobile {
    display: none;
}

.sidenav .min-sidenav-btn {
    /* position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0px;
    padding: 30px 10px; */
    background-color: #2977c9;
    color: #fff;
    border-radius: 50%;
    transition: 0.2s;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 2px;
    align-self: flex-end;
    position: sticky;
    bottom: 0;
    right: 0;
}

.sidenav .min-sidenav-btn:hover {
    padding-right: 5px;
    transition: 0.2s;
}

.min-sidenav {
    width: fit-content !important;
    overflow-y: unset;
}

.min-sidenav .module {
    align-items: center;
}

.min-sidenav .fg-1 {
    display: none;
}

.min-sidenav .notification-bell {
    position: absolute;
    top: -20px;
    right: -14px;
}

.min-sidenav .scroll::-webkit-scrollbar,
.sidenav .scroll::-webkit-scrollbar {
    width: 0px;
}

.min-sidenav .module a,
.min-sidenav .module p {
    width: fit-content;
}

.min-sidenav .min-sidenav-btn {
    background-color: #2977c9;
    color: #fff;
    padding-left: 5px;
    padding-right: 5px;
}

.min-sidenav .min-sidenav-btn:hover {
    background-color: #2977c9;
    padding-left: 5px;
    color: #fff;
}

.min-sidenav .container {
    width: fit-content;
    overflow-x: hidden;
}

.min-sidenav .user-sec {
    justify-content: center !important;
    align-items: center !important;
}

.min-sidenav .btn-issue {
    border-radius: 5px;
    width: 100%;
    height: 40px;
    padding: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0s;
}

.min-sidenav .user-sec {
    flex-direction: column !important;
    gap: 8px !important;
}

.toggle-sub-nav-btn {
    background-color: transparent;
    color: #2977c9;
    padding: 0px;
    position: absolute;
    bottom: 3px;
    width: fit-content;
    left: 50%;
    transform: translateX(-50%);
}

.toggle-sub-nav-btn:hover {
    background-color: transparent;
}

.sub-nav .container-left {
    display: flex;
    gap: 30px;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    align-content: flex-start;
}

.sub-nav .container-right {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    align-content: flex-start;
    position: relative;
}

.sub-nav .container-right input {
    padding: 9.5px;
}

.sub-nav .container-right .search-btn {
    position: absolute;
    top: 0;
    right: 0;
    padding: 9.5px 15px;
    border: 1px solid #2977c9;
    border-radius: 0px 10px 10px 0px;
}

.sub-nav .download-btn {
    width: fit-content;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 5px;
    flex-direction: row;
    min-height: 48px;
    flex-grow: 1;
}

.sub-nav .download-btn.center {
    justify-content: center;
}

.sub-nav .download-btn.gray {
    background-color: #8e8e8e;
}

.sub-nav .download-btn.green {
    background-color: #2bcead;
}

.sub-nav .download-btn p {
    font-weight: 600;
    color: #fff !important;
}

.sub-nav .download-btn-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    gap: 10px;
    background-color: #fff;
    border-radius: 10px;
    padding: 7px 12px;
    width: 100%;
}

.sidenav .verification-warning {
    align-self: flex-start;
    justify-content: center;
    display: flex;
    align-items: center;
    flex-direction: row;
    background-color: #ffd4d4;
    color: #000;
    padding: 5px 10px;
    border-radius: 5px;
    gap: 10px;
    text-decoration: none;
    margin-top: 10px;
}

.sidenav .verification-warning p span {
    color: #d5415a;
    font-weight: 400;
    font-size: inherit;
}

.sidenav .verification-warning p u {
    font-size: inherit;
    color: #157efb;
    font-weight: 400;
    text-decoration: none;
}

.sidenav .verification-warning p u i {
    color: #157efb;
    font-size: inherit;
}

.sidenav .verification-warning i {
    color: #d5415a;
}

.sidenav .verification-warning p {
    color: #000;
    font-size: 12px;
}

.sidenav .scrollable {
    max-height: calc(100vh - 137px);
    overflow-y: auto;
}

.sidenav .scrollable::-webkit-scrollbar {
    width: 0px;
    display: none;
}

.min-sidenav .btn-issue:hover i {
    width: 16px;
}

@media screen and (max-width: 1250px) {
    .sidenav {
        position: fixed;
        bottom: 0;
        left: 0;
        z-index: 996;
        box-shadow: 0px 0px 20px 0px #00000069;
        border-radius: 0px;
        height: 100vh;
        background-color: #f1f1f1;
        padding: 10px;
    }

    .main {
        margin-left: 75px;
    }

    .issue-page {
        margin-left: 0px;
    }

    .sidenav .scroll {
        height: calc(100vh - 200px);
    }

    /* .ads-sidenav {
        display: none;
    } */
}

@media screen and (max-width: 820px) {
    .main {
        margin-left: 0px;
    }

    .min-sidenav {
        width: 100% !important;
    }

    .min-sidenav-btn {
        display: none !important;
    }

    .sidenav .container {
        background-color: #00000069;
        backdrop-filter: blur(3px);
        position: fixed;
        bottom: 60px;
        left: 0px;
        height: calc(100% - 60px);
        width: 100%;
        padding: 10px;
        overflow: hidden;
        display: flex;
        justify-content: flex-end;
    }

    .sidenav .container::-webkit-scrollbar {
        display: none;
        width: 0px;
    }

    #mobile-nav-modal {
        display: none;
    }

    .sidenav .container .profile {
        display: flex;
        gap: 10px;
        background-color: #fff;
        padding: 10px;
        border-radius: 10px;
    }

    .sidenav .container h1 {
        color: #2977c9;
    }

    .sidenav .container .profile .org-name,
    .sidenav .container .profile .tooltiptext {
        display: none;
    }

    .sidenav .container .copyright {
        display: none;
    }

    .sidenav .container .container {
        height: fit-content;
        max-height: 100%;
        position: relative;
        bottom: auto;
        left: auto;
        overflow: scroll;
        background-color: #f1f1f1;
        border-radius: 10px;
        padding: 5px;
        display: flex;
        justify-content: flex-start;
    }

    .sidenav .mobile {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        gap: 10px;
        flex-direction: row;
        width: 100%;
    }

    .sidenav {
        position: fixed;
        bottom: 0;
        left: 0;
        border-radius: 0px;
        background-color: #2977c9;
        color: #fff;
        z-index: 998;
        height: 60px;
        display: flex;
        padding: 10px;
        width: 100%;
        max-width: none;
    }

    .sidenav .mobile .nav-ico {
        color: #f6f6f6;
        font-size: 12px;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 1px;
        flex-direction: column;
        text-decoration: none;
        padding: 5px;
        border-radius: 5px;
        flex-grow: 1;
    }

    .sidenav .mobile .nav-ico.active {
        background-color: #d0e5fe;
        color: #2977c9;
    }

    .sidenav .mobile .nav-ico i {
        color: inherit;
        font-size: 18px;
    }

    .sidenav .mobile .nav-ico p {
        font-size: inherit;
    }

    .sidenav .scrollable {
        max-height: none;
    }
}

@media screen and (max-width: 640px) {
    .tileV2 .trial {
        display: none;
    }
}
