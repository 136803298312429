.api-section {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 20px;
    flex-direction: row;
    flex-wrap: nowrap;
    line-height: 30px;
    font-weight: 400;
}

.api-section .api-nav {
    padding: 20px;
    border-radius: 20px;
    background-color: #2f3e53;
    color: #fff;
    min-width: 280px;
    width: 40%;
    max-width: 320px;
    display: block;
    position: sticky;
    top: 120px;
}

.api-section .api-nav .container {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 0px;
    flex-direction: column;
    max-height: 60vh;
    width: 100%;
    overflow-y: auto;
}

.api-section .api-nav .container ul {
    margin-bottom: 20px;
    padding-left: 0px;
}

.api-section .api-nav .container ul li {
    list-style-type: none;
    transition: 0.3s;
}

.api-section .api-nav .container ul li:hover {
    padding-left: 5px;
    transition: 0.3s;
}

.api-section .api-nav h3 {
    color: #21d9a1;
    font-size: 16px;
}

.api-section .api-nav .back-btn {
    padding: 3px 20px;
    font-size: 14px;
    font-weight: 600;
    width: fit-content;
    margin-bottom: 20px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    gap: 10px;
    background-color: #1a7efb;
    color: #fff;
    cursor: pointer;
    transition: 0.3s;
}

.api-section .api-nav .back-btn:hover {
    padding-left: 15px;
    gap: 15px;
    transition: 0.3s;
}

.api-section .content {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 20px;
    flex-direction: column;
    max-width: 800px;
    overflow: hidden;
}

.api-section .info {
    background-color: #e9f3ff;
    padding: 20px;
    border-radius: 20px;
    color: #084a9a;
    scroll-margin-top: 90px;
    width: 100%;
}

.api-section h4 {
    color: #000;
    font-size: 1.6rem;
    margin-bottom: 10px;
}

.api-section .desc {
    background-color: transparent;
    padding: 20px;
    border-radius: 20px;
    color: #084a9a;
    scroll-margin-top: 90px;
    width: 100%;
}

.api-section .main-img {
    display: block;
    margin-top: 20px;
    border-radius: 6px;
    width: 100%;
    max-width: 400px;
    min-height: 200px;
    object-fit: contain;
    object-position: center;
    background-color: #d5d9dd;
    border: 1px solid #d5d9dd;
}

.code-block {
    background-color: #171321;
    border-radius: 20px;
    overflow: hidden;
    width: 100%;
    scroll-margin-top: 90px;
}

.code-block .head {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    gap: 10px;
    width: 100%;
    flex-direction: row;
    flex-wrap: nowrap;
}

.code-block .head button {
    padding: 10px 15px;
    border-radius: 5px;
    background-color: transparent;
    font-size: 12px;
    color: #fff;
    border: none;
    width: fit-content;
    font-weight: 600;
}

.code-block .head p {
    color: #fff;
    background-color: #6d84a1;
    border-radius: 0px 0px 10px 0px;
    font-size: 12px;
    padding: 0px 15px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.code-block p {
    padding: 20px;
    font-size: 16px;
    color: #e9f3ff;
}

.code-block pre {
    user-select: all;
    background-color: transparent;
    padding: 20px;
    width: fit-content;
}

.code-block code {
    color: var(--pink);
    font-family: monospace;
    font-size: 14px;
    user-select: all;
    text-align: left;
}

.code-block :not(pre)>code {
    background: var(--blue);
    color: var(--black);
    margin: 0 10px;
    padding: 0 5px;
}

:root {
    --pink: #ff87d4;
    --blue: #87fffb;
    --black: #171321;
}

.api-section table {
    margin-top: 20px;
    width: 100%;
    border-collapse: collapse;
    border-radius: 20px;
    overflow: hidden;
    border: 0px;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
}

.api-section td,
.api-section th {
    border: 1px solid #ddd;
    padding: 8px 20px;
    max-width: 120px;
}

.api-section tr {
    background-color: #f1f4f6;
}

.api-section tr:nth-child(even) {
    background-color: #f1f1f1;
}

.api-section th {
    padding: 8px 20px;
    text-align: left;
    background-color: #d5d9dd;
    color: #2f3f53;
}

.api-section .desc a,
.api-section .info a {
    color: inherit;
    font-weight: 600;
}

.api-section .next-btn {
    color: #FFF;
    font-size: 16px;
    font-weight: 600;
    text-decoration: none;
    align-self: flex-end;
    padding: 5px 20px;
    background-color: #21d9a1;
    border-radius: 20px;
}

@media screen and (max-width: 820px) {
    .api-section .api-nav{
        display: none;
    }
}