.premium-container {
    display: flex;
    justify-content: center;
    align-content: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 20px;
    height: calc(100% - 142px);
    overflow-y: scroll;
}

.premium-container::-webkit-scrollbar {
    width: 0px;
    display: none;
}

.premium-container .pricing {
    flex-basis: 30%;
    padding: 30px 20px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    flex-direction: column;
    position: relative;
    border-radius: 10px;
    background-color: #FFF;
    min-width: 300px;
}

.premium-container .pricing.active {
    background-image: linear-gradient(120deg, #84fab0 0%, #8fd3f4 100%);
    ;
}

.premium-container .pricing:nth-child(5) {
    flex-basis: calc(60% + 20px);
    justify-content: space-between;
}

.pricing h2 {
    font-size: 2rem;
    text-align: center;
    color: #2977C9;
    font-weight: 400;
}

.pricing h3 {
    font-size: 3rem;
    text-align: center;
    color: #2BCEAD;
    font-weight: 600;
    min-width: 160px;
    line-height: 3rem;
}

.pricing.active h3 {
    color: #2977C9;
}

.pricing h3 span {
    color: #939393;
    font-size: 16px;
    font-weight: 300;
}

.pricing.active h3 span {
    color: #000;
}

.pricing .features {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;
    color: #496585;
    flex-direction: column;
    max-width: 400px;
}

.pricing .features i {
    color: #2977C9;
}

.pricing .popular {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    text-align: center;
    background-color: #2977C9;
    color: #FFF;
    font-size: 14px;
    font-weight: 400;
    border-radius: 8px 8px 0px 0px;
    padding: 3px;
}

.pricing .buy-button {
    position: absolute;
    bottom: 10px;
    right: 0px;
    width: 0px;
    height: 47px;
    border-radius: 10px 0px 0px 10px;
    padding: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    overflow: hidden;
    transition: 0.3s;
}

.pricing:hover .buy-button {
    width: 96px;
    transition: 0.3s;
}

.pricing .qty-wrapper {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    gap: 20px;
    flex-direction: row;
    width: 100%;
    flex-wrap: wrap;
}

.pricing .qty-wrapper .buy {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-direction: column;
}

.pricing .qty-wrapper .buy input {
    border: 2px solid #2977C9;
    padding: 8px 16px;
}

.pricing .qty-wrapper .pack {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-direction: row;
    height: 100%;
    border-radius: 10px;
    background-color: #D1EDFF;
    min-height: 100px;
    height: fit-content;
    overflow: hidden;
}

.pricing .pack .qty {
    background-color: #2977C9;
    color: #FFF;
    font-size: 2.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100px;
    border-radius: 0px;
}

.pricing .pack .wrapper {
    padding: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0px;
    flex-direction: column;
    background-color: transparent;
}

.pricing .pack h4 {
    font-size: 2rem;
    width: 100px;
    text-align: center;
    color: #2977C9;
    line-height: 2rem;
}

.toggle-mode {
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    gap: 0px;
    flex-direction: row;
}

.toggle-mode .t {
    padding: 10px 15px;
    border: 1px solid #2977C9;
    background-color: #F1F1F1;
    width: fit-content;
    max-width: 200px;
    text-align: center;
    transform: scale(1);
    transition: 0.3s;
}

.toggle-mode .t:first-child {
    border-radius: 10px 0px 0px 10px;
}

.toggle-mode .t b {
    font-size: 12px;
}

.toggle-mode .t:last-child {
    border-radius: 0px 10px 10px 0px;
}

.toggle-mode .t.active {
    z-index: 4;
    background-color: #2977C9;
    color: #FFF;
    transition: 0.3s;
}

.premium-header {
    display: flex;
    justify-content: space-around;
    align-items: center;
    gap: 20px;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
}

.premium-header .sub-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-direction: row;
    flex-wrap: wrap;
}

.premium-header .nav {
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    color: #2977C9;
    padding: 10px 20px;
    border: 1px solid #2977C9;
    border-radius: 10px;
}

.premium-header .plan-wrapper p {
    text-transform: capitalize;
    color: #939393;
}

.premium-header .plan-wrapper p span {
    font-weight: 600;
    color: #2977C9;
    font-size: 18px;
}

.premium-header .plan-wrapper hr {
    background-color: #2BCEAD;
    border: none;
    height: 1px;
}

.premium-container p.buy-credit-content {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    flex-direction: column;
}

.lisence-key-btn {
    width: fit-content;
    font-size: 14px;
    padding: 10px 20px;
    font-weight: 500;
    background-color: #D1EDFF;
    color: #2977C9;
    border: 1px solid #2977C9;
}

.lisence-key-btn:hover {
    background-color: #F1F1F1;
    border: 1px solid #2977C9;
}

@media screen and (max-width: 1060px) {
    .pricing .qty-wrapper .pack {
        flex-direction: column;
    }
}

@media screen and (max-width: 630px) {
    .pricing {
        flex-basis: 100% !important;
    }

    .premium-container::-webkit-scrollbar {
        width: 0px;
        display: none;
    }

    .premium-header .nav {
        display: none;
    }
}