body {
    background-color: #f1f1f1;
    background-image: url(../resources/bg-line.svg);
    background-size: cover;
    background-position: -30vw -30vh;
    /* background-repeat: no-repeat; */
}

.signin {
    max-height: 100vh;
    overflow-y: scroll;
}

.signin::-webkit-scrollbar {
    width: 0px;
    display: none;
}

.signin img {
    max-height: 80vh;
    max-width: 40vw;
    align-self: flex-start;
}

.signin img.gif-img {
    mix-blend-mode: darken;
}

.signin .wrapper {
    background-image: linear-gradient(20deg, #fcfcfc, #f1f1f1);
    padding: 30px 60px;
    width: 30vw;
    max-width: 400px;
}

.signin h1 {
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    font-size: 24px;
}

.signin .wrapper .container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 15px;
}

.signin .wrapper .container .userimage {
    height: 90px;
    display: block;
    margin: auto;
}

.signin label {
    font-family: 'Poppins', sans-serif;
    font-weight: 200;
    font-size: 14px;
    display: block;
    margin-bottom: 5px;
}

.signin p {
    font-size: 14px;
    text-align: center;
    width: 100%;
}

.signin .container .back-btn {
    width: fit-content;
    padding: 5px 20px;
    font-size: 14px;
    background-color: #fff;
    border: 1px solid #888;
    color: #888;
}

.name-with-designation {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    flex-direction: row;
}

.name-with-designation select {
    max-width: 63px;
    padding-left: 3px;
    padding-right: 0px;
}

.action-btn {
    text-align: center;
    width: 100%;
    color: #2977c9;
    text-decoration: underline;
}

.action-btn.disabled {
    color: #888;
    text-decoration: none;
}

.action-btn span {
    font-size: 12px;
    color: #2bcead;
    text-decoration: none;
}

.signin p .secondary {
    color: #2977c9;
    font-weight: 600;
}

.signin p a {
    text-decoration: none;
    color: #000;
    font-size: 14px;
}

.signin p a:hover {
    text-decoration: underline;
    color: #2977c9;
}

.signin p.success {
    background-color: transparent;
    color: #2bcead;
    font-weight: 600;
}

.signin-video {
    display: flex;
    justify-content: center;
    align-items: stretch;
    gap: 20px;
    flex-direction: column;
}

.redirect-status {
    padding: 20px;
    background-color: #2bcead;
    color: #fff;
    text-align: center;
    border-radius: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-direction: row;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    font-size: 14px;
    z-index: 999;
    animation: redirectMessage 3s ease-in-out;
}

.g-recaptcha {
    scale: 0.92;
    transform-origin: left;
}

@keyframes redirectMessage {
    0% {
        height: 100vh;
        flex-direction: column;
        font-size: 18px;
        font-weight: 600;
        gap: 20px;
    }

    90% {
        height: 100vh;
        flex-direction: column;
        font-size: 18px;
        font-weight: 600;
        gap: 20px;
    }

    100% {
        height: 82px;
        flex-direction: row;
        font-size: 14px;
        font-weight: 400;
        gap: 10px;
    }
}

.redirect-status i {
    font-size: 3rem;
    animation: shakeMessage 6s linear infinite;
}

@keyframes shakeMessage {
    0% {
        transform: translateY(0);
    }

    90% {
        transform: translateY(0);
    }

    92% {
        transform: translateY(-10px);
    }

    94% {
        transform: translateY(0);
    }

    96% {
        transform: translateY(-10px);
    }

    98% {
        transform: translateY(0);
    }

    100% {
        transform: translateY(0);
    }
}

.redirect-status.failed {
    background-color: #df7184;
}

#googleButton {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

#googleButton button {
    width: 100%;
    border-radius: 10px !important;
    padding: 3px !important;
    justify-content: center;
    color: #000 !important;
    opacity: 1 !important;
}

.password-warns {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 3px;
    flex-direction: column;
}

.password-warns p {
    font-size: 12px;
    font-weight: 300;
    color: #888;
    vertical-align: middle;
    text-align: left;
}

.password-warns .fa-circle-xmark {
    color: #df7184;
}

.password-warns .fa-circle-check {
    color: #2bcead;
}

.kicked {
    flex-grow: 1;
    justify-content: center;
    align-items: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 5px;
    max-width: 768px;
}

.kicked h1 {
    text-align: center;
    color: #df7184;
}

.kicked h1:last-child {
    color: #8b6cc0;
    font-size: 18px;
}

.kicked p {
    color: #000;
}

.kicked img {
    margin: 30px auto;
    height: 300px;
    width: auto;
    object-fit: contain;
    background-color: #000;
    border-radius: 50%;
}

.signup-policy {
    font-size: 12px !important;
    font-weight: 600;
    color: #2977c9;
    max-width: 200px;
    display: block;
    margin: auto;
}

.network-check {
    position: relative;
    padding: 10px;
    text-align: center;
    background-color: #df7184;
    color: #fff;
    border-radius: 5px;
    width: 100%;
}

.skip-modal-v2 {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    background-color: #00000069;
    z-index: 999;
}

.skip-modal-v2 .container {
    padding: 0px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    background-color: #fff;
    border-radius: 20px;
    width: 600px;
    max-width: 90%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
    flex-direction: column;
}

.skip-modal-v2 .title {
    font-size: 2rem;
    font-weight: 600;
    text-align: left;
    padding: 20px 40px;
    padding-bottom: 0px;
    color: #002a63;
}

.skip-modal-v2 .btn-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 20px;
    padding-top: 0px;
    width: 100%;
}

.skip-modal-v2 button {
    width: fit-content;
    background-color: #137efb;
    color: #fff;
    border: 2px solid #137efb;
    transition: 0.3s;
    display: block;
    padding: 6px 30px;
    border-radius: 10px;
    min-width: 200px;
    font-weight: 500;
}

.skip-modal-v2 label {
    margin-bottom: 0px;
    font-size: 12px;
    cursor: pointer;
    max-width: calc(100% - 210px);
}

.skip-modal-v2 .container .features {
    display: flex;
    justify-content: center;
    align-items: stretch;
    gap: 5px;
    flex-wrap: wrap;
    flex-direction: column;
    width: 100%;
}

.skip-modal-v2 .container .features .sub {
    box-shadow: none;
    width: 100%;
    color: #888;
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    gap: 5px;
    flex-direction: row;
    flex-wrap: wrap;
}

.skip-modal-v2 .container .features .sub.alt {
    background-color: transparent;
}

.skip-modal-v2 .container .features .sub h1 {
    font-size: 14px;
    font-weight: 600;
    color: #fff;
    width: 180px;
    padding: 20px;
    padding-top: 33px;
    flex-grow: 1;
    background-image: linear-gradient(45deg, #1cb899, #21d9a1);
    border-radius: 0px 20px 20px 0px;
}

.skip-modal-v2 .container .features .sub.alt h1 {
    background-image: linear-gradient(45deg, #fe8782, #fa8d74);
    color: #fff;
}

.skip-modal-v2 .container .features .sub h1 span {
    font-size: 21px;
    font-weight: 400;
    color: #fff;
    width: 100%;
    display: block;
}

.skip-modal-v2 .container .features .sub.alt h1 span {
    color: #fff;
}

.skip-modal-v2 .container .features .sub .content {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    gap: 10px;
    flex-direction: row;
    flex-wrap: nowrap;
    width: calc(100% - 185px);
    padding: 20px;
    background-color: #d2f9eb;
    border-radius: 20px 0px 0px 20px;
}

.skip-modal-v2 .container .features .sub.alt .content {
    background-color: #ffe7e7;
}

.skip-modal-v2 .container .features .sub label {
    color: #888;
    font-size: 12px;
    font-weight: 400;
}

.skip-modal-v2 .container .features .sub p {
    text-align: left;
    font-size: 13px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 10px;
    text-align: center;
    max-width: 200px;
    color: #000;
}

.skip-modal-v2 .container .features .sub p i {
    font-size: 2rem;
    color: #1cb899;
}

.skip-modal-v2 .container .features .sub p .icon {
    width: auto;
    height: 40px;
    display: block;
    margin: auto;
}

.skip-modal-v2 .container .features .sub.alt p i {
    color: #df7184;
}

.signin-v2 .wrapper {
    background-image: none;
    border-radius: 20px;
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
}

.signin-v2 .wrapper input {
    box-shadow: rgba(0, 0, 0, 0.06) 0px 0px 4px 0px inset;
}

.maintenance {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    flex-direction: column;
    width: 100%;
    height: calc(100% - 68px);
    background-color: #2687d6;
    border-radius: 20px;
    mix-blend-mode: multiply;
    padding: 20px;
}

.maintenance i {
    font-size: 6rem;
    animation: spin 2s linear infinite;
    color: #1f313d;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.maintenance h1 {
    text-align: center;
    font-size: 3rem;
    font-weight: 600;
    color: #ffcb74;
}

.maintenance p span {
    font-weight: 600;
}

.maintenance h2 {
    text-align: center;
    font-size: 1.5rem;
    font-weight: 400;
    color: #1f313d;
}

@media screen and (max-width: 1200px) {
    .signin .landing-img {
        display: none;
    }

    .signin .wrapper {
        width: 400px;
    }

    .signin .signin-video {
        display: none;
    }
}

@media screen and (max-height: 850px) {
    .signin .wrapper .container .userimage {
        display: none;
    }
}

@media screen and (max-width: 820px) {
    .signin {
        padding: 20px;
        align-content: flex-start;
        justify-content: center;
    }

    .signin .wrapper {
        padding: 30px 20px;
    }

    .kicked img {
        height: auto;
    }

    .skip-modal-v2 {
        align-items: flex-start;
        padding-top: 20px;
    }

    .skip-modal-v2 .container .features .sub {
        flex-direction: column;
        background-color: #d2f9eb;
    }

    .skip-modal-v2 .container .features .sub h1 {
        width: 100%;
        text-align: center;
        border-radius: 0px;
        background-image: none;
        color: #1cb899;
        padding-top: 20px;
        padding-bottom: 0px;
    }

    .skip-modal-v2 .container .features .sub h1 span {
        color: #000;
    }

    .skip-modal-v2 .container .features .sub .content {
        width: 100%;
        background-color: transparent;
    }

    .skip-modal-v2 .title {
        font-size: 1.8rem;
        text-align: center;
    }

    .skip-modal-v2 .btn-container {
        flex-direction: column;
    }

    .skip-modal-v2 label {
        width: 100%;
        max-width: none;
        text-align: center;
    }

    .skip-modal-v2 .container .features .sub.alt {
        background-color: #ffe7e7;
    }

    .skip-modal-v2 .container .features .sub.alt h1 {
        width: 100%;
        text-align: center;
        border-radius: 0px;
        background-image: none;
        color: #df7184;
        padding-top: 20px;
        padding-bottom: 0px;
    }

    .skip-modal-v2 .container .features .sub.alt h1 span {
        color: #000;
    }
}
