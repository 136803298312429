.cert-container {
    /* width: 904px;
    height: 644px; */
    position: relative;
}

.cert-container .elements {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0%;
    left: 0%;
    transform: scale(1);
    mix-blend-mode: multiply;
    /* instead of overflow:hidden .clearfix class was added to allow image element visibility outside parent */
    /* overflow: hidden; */
}

.cert-container .elements.of-hidden {
    overflow: hidden !important;
}

.clearfix:before,
.clearfix:after {
    content: ".";
    display: block;
    height: 0;
    overflow: hidden;
}

.clearfix:after {
    clear: both;
}

.clearfix {
    zoom: 1;
}

.cert-container img {
    width: 100%;
}

.image-container .origin {
    /* transform-origin: top left;
    width: fit-content; */
}

.slider-1 {
    transform: scale(calc(var(--scale-width) * 60%));
    transform-origin: 0 0;
}

.slider-2 {
    transform: scale(calc(var(--scale-width) * 80%));
    transform-origin: 0 0;
}

.slider-3 {
    transform: scale(calc(var(--scale-width) * 100%));
    transform-origin: 0 0;
}

.slider-4 {
    transform: scale(calc(var(--scale-width) * 120%));
    transform-origin: 0 0;
}

.slider-5 {
    transform: scale(calc(var(--scale-width) * 140%));
    transform-origin: 0 0;
}

.slider-6 {
    transform: scale(calc(var(--scale-width) * 160%));
    transform-origin: 0 0;
}

.slider-7 {
    transform: scale(calc(var(--scale-width) * 180%));
    transform-origin: 0 0;
}

.recenter-certificate {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 3;
    width: 40px !important;
    height: 40px !important;
    border-radius: 10px;
    padding: 0px !important;
    opacity: 0.3;
    transition: 0.3s;
}

.recenter-certificate:hover {
    opacity: 1;
    transition: 0.3s;
}

.pg-certficate_viewer {
    width: 100%;
}

.cert-viewer .slider {
    -webkit-appearance: none;
    width: 200px;
    height: 2px;
    background: #000;
    padding: 2px;
    margin: 10px;
    outline: none;
    opacity: 0.7;
    border-radius: 10px;
    -webkit-transition: .2s;
    transition: opacity .2s;
    border: none;
}

.cert-viewer .slider:focus {
    outline: none !important;
    border: none;
}

.cert-viewer .slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 12px;
    height: 25px;
    background: #157EFB;
    border: 2px solid #FFF;
    cursor: pointer;
    border-radius: 10px;
    transition: 0.3s;
}

.cert-viewer .slider::-moz-range-thumb {
    width: 12px;
    height: 25px;
    background: #157EFB;
    border: 2px solid #FFF;
    cursor: pointer;
    border-radius: 10px;
    transition: 0.3s;
}

@media screen and (max-width: 820px) {
    .pg-certficate_viewer {
        background-color: transparent !important;
        padding: 5px !important;
        height: 100vh !important;
        border-radius: 0px !important;
    }

    .pg-certficate_viewer .wrapper {
        background-color: transparent;
        gap: 5px;
        border-radius: 0px;
    }

    .mob-actions.fixed-controls {
        position: fixed;
        bottom: 70px;
        left: 50%;
        transform: translateX(-50%);
        width: fit-content;
        min-width: 60%;
        max-width: 100%;
        z-index: 10;
        padding: 0px;
        box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
    }

    .mob-actions {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 10px;
        background-color: #FFF;
        border-radius: 10px;
        width: 100%;
        overflow-x: auto;
        padding: 10px 0px;
    }

    .mob-actions .nav-container {
        flex-direction: row !important;
        justify-content: center;
        padding-top: 0px;
        gap: 10px;
        min-width: 100%;
        width: fit-content;
        padding: 10px;
    }

    .doc-alt-marketplace .mob-actions {
        position: fixed;
        bottom: 70px;
        left: 50%;
        transform: translateX(-50%);
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 10px;
        background-color: #FFF;
        border-radius: 10px;
        max-width: 100%;
        min-width: 60%;
        width: fit-content;
        overflow-x: auto;
        padding: 10px 0px;
    }

    .doc-alt-marketplace .mob-actions .nav-container {
        flex-direction: row !important;
        justify-content: center;
        padding-top: 0px;
        gap: 10px;
        min-width: 100%;
        width: fit-content;
        padding: 10px;
    }

    .mob-actions .nav-container .nav {
        flex-direction: column;
        font-size: 10px;
        gap: 3px;
    }

    .mob-actions::-webkit-scrollbar {
        display: none;
    }

    .cropper-zoom.cert-viewer {
        display: none;
    }
}